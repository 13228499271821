import { FC, Fragment, useContext } from "react";

import { AuthContext } from "../../context/authContext";

import AuthHeader from "./authHeader";
import GuestHeader from "./guestHeader";



const Header: FC = (): JSX.Element => {
  const { isUserAuthorized } = useContext(AuthContext);

  return (
    <Fragment>
      {isUserAuthorized ? (
        <AuthHeader />
      ) : (
        <GuestHeader />
      )}
    </Fragment>
  );
};

export default Header;
