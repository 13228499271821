import { FC } from "react"
import { StatisticsObjectType } from "./types";
import CustomButtonRight from "../customButtonRight";
import CustomLineChart from "../customLineChart";

const CaseVolumeHorizontal: FC<StatisticsObjectType> = ({ StatisticsObjectType }): JSX.Element => {
 
  return (
    <div className="row mt-2">
      <div className="col-9">
      {<CustomLineChart title={"Case Volume"} LineChartObject={StatisticsObjectType.case_volume}/>}
      </div>
      <div className="col-3">
      {<CustomButtonRight  StatisticsObjectType={StatisticsObjectType}/>}
      </div>
    </div >
  )
}
export default CaseVolumeHorizontal;
