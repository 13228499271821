import { UserType } from "src/types/users";

export const startChat = (userData: UserType) => {
  if ((window as any).Tawk_API &&
    typeof((window as any).Tawk_API.onLoaded) === "boolean" &&
    (window as any).Tawk_API.onLoaded === true
  ) {
    setTimeout(() => {
      (window as any).Tawk_API.setAttributes && (window as any).Tawk_API.setAttributes({
        name: userData.name,
        store: userData.email,
        hash: userData.id,
      });
    }, 1000);
    (window as any).Tawk_API.start && (window as any).Tawk_API.start();
    (window as any).Tawk_API.showWidget && (window as any).Tawk_API.showWidget();
  }
};

export const shutDownAndEndChat = () => {
  if ((window as any).Tawk_API &&
    typeof((window as any).Tawk_API.onLoaded) === "boolean" &&
    (window as any).Tawk_API.onLoaded === true
  ) {
    setTimeout(() => {
      (window as any).Tawk_API.setAttributes && (window as any).Tawk_API.setAttributes({
        name: "",
        store: "",
        hash: "",
      });
    }, 1000);
    (window as any).Tawk_API.hideWidget && (window as any).Tawk_API.hideWidget();
    (window as any).Tawk_API.shutdown && (window as any).Tawk_API.shutdown();
  }
};
