import { FC, useContext, useEffect } from "react";
import { CreateOrderAPIResponse, CreateOrderErrorResponse, CreateOrderModalProps, CreateOrderProps } from "./types";
import useFetch from "src/hooks/useFetch";
import { APIEndpoints } from "src/types/apiTypes";
import { ToastContext } from "src/context/toastContext";
import { AuthContext } from "src/context/authContext";
import { LoaderContext } from "src/context/loaderContext";
import { useNavigate } from "react-router-dom";
import { ToastTypes } from "src/types";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CreateOrderSchema } from "src/validation/createOrder";
import HubInputField from "src/components/inputType";
import { GroupBase, OptionsOrGroups } from "react-select";


const CreateOrderModal: FC<CreateOrderModalProps> = ({ isModalVisible, hideModal, orderType ,refreshListing}): JSX.Element => {
  const { control, handleSubmit, reset ,setValue } = useForm<CreateOrderProps>({
    mode: 'all',
    resolver: yupResolver(CreateOrderSchema),
  });
  const { showToast } = useContext(ToastContext);
  const { destroySession } = useContext(AuthContext);
  const { hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();




  const { loading: isCreateOrderLoading, data: creatOrderResponseData, mutate: createOrder, error: createOrderError } =
    useFetch<CreateOrderAPIResponse>(APIEndpoints.CREATE_ORDER);

  const clickOnCreateOrder = (values: CreateOrderProps) => {
    createOrder(values);
  }

  useEffect(() => {
    setValue("order_type",orderType && orderType.length>0 ? orderType[0].type : " ")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderType]);

  useEffect(() => {
    if (createOrderError) {
      reset()
      hideModal()
      hideLoader()
      const { code, error } = createOrderError as unknown as CreateOrderErrorResponse;
      showToast([error || `Can't create Order right now. Please try again in a while.`], ToastTypes.ERROR);
      if (code === 401) {
        destroySession();
        showToast([`You need to sign up or sign in to continue`], ToastTypes.ERROR);
        navigate(`/users/sign_in`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrderError]);

  useEffect(() => {
    if (creatOrderResponseData) {
      showToast([`Order Created Successfully`], ToastTypes.SUCCESS);
      hideLoader();
      reset();
      hideModal();
      refreshListing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatOrderResponseData]);





  return (
    <div
      className={`modal fade ${isModalVisible ? 'show show-modal' : ''}`}
      id="exampleModal"
      tabIndex={-1}
      data-bs-backdrop="true"
      aria-hidden="true"
      onClick={hideModal}
    >
      <form onSubmit={handleSubmit(clickOnCreateOrder)}>
        <div className="modal-dialog modal-lg" onClick={e => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <p className="modal-title fs-3 text-primary" id="exampleModalLabel">
                Order Product
              </p>
              <button type="button" className="btn-close" aria-label="Close" onClick={hideModal}></button>
            </div>

            <div className="modal-body">
              <p> Input information in the form below to place your order </p>

              <div className="my-3">
                <Controller
                  name="case_id"
                  control={control}
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <HubInputField
                      inputId="case_id"
                      isRowBased={true}
                      isRequired={false}
                      field={field}
                      inputLabel="Case"
                      inputPlaceholder="Case ID"
                      error={error}
                    />
                  )}
                />
              </div>

              <div className="my-3">
                <Controller
                  name="order_type"
                  control={control}
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <HubInputField
                      inputId="order_type"
                      inputLabel="Order Type"
                      isRowBased={true}
                      isRequired={true}
                      field={field}
                      isDropdown={true}
                      isSearchable={false}
                      dropdownData={orderType.map(({ name,type }) => ({ label: name, value: type }) ) as unknown as OptionsOrGroups<string, GroupBase<string>>}
                      error={error}
                    />
                  )}
                />
              </div>

              <div className="my-3">
                <Controller
                  name="po_number"
                  control={control}
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <HubInputField
                      inputId="po_number"
                      inputLabel="PO Number"
                      isRequired={false}
                      isRowBased={true}
                      field={field}
                      error={error}
                      inputPlaceholder="PO Number"
                    />
                  )}
                />
              </div>

              <div className="my-3">
                <Controller
                  name="qty"
                  control={control}
                  defaultValue={0}
                  render={({ field, fieldState: { error } }) => (
                    <HubInputField
                      inputId="qty"
                      inputLabel="Qty"
                      isRowBased={true}
                      isRequired={false}
                      field={field}
                      inputPlaceholder="Qty"
                      error={error}
                    />
                  )}
                />
              </div>
            </div>

            <div className="modal-footer justify-content-start">
              <button type="submit" className="btn btn-primary" data-bs-dismiss="modal" disabled={isCreateOrderLoading}>
                <div className="row align-items-center px-1">
                  {isCreateOrderLoading ? (
                    <div className="spinner-border text-light col-3" role="status">
                      <span className="sr-only"></span>
                    </div>
                  ) : null}
                  <p className="col-auto mb-0"> Order </p>
                </div>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateOrderModal;
