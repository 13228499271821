import {
  GeographicalStatisticsObject,
  topPayorsMOSECRProps,
} from "src/pages/manufacturer/types";
import packageInfo from "../../package.json";
import axios from "axios";

export const objectToQueryString = (obj: any) => {
  const keys = Object.keys(obj);
  const keyValuePairs = keys.map((key) => {
    return encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]);
  });
  return keyValuePairs.join("&");
  // more code goes here
};
export const wrapperFunction = (value: GeographicalStatisticsObject[]) => {
  const dataArray: any = [];
  if (value && value.length > 0) {
    value.forEach((item) => {
      const array: [string, number] = [
        "us-" + item.state.toLocaleLowerCase(),
        item.case_count,
      ];
      dataArray.push(array);
    });
  }
  return dataArray;
};

export const wrapperFunctionPieChart = (pieChartData: topPayorsMOSECRProps[]) => {
  return [{
    name: "Percentage",
    type: "pie",
    data: pieChartData.map((item) => ({
      name: item.name || "",
      y: item.value,
    }))
  }]
}

export const updateApiVersion = async () => {
  const response = await axios(
    `${process.env.REACT_APP_BASE_URL}/update_api_version?APP_VERSION=${packageInfo.version}`,
    {
      method: "GET",
      headers: {
        ClientDb: process.env.REACT_APP_CLIENT_DB,
      },
    }
  );
  console.log(response, "     ");
};

export const emptyCacheAndCookies = () => {

  document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  if ("caches" in window) {
    caches.keys().then((names) => {
      // Delete all the cache files
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    // Makes sure the page reloads. Changes are only visible after you refresh.
   setTimeout(() => window.location.reload(), 2000)
  }
};
