import { FC, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { GroupBase, OptionsOrGroups } from "react-select";

import HubInputField from "src/components/inputType";

import { OrderTreatmentSchema } from "src/validation/OrderTreatment";

import useFetch from "src/hooks/useFetch";
import { ToastContext } from "src/context/toastContext";
import { AuthContext } from "src/context/authContext";
import { APIEndpoints } from "src/types/apiTypes";
import { OrderTreatmentProps, TreatmentOrderModalProps } from "./types";
import { ToastTypes } from "src/types";
import { CreateOrderAPIResponse, CreateOrderErrorResponse } from "../createOrderModal/types";




const TreatmentOrderModal: FC<TreatmentOrderModalProps> = ({ isModalVisible, patientName, hideModal, orderType, refreshListing, caseId }): JSX.Element => {
  const { control, handleSubmit, setValue, reset } = useForm<OrderTreatmentProps>({
    mode: 'all',
    resolver: yupResolver(OrderTreatmentSchema)
  });

  const { showToast } = useContext(ToastContext);
  const { destroySession } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    setValue("order_type", orderType && orderType.length > 0 ? orderType[0].type : "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderType]);

  const internalHideModal = () => {
    reset()
    hideModal()
  };





  const { loading, data: creatOrderResponseData, mutate: createOrder, error: createOrderError } =
    useFetch<CreateOrderAPIResponse>(APIEndpoints.CREATE_ORDER);

  useEffect(() => {
    if (createOrderError) {
      const { code, error } = createOrderError as unknown as CreateOrderErrorResponse;
      if (code === 401) {
        destroySession();
        showToast([`You need to sign up or sign in to continue`], ToastTypes.ERROR);
        navigate(`/users/sign_in`);
      } else {
        showToast([error || `Can't create Order right now. Please try again in a while.`], ToastTypes.ERROR);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrderError]);

  useEffect(() => {
    if (creatOrderResponseData) {
      showToast([`Order Created Successfully`], ToastTypes.SUCCESS);
      refreshListing();
      internalHideModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatOrderResponseData]);

  const clickOnCreateOrder = (values: OrderTreatmentProps) => {
    const requestBody = {
      order_type: values.order_type,
      po_number: values.po_number,
      case_id: caseId,
    }
    createOrder(requestBody)
  };





  return (
    <div
      className={`modal fade ${isModalVisible ? 'show show-modal' : ''}`}
      id="exampleModal"
      tabIndex={-1}
      data-bs-backdrop="true"
      aria-hidden="true"
      onClick={hideModal}
    >
      <div className="modal-dialog modal-lg" onClick={e => e.stopPropagation()}>
        <div className="modal-content">
          <div className="modal-header">
            <p className="modal-title fs-3 text-primary" id="exampleModalLabel">
              Order Product {patientName ? `for ${patientName}` : ""}
            </p>
            <button type="button" className="btn-close" aria-label="Close" onClick={hideModal}></button>
          </div>

          <form onSubmit={handleSubmit(clickOnCreateOrder)}>
            <div className="modal-body">
              <p> Input information in the form below to place your order </p>

              <div className="row align-items-center mb-3">
                <div className="col-3">
                  <p className="mb-0 text-end"> Order Type * </p>
                </div>

                <div className="col-7">
                  <Controller
                    name="order_type"
                    control={control}
                    defaultValue=""
                    render={({ field, fieldState: { error } }) => (
                      <HubInputField
                        inputId="order_type"
                        isRowBased={true}
                        isRequired={false}
                        field={field}
                        isDropdown={true}
                        isSearchable={false}
                        dropdownData={orderType.map(({ name, type }) => ({ label: name, value: type })) as unknown as OptionsOrGroups<string, GroupBase<string>>}
                        error={error}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="row align-items-center mb-3">
                <div className="col-3">
                  <p className="mb-0 text-end"> PO Number  </p>
                </div>

                <div className="col-7">
                  <Controller
                    name="po_number"
                    control={control}
                    defaultValue=""
                    render={({ field, fieldState: { error } }) => (
                      <HubInputField
                        inputId="po_number"
                        isRequired={true}
                        isRowBased={true}
                        field={field}
                        error={error}
                        inputPlaceholder="PO Number"
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="modal-footer text-end">
              <button type="submit" className="btn btn-primary" disabled={loading} data-bs-dismiss="modal">
                <div className="row align-items-center px-1">
                  {loading ? (
                    <div className="spinner-border text-light col-3" role="status">
                      <span className="sr-only"></span>
                    </div>
                  ) : null}
                  <p className="col-auto mb-0"> Order </p>
                </div>
              </button>
            </div>
          </form>

        </div>
      </div>
    </div>
  );
};


export default TreatmentOrderModal;
