import { FC, useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";

import FooterText from "../../components/footerText";
// import ContactAndSafetyInfo from "../../components/contactAndSafetyInfo";
// import { TextAlignProps } from "../../components/contactAndSafetyInfo/types";
import HubInputField from "../../components/inputType";

import { AuthContext } from "../../context/authContext";
import { ToastContext } from "../../context/toastContext";

// import { AvanosLogo } from "../../assets";
import { LoginSchema } from "../../validation/login";
import useFetch from "../../hooks/useFetch";
import { APIEndpoints } from "../../types/apiTypes";
import { LoginFormType, LoginAPIResponseType } from "./types";
import { ToastTypes } from "../../types";
import { UserType } from "src/types/users";
import { USER_TYPES } from "src/constants";
// import { NearFooterImg, ProductBasedLogo } from "src/assets";

const HubLogin: FC = (): JSX.Element => {
  const { control, handleSubmit } = useForm({
    mode: 'all',
    resolver: yupResolver(LoginSchema),
  });
  const [isPasswordVisible, setPasswordVisibility] = useState<boolean>(false);

  const { userData: sessionSavedUser, createSession, restoreSession } = useContext(AuthContext);
  const { showToast } = useContext(ToastContext);
  const navigate = useNavigate();

  const reRouteRelevantUser = (userData: UserType) => {
    if (userData.is_password_changed === false) {
      navigate("/users/createuser");
    } else {
      if (USER_TYPES.MANUFACTURER.includes(userData.ref_type)) {
        navigate("/statistics");
      } else if (USER_TYPES.HCP.includes(userData.ref_type)) {
        navigate("/cases");
      } else {
        navigate("/cases");
      }
    }
  };

  const { data: userData, loading, mutate, error, removeErrorAndData } = useFetch<LoginAPIResponseType>(APIEndpoints.USER_SIGNIN);

  useEffect(() => {
    if (error && error.length > 0)
      showToast([error], ToastTypes.ERROR);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (userData && userData.status.code === 200) {
      const { status, token } = userData;
      createSession(status.data.user, status.data.sub_header, token);
      showToast([status.message], ToastTypes.INFO);

      reRouteRelevantUser(status.data.user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const submitForm = async (values: LoginFormType) => {
    if ((error && error.length > 0) || userData) removeErrorAndData();
    const isSessionExists = restoreSession();

    if (!isSessionExists) {
      mutate({ user: values });
    } else {
      showToast([`There is already an active user session`], ToastTypes.INFO);
      if (sessionSavedUser) reRouteRelevantUser(sessionSavedUser);
    }
  };

  return (
      <div className="container-fluid login-sec d-flex flex-fill flex-column justify-content-between overflow-auto">
        <div className="container d-flex flex-fill flex-column align-items-center pt-4 pb-4 ps-4 pe-4">
          <div className="card shadow-lg my-3">
            <h2 className="text-center m-1">Avanos has transitioned from orthogenrx.aspnprograms.com to harmoknee.support effective September 3rd, 2024</h2>
            <Link to="/enrollments/new" className="text-center text-link text-decoration-none fs-4 fw-medium"> Click Here To Register </Link>
          </div>
          <div className="row justify-content-center align-items-center flex-fill mb-3">
            <div className="col-12 col-md-7">
              <h1 className="login-text">
                HA Support for Practices. HA Support for Patients.
                That’s HARMOKNEE REIMBURSEMENT SOLUTIONS.
              </h1>
              <h2 className="sub-text">
                We make it simple to access HA so you can deliver the best patient experience possible.
              </h2>
            </div>
            <div className="col-12 col-md-1"></div>
            <div className="col-12 col-md-4">
              <form className="card shadow-lg" onSubmit={handleSubmit(submitForm)}>
                <div className="card-header">
                  <h3 className="m-0">Sign in</h3>
                  <small className="m-0">Please sign in with your credentials</small>
                </div>

                <div className="card-body">
                  <div className="mb-4">
                    <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        render={({field, fieldState: {error}}) => (
                            <HubInputField
                                inputId="email"
                                inputPlaceholder="Email"
                                isRowBased={false}
                                isRequired={true}
                                field={field}
                                error={error}
                                inputType="email"
                            />
                        )}
                    />
                  </div>
                  <Controller
                      name="password"
                      control={control}
                      defaultValue=""
                      render={({field, fieldState: {error}}) => (
                          <>
                            <div className="input-group mb-3">
                              <input type={isPasswordVisible ? "text" : "password"} placeholder="Password"
                                     className="form-control" {...field} />
                              <button
                                  type="button"
                                  className="btn btn-outline-dark"
                                  onClick={() => setPasswordVisibility((isPasswordVisible) => !isPasswordVisible)}
                              >
                                {isPasswordVisible ? (
                                    <i className="bi bi-eye-fill"></i>
                                ) : (
                                    <i className="bi bi-eye-slash"></i>
                                )}
                              </button>
                            </div>
                            {error && (<p className="mb-0 text-danger">{error.message}</p>)}
                          </>
                      )}
                  />
                </div>

                <div className="card-footer d-flex align-items-center justify-content-between">
                  <button disabled={loading} className="btn btn-primary" type="submit">
                    {loading ? (<i className="spinner-border"></i>) : null} Sign In
                  </button>
                  <Link to="/users/forgotpassword" className="text-black text-decoration-none">Forgot your
                    password?</Link>
                </div>
              </form>
            </div>
          </div>
          {/* <h5 className="text-white text-center m-0">
            Avanos has transitioned from orthogenrx.aspnprograms.com to harmoknee.support effective September 3rd, 2024
          </h5> */}
        </div>
        <FooterText footerType="black"/>
      </div>
  );
};

export default HubLogin;
