import { FC, useContext, useEffect, useReducer, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

import AuthSubHeader from "src/components/authSubHeader";
import FooterText from "src/components/footerText";
import TreatmentInstillationModal from "src/components/modals/treatmentInstillationModal";
import TreatmentOrderModal from "src/components/modals/orderTreatmentModal";

import useFetch from "src/hooks/useFetch";
import { APIEndpoints } from "src/types/apiTypes";
import { ToastContext } from "src/context/toastContext";
import { ToastTypes } from "src/types";
import { AuthContext } from "src/context/authContext";
import { LoaderContext } from "src/context/loaderContext";
import TreatmentItemActions from "./treatmentItemActions";

import { initialState, queryParamReducer } from "./reducer";
import { TreatmentListErrorResponse, QueryParamsState, QueryStateActionType, QueryStateActions, TreatmentListAPIResponse, TreatmentStatusUpdateApiResponse, ScheduleTreatmentModalProps } from "./types";
import { TREATMENT_HEADER_LISTING } from "src/constants/cases";
import { getDateTimeFormat } from "src/helpers/dateTime";



const TreatmentListing: FC = (): JSX.Element => {
  const [queryParams, dispatch] = useReducer(queryParamReducer, initialState);
  const [scheduleTreatmentModal, setDataForscheduleTreatmentModal] = useState<ScheduleTreatmentModalProps>({
    isModalVisible: false,
    patientName: "",
    treatmentId: 0,
    instillation: "",
    treatedAt: ""
  });
  const [orderTreatmentModal, setDataForOrderModal] = useState<{ isModalVisible: boolean; patientName: string; caseId:number }>({
    isModalVisible: false,
    patientName: "",
    caseId : 0,
  });

  const { showToast } = useContext(ToastContext);
  const { destroySession } = useContext(AuthContext);
  const { setLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();





  const { loading, data: treatmentUpdateData, mutate: updatetreatment, error: treatmentUpdateError } =
    useFetch<TreatmentStatusUpdateApiResponse>(APIEndpoints.TREATMENT_STATUS_UPDATE);

  useEffect(() => {
    if (treatmentUpdateError) {
      hideLoader();
      const { code, error } = treatmentUpdateError as unknown as TreatmentListErrorResponse;
      showToast([error || `Treatment can't update now. Please try again in a while.`], ToastTypes.ERROR);
      if (code === 401) {
        destroySession();
        navigate(`/users/sign_in`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treatmentUpdateError]);

  useEffect(() => {
    if (treatmentUpdateData && treatmentUpdateData.code) {
      getTreatmentListing(null, null, initialState);
      hideLoader();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treatmentUpdateData]);

  const onTreatedOrNotTreatedButtonClicked = (value: string, treatment_id: number) => {
    const requestBody = { status: 0 };

    if (value === "Not-Treated") {
      requestBody.status = 31;
    } else {
      requestBody.status = 22;
    }
    setLoader()
    updatetreatment(requestBody, { treatmentId: treatment_id });
  };





  const { data: treatmentData, mutate: getTreatmentListing, error: treatmentError, removeErrorAndData: clearTreatmentState } =
    useFetch<TreatmentListAPIResponse, QueryParamsState>(APIEndpoints.TREATMENT_LISTING, queryParams);

  useEffect(() => {
    if (treatmentError) {
      hideLoader();
      const { code, error } = treatmentError as unknown as TreatmentListErrorResponse;
      showToast([error || `Can't fetch treatments right now. Please try again in a while.`], ToastTypes.ERROR);
      if (code === 401) {
        destroySession();
        showToast([`You need to sign up or sign in to continue`], ToastTypes.ERROR);
        navigate(`/users/sign_in`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treatmentError]);

  useEffect(() => {
    if (treatmentData && treatmentData.code) {
      hideLoader();
      if (treatmentData.code === 500) {
        showToast(['No treatments found against provided filters'], ToastTypes.ERROR);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treatmentData]);

  // const onClearButtonClicked = () => {
  //   if (treatmentError || treatmentData) clearTreatmentState();
  //   dispatch({
  //     type: QueryStateActions.CLEAR_STATE,
  //   });
  //   getTreatmentListing(null, null, initialState);
  // };

  const handleSearchWithQueryParam = (updatedState?: QueryParamsState) => {
    if (treatmentError || treatmentData) clearTreatmentState();
    setLoader();
    getTreatmentListing(null, null, updatedState ? updatedState : queryParams);
  };





  const changeIdOrder = (value: string) => {
    const order = queryParams.direction === 'desc' ? 'asc' : 'desc';

    const action: QueryStateActionType = {
      type: QueryStateActions.CHANGE_SORT_PARAMS,
      payload: {
        direction: queryParams.sort_by === value ? order : "desc",
        sort_by: value,
      },
    };
    dispatch(action);

    const updatedState = queryParamReducer(queryParams, action);
    handleSearchWithQueryParam(updatedState);
  };

  const changePageNumber = (page: { selected: number; }) => {
    const action: QueryStateActionType = {
      type: QueryStateActions.CHANGE_PAGE,
      payload: page.selected + 1,
    };
    dispatch(action);

    const updatedState = queryParamReducer(queryParams, action);
    handleSearchWithQueryParam(updatedState);
  };

  return (
    <div className="container-fluid auth-page">
      <AuthSubHeader />

      <div className="card m-1 mt-4">
        <div className="card-header">
          <h3 className="text-black m-0">Scheduled Treatments</h3>
        </div>

        <div className="card-body p-0">
          <div className="px-3 pt-3">
            <p>The following is a list of all treatments requested and scheduled at your location(s)</p>
            {/* <TableFilters
              dropdownData={TREATMENT_STATUS_LISTING}
              inputPlaceholder="Search by Treatment ID Case ID, Patient, Physician"
              state={queryParams}
              dispatch={dispatch}
              handleSearchWithQueryParam={handleSearchWithQueryParam}
              onClearButtonClicked={onClearButtonClicked}
            /> */}
          </div>

          <div className="table-responsive mt-3">
            <table className="table table-striped table-sm">
              <thead>
                <tr className="text-center align-items-center">
                  {TREATMENT_HEADER_LISTING.map((tableHeader, index) => (
                    <th key={index} className="text-white bg-primary" scope="col" onClick={() => changeIdOrder(tableHeader.value)}>
                      <span
                        role={tableHeader.value === '' ? "textbox" : "button"}
                        className={
                          tableHeader.value === '' ?
                            'fw-bold fs-6 lh-base' :
                            'btn btn-link link-light link-underline link-underline-opacity-0 link-offset-0-hover link-underline-opacity-75-hover p-0 fw-bold fs-6'
                        }
                      >
                        {tableHeader.label}
                        {queryParams.sort_by === tableHeader.value ? (
                          <i className={`bi ${queryParams.direction === 'desc' ? 'bi-caret-down-fill' : 'bi-caret-up-fill'}`}></i>
                        ) : null}
                      </span>
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {treatmentData && treatmentData.data && treatmentData.data.treatments && treatmentData.data.treatments.length > 0 ?
                  treatmentData.data.treatments.map((item) => (
                    <tr key={item.case_id} className="align-middle text-center">
                      <td>
                        <Link
                          to={{ pathname: `/cases/detail/${item.case_id}` }}
                          className="btn btn-link link-underline link-underline-opacity-0 link-offset-0-hover link-underline-opacity-75-hover fs-6"
                        >
                          {item.case_id}
                        </Link>
                      </td>
                      <td className="fs-6">
                        {item.schedule_treatment_popup ?
                          (
                            <button
                              type="button"
                              className="btn btn-link link-underline link-underline-opacity-0 link-offset-0-hover link-underline-opacity-75-hover fs-6"
                              onClick={() => setDataForscheduleTreatmentModal({ isModalVisible: true, patientName: item.patient, treatmentId: item.treatment_id, instillation: item.instillation, treatedAt: item.treated_at })}
                            >
                              {item ? getDateTimeFormat(item.instillation) : ''}
                            </button>
                          ) : (
                            <p className="fs-6 mb-0">
                              {item ? getDateTimeFormat(item.instillation) : ''}
                            </p>
                          )
                        }
                      </td>
                      <td className="fs-6">{item ? item.ordered : ''}</td>
                      <td className="fs-6">{item && item.delivery_eta !== null ? item.delivery_eta : 'N/A'}</td>
                      <td className="fs-6">{item ? item.patient : ''}</td>
                      <td className="fs-6">{item ? item.provider : ''}</td>
                      <td className={`text-white align-middle fs-6 background-color-${item && item.status_color ? item.status_color : 1}`}>
                        {item ? item.treatment_status : ''}
                      </td>
                      <td>
                        <TreatmentItemActions
                          item={item}
                          isAPICalling={loading}
                          setDataForOrderModal={setDataForOrderModal}
                          setDataForscheduleTreatmentModal={setDataForscheduleTreatmentModal}
                          onTreatedOrNotTreatedButtonClicked={onTreatedOrNotTreatedButtonClicked}
                        />
                      </td>
                    </tr>
                  ))
                  : null}
              </tbody>
            </table>

            {treatmentData && treatmentData.data.total_pages ? (
              <div className="pagination justify-content-center">
                {queryParams.page !== 1 ? (
                  <li className="page-item">
                    <button className="btn btn-link page-link" onClick={() => changePageNumber({ selected: 0 })}>
                      <i className="bi bi-chevron-double-left"></i> First
                    </button>
                  </li>
                ) : null}
                <ReactPaginate
                  nextLabel={<>Next <i className="bi bi-chevron-right"></i></>}
                  onPageChange={changePageNumber}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={treatmentData.data.total_pages}
                  previousLabel={<><i className="bi bi-chevron-left"></i> Previous</>}
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link rounded-0"
                  nextClassName="page-item"
                  nextLinkClassName="page-link rounded-0"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                  forcePage={queryParams.page - 1}
                />
                {queryParams.page !== treatmentData.data.total_pages ? (
                  <li className={`page-item ${queryParams.page === treatmentData.data.total_pages ? 'disabled' : ''}`}>
                    <button className="btn btn-link page-link" onClick={() => changePageNumber({ selected: treatmentData.data.total_pages - 1 })}>
                      Last <i className="bi bi-chevron-double-right"></i>
                    </button>
                  </li>
                ) : null}
              </div>
            ) : null}

          </div>
        </div>
      </div>

      <FooterText footerType="black" />

      <TreatmentInstillationModal
        isModalVisible={scheduleTreatmentModal.isModalVisible}
        patientName={scheduleTreatmentModal.patientName}
        treatmentId={scheduleTreatmentModal.treatmentId}
        instillation={scheduleTreatmentModal.instillation}
        treatedAt={scheduleTreatmentModal.treatedAt}
        hideModal={() => {
          setDataForscheduleTreatmentModal({
            isModalVisible: false,
            patientName: "",
            treatmentId: 0,
            instillation: "",
            treatedAt: "",
          });
        }}
        refreshListing = {()=> {
          getTreatmentListing(null, null, initialState);
        }}
      />

      <TreatmentOrderModal
        isModalVisible={orderTreatmentModal.isModalVisible}
        patientName={orderTreatmentModal.patientName}
        hideModal={() => {
          setDataForOrderModal({
            isModalVisible: false,
            patientName: "",
            caseId: 0,
          });
        }}
        caseId = {orderTreatmentModal.caseId}
        orderType={treatmentData ? treatmentData.data.order_type : []}
        refreshListing = {()=> {
          getTreatmentListing(null, null, initialState);
        }}
      />
    </div>
  );
};

export default TreatmentListing;
