import { FC, useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

import AuthSubHeader from "src/components/authSubHeader";
import FooterText from "src/components/footerText";
import CaseStepOne from "src/components/caseStepOne";
import CaseStepTwo from "src/components/caseStepTwo";
import CaseStepThree from "src/components/caseStepThree";

import { AddCaseProps, InsuranceAttributesProps, PatientAddress, PatientPhoneNumber } from "src/pages/addCase/types";
import { AddCaseSchema } from "src/validation/addCase";
import { AuthContext } from "src/context/authContext";
import { ToastContext } from "src/context/toastContext";
import { ToastTypes } from "src/types";
import { DEFAULT_DRUG_ID } from "src/constants/cases";
import useFetch from "src/hooks/useFetch";
import { APIEndpoints } from "src/types/apiTypes";
import { CaseClonePathParams, CaseCloneResponseType } from "./types";
import { LoaderContext } from "src/context/loaderContext";
import { populatingCloneCase } from "src/helpers/cloneCase";
import { UserType } from "src/types/users";



const HubCaseClone: FC = (): JSX.Element => {
  const [step, changeStep] = useState<number>(1);
  const [isStepTwoMounted, mountingStepTwo] = useState<boolean>(false);
  const [isStepOneInvalid, setStepOneValidity] = useState<boolean>(false);
  const [isStepTwoInvalid, setStepTwoValidity] = useState<boolean>(false);

  const { userData } = useContext(AuthContext);
  const { showToast } = useContext(ToastContext);
  const { hideLoader, setLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  const { caseId } = useParams();

  const { control, formState, handleSubmit, getValues, watch, trigger, setValue, reset ,resetField} = useForm<AddCaseProps>({
    mode: 'onChange',
    resolver: yupResolver(AddCaseSchema),
    defaultValues: {
      mos: "online",
      submission_source: "provider",
      prescription_attributes: {
        drug_id: DEFAULT_DRUG_ID,
      },
      patient_attributes: {
        phone_numbers: [
          {
            phone_type: "cell",
          } as PatientPhoneNumber,
        ],
        address: {
          address_name: "home"
        } as PatientAddress,
      },
      insurances_attributes: [
        {
          global_insurance_id: null,
          is_primary_insurance: true,
          insurance_priority: 0,
        } as InsuranceAttributesProps,
      ],
    }
  });
  const patientData = watch("patient_attributes");



  useEffect(() => {
    if (patientData && patientData.global_patient_id)
      trigger(["patient_attributes", "insurances_attributes"]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientData]);





  const { error: caseCloneError, data: caseCloneData, loading: caseCloneLoading } =
    useFetch<CaseCloneResponseType, {}, CaseClonePathParams>(APIEndpoints.CLONE_CASE_DETAILS, {}, { caseId: caseId ? Number(caseId) : 0 });

  useEffect(() => {
    if (caseCloneLoading === true) setLoader();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseCloneLoading]);

  useEffect(() => {
    if (caseCloneError) hideLoader();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseCloneError]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (caseCloneData)
      timeout = setTimeout(() => {
        hideLoader();
        console.log(caseCloneData.data);
        const caseFormData = populatingCloneCase(caseCloneData, userData || {} as UserType, getValues);
        reset(caseFormData);
      }, 1500);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseCloneData]);





  useEffect(() => {
    const { errors } = formState;

    if (Object.keys(errors).length > 0) {
      if (Object.hasOwn(errors, 'prescription_attributes') || Object.hasOwn(errors, 'procedure_attributes'))
        setStepOneValidity(true);
      else
        setStepOneValidity(false);

      if (Object.hasOwn(errors, 'patient_attributes') ||
        Object.hasOwn(errors, 'case_doctor') ||
        Object.hasOwn(errors, 'insurances_attributes')
      )
        setStepTwoValidity(true);
      else
        setStepTwoValidity(false);
    } else {
      setStepOneValidity(false);
      setStepTwoValidity(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  useEffect(() => {
    const messages = [];
    const { errors } = formState;

    if (isStepOneInvalid && isStepTwoInvalid && step >= 2) {
      messages.push(`The following sections from step 1 have errors:
      ${errors.procedure_attributes ? '<br>- case procedure<br>' : ''}
      ${errors.prescription_attributes ? '- case prescription<br>' : ''}
      ${errors.category ? '- case type' : ''}`);

      messages.push(`The following sections from step 2 have errors: <br>
      ${errors.doctor_attributes ? '- case doctor<br>' : ''}
      ${errors && errors.patient_attributes ? '- case patient<br>' : ''}
      ${errors.patient_attributes && errors.patient_attributes.hasOwnProperty("phone_numbers") ? '- case patient phone numbers<br>' : ''}
      ${errors.patient_attributes && errors.patient_attributes.address ? '- case patient address<br>' : ''}
      ${errors.insurances_attributes ? '- case patient insurances' : ''}`);
    }
    else if (isStepOneInvalid && step >= 1) {
      messages.push(`The following sections from step 1 have errors:
      ${errors.procedure_attributes ? '<br>- case procedure<br>' : ''}
      ${errors.prescription_attributes ? '- case prescription<br>' : ''}
      ${errors.category ? '- case type' : ''}`);
    }
    else if (isStepTwoInvalid && step >= 2) {
      messages.push(`The following sections from step 2 have errors: <br>
      ${errors.doctor_attributes ? '- case doctor<br>' : ''}
      ${errors && errors.patient_attributes ? '- case patient<br>' : ''}
      ${errors.patient_attributes && errors.patient_attributes.hasOwnProperty("phone_numbers") ? '- case patient phone numbers<br>' : ''}
      ${errors.patient_attributes && errors.patient_attributes.address ? '- case patient address<br>' : ''}
      ${errors.insurances_attributes ? '- case patient insurances' : ''}`);
    }

    showToast(messages, ToastTypes.WARNING);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStepOneInvalid, isStepTwoInvalid, step]);


  const onStepProgressionClick = (localStep: number) => {
    if (step === 1)
      trigger(["procedure_attributes", "prescription_attributes"]);
    else if (step === 2)
      trigger(["doctor_attributes", "patient_attributes", "insurances_attributes"]);

    if (localStep >= 2)
      mountingStepTwo(true);
    else if (localStep === 3)
      trigger(["procedure_attributes", "prescription_attributes", "doctor_attributes", "patient_attributes", "insurances_attributes"]);

    changeStep(localStep);

    //:- logic to save case data in localstorage
    localStorage.setItem('newCase', JSON.stringify({ newData: getValues(), step }));
  };





  /**
   * @AddNewCase form handling for case data submission
   * @fourth case submit handler
   */
  const { loading: addCaseLoading, data: addCaseData, mutate: addNewCase } =
    useFetch<{ status: number }>(APIEndpoints.ADD_NEW_CASE);

  useEffect(() => {
    if (addCaseData && addCaseData.status === 200) {
      localStorage.removeItem('newCase');
      showToast([`Your request submitted successfully!`], ToastTypes.SUCCESS);
      navigate(`/cases`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addCaseData]);

  /** @fourth */
  const caseDataSubmission = (values: AddCaseProps) => {
    const newValues = {
      ...values,
      prescription_attributes: {
        ...values.prescription_attributes,
        diagnosis_code: values.prescription_attributes.diagnosis_code.join(","),
      },
      patient_firstlastname: `${values.patient_attributes.firstname} ${values.patient_attributes.lastname}`,
      patient_fullname: `${values.patient_attributes.firstname} ${values.patient_attributes.middlename} ${values.patient_attributes.lastname}`,
      patient_dob: values.patient_attributes.dob,
      insurances_attributes: values.insurances_attributes?.map((insurance, index) => {
        insurance.insurance_priority = index;
        return insurance;
      }),
      patient_attributes: {
        ...values.patient_attributes,
        ...(values.patient_attributes.patient_email && {
          patient_email: {
            id: values.patient_attributes.patient_email_id,
            email: values.patient_attributes.patient_email
          }
        })
      },
    };
    addNewCase({ case: newValues });
  };





  return (
    <div className="bg-light overflow-auto">
      <div className="container">
        <AuthSubHeader />

        <form onSubmit={handleSubmit(caseDataSubmission)}>
          <div className="bg-light py-3 sticky-top top-0 z-1">
            <div id="case-creation-progress-bar" className="case-progress-bar">
              <button type="button" id="progress-step-1"
                  className={`btn col-4 case-creation-progress-step ${step >= 1 ? isStepOneInvalid ? 'invalid-progress-step' : 'active-progress-step' : ''}`}
                  onClick={() => onStepProgressionClick(1)}
              >
                1. Getting Started <i className={`bi bi-play-fill progression-arrow-head`}></i>
              </button>

              <button type="button" id="progress-step-2"
                  className={`btn col-4 case-creation-progress-step ${step >= 2 ? isStepTwoInvalid ? 'invalid-progress-step' : 'active-progress-step' : ''}`}
                  onClick={() => onStepProgressionClick(2)}
              >
                2. Patient Information <i className={`bi bi-play-fill progression-arrow-head`}></i>
              </button>

              <button type="button" id="progress-step-3"
                  className={`btn col-4 case-creation-progress-step ${step >= 3 ? 'active-progress-step' : ''}`}
                  onClick={() => onStepProgressionClick(3)}
              >
                3. Finish
              </button>
            </div>
          </div>

          <Controller
            name="original_id"
            control={control}
            defaultValue={Number(caseId)}
            render={() => <></>}
          />

          {userData ? (
            <>
              <Controller
                name="creator_name"
                control={control}
                defaultValue={userData.name}
                render={() => (<></>)}
              />

              <Controller
                name="user_id"
                control={control}
                defaultValue={userData.id}
                render={() => (<></>)}
              />
            </>
          ) : (<></>)}

          <div id="step-1" className="case-creation-step">
            {step >= 1 && caseCloneData &&
                <CaseStepOne control={control} watch={watch} setValue={setValue} getValues={getValues}
                             isVisible={step === 1}/>}
          </div>
          <div id="step-2" className="case-creation-step">
            {isStepTwoMounted &&
                <CaseStepTwo resetField={resetField} control={control} getValues={getValues} watch={watch}
                             setValue={setValue} isVisible={step === 2} trigger={trigger} />}
          </div>
          <div id="step-3" className="case-creation-step">
            {step === 3 && <CaseStepThree getValues={getValues}/>}
          </div>

          <div className="mt-2 d-flex">
            <button type="button" key="previousStepButton"
                    className={`me-3 btn btn-outline-secondary ${step === 1 ? 'd-none' : ''}`}
                    onClick={() => onStepProgressionClick(step - 1)}>
              <i className="bi bi-chevron-double-left"></i> Previous
            </button>

            {step === 3 ? (
                <button type="submit" disabled={addCaseLoading} key="formSubmitButton"
                        className="btn btn-primary d-flex">
                  {addCaseLoading ? (
                      <i className="spinner-border text-white me-2" style={{width: `24px`, height: `24px`}}></i>
                  ) : null}
                  Submit Request
                </button>
            ) : (
                <button type="button" key="nextStepButton" className="btn btn-outline-secondary"
                        onClick={() => onStepProgressionClick(step + 1)}
                >
                  Next <i className="bi bi-chevron-double-right"></i>
                </button>
            )}
          </div>
        </form>

        <FooterText footerType="black"/>
      </div>
    </div>
  );
};

export default HubCaseClone;
