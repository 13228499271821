export const mapStatesData = [
  {
    state: "AL",
    cases: "0 (0.0%)",
  },
  {
    state: "AK",
    cases: "1 (0.18%)",
  },
  {
    state: "AZ",
    cases: "6 (1.08%)",
  },
  {
    state: "AR",
    cases: "0 (0.0%)",
  },
  {
    state: "CA",
    cases: "30 (5.42%)",
  },
  {
    state: "CO",
    cases: "1 (0.18%)",
  },
  {
    state: "CT",
    cases: "6 (1.08%)",
  },
  {
    state: "DC",
    cases: "2 (0.36%)",
  },
  {
    state: "DE",
    cases: "13 (2.35%)",
  },
  {
    state: "FL",
    cases: "36 (6.51%)",
  },
  {
    state: "FM",
    cases: "0 (0.0%)",
  },
  {
    state: "GA",
    cases: "12 (2.17%)",
  },
  {
    state: "GU",
    cases: "0 (0.0%)",
  },
  {
    state: "HI",
    cases: "0 (0.0%)",
  },
  {
    state: "ID",
    cases: "5 (0.9%)",
  },
  {
    state: "IL",
    cases: "26 (4.7%)",
  },
  {
    state: "IN",
    cases: "20 (3.62%)",
  },
  {
    state: "IA",
    cases: "6 (1.08%)",
  },
  {
    state: "KS",
    cases: "10 (1.81%)",
  },
  {
    state: "KY",
    cases: "2 (0.36%)",
  },
  {
    state: "LA",
    cases: "15 (2.71%)",
  },
  {
    state: "ME",
    cases: "0 (0.0%)",
  },
  {
    state: "MD",
    cases: "28 (5.06%)",
  },
  {
    state: "MA",
    cases: "66 (11.93%)",
  },
  {
    state: "MH",
    cases: "0 (0.0%)",
  },
  {
    state: "MI",
    cases: "4 (0.72%)",
  },
  {
    state: "MN",
    cases: "3 (0.54%)",
  },
  {
    state: "MS",
    cases: "0 (0.0%)",
  },
  {
    state: "MO",
    cases: "10 (1.81%)",
  },
  {
    state: "MP",
    cases: "0 (0.0%)",
  },
  {
    state: "MT",
    cases: "0 (0.0%)",
  },
  {
    state: "NE",
    cases: "5 (0.9%)",
  },
  {
    state: "NV",
    cases: "1 (0.18%)",
  },
  {
    state: "NH",
    cases: "8 (1.45%)",
  },
  {
    state: "NJ",
    cases: "22 (3.98%)",
  },
  {
    state: "NM",
    cases: "0 (0.0%)",
  },
  {
    state: "NY",
    cases: "45 (8.14%)",
  },
  {
    state: "NC",
    cases: "41 (7.41%)",
  },
  {
    state: "ND",
    cases: "3 (0.54%)",
  },
  {
    state: "OH",
    cases: "17 (3.07%)",
  },
  {
    state: "OK",
    cases: "4 (0.72%)",
  },
  {
    state: "OR",
    cases: "0 (0.0%)",
  },
  {
    state: "PA",
    cases: "14 (2.53%)",
  },
  {
    state: "PW",
    cases: "0 (0.0%)",
  },
  {
    state: "PR",
    cases: "0 (0.0%)",
  },
  {
    state: "RI",
    cases: "1 (0.18%)",
  },
  {
    state: "SC",
    cases: "10 (1.81%)",
  },
  {
    state: "SD",
    cases: "0 (0.0%)",
  },
  {
    state: "TN",
    cases: "5 (0.9%)",
  },
  {
    state: "TX",
    cases: "39 (7.05%)",
  },
  {
    state: "UT",
    cases: "0 (0.0%)",
  },
  {
    state: "VT",
    cases: "0 (0.0%)",
  },
  {
    state: "VI",
    cases: "0 (0.0%)",
  },
  {
    state: "VA",
    cases: "6 (1.08%)",
  },
  {
    state: "WA",
    cases: "14 (2.53%)",
  },
  {
    state: "WV",
    cases: "4 (0.72%)",
  },
  {
    state: "WI",
    cases: "12 (2.17%)",
  },
  {
    state: "WY",
    cases: "0 (0.0%)",
  },
];

const mapperFuncforStringTonumber = (item: string) => {
  let test = item.replace(/['"]+/g, "").replace("(?s) .*", "");
  return parseInt(test);
};

export const arrayConversion = () => {
  let dataList: any = [];
  mapStatesData.forEach((items: any) => {
    let data = [items.state, mapperFuncforStringTonumber(items.cases)];
    dataList.push(data);
  });
  return dataList;
};

export const stateData: [string, number][] = [
  ["fo", 0],
  ["um", 1],
  ["us", 2],
  ["jp", 3],
  ["sc", 4],
  ["in", 5],
  ["fr", 6],
  ["fm", 7],
  ["cn", 8],
  ["pt", 9],
  ["sw", 10],
  ["sh", 11],
  ["br", 12],
  ["ki", 13],
  ["ph", 14],
  ["mx", 15],
  ["es", 16],
  ["bu", 17],
  ["mv", 18],
  ["sp", 19],
  ["gb", 20],
  ["gr", 21],
  ["as", 22],
  ["dk", 23],
  ["gl", 24],
  ["gu", 25],
  ["mp", 26],
  ["pr", 27],
  ["vi", 28],
  ["ca", 29],
  ["st", 30],
  ["cv", 31],
  ["dm", 32],
  ["nl", 33],
  ["jm", 34],
  ["ws", 35],
  ["om", 36],
  ["vc", 37],
  ["tr", 38],
  ["bd", 39],
  ["lc", 40],
  ["nr", 41],
  ["no", 42],
  ["kn", 43],
  ["bh", 44],
  ["to", 45],
  ["fi", 46],
  ["id", 47],
  ["mu", 48],
  ["se", 49],
  ["tt", 50],
  ["my", 51],
  ["pa", 52],
  ["pw", 53],
  ["tv", 54],
  ["mh", 55],
  ["cl", 56],
  ["th", 57],
  ["gd", 58],
  ["ee", 59],
  ["ag", 60],
  ["tw", 61],
  ["bb", 62],
  ["it", 63],
  ["mt", 64],
  ["vu", 65],
  ["sg", 66],
  ["cy", 67],
  ["lk", 68],
  ["km", 69],
  ["fj", 70],
  ["ru", 71],
  ["va", 72],
  ["sm", 73],
  ["kz", 74],
  ["az", 75],
  ["tj", 76],
  ["ls", 77],
  ["uz", 78],
  ["ma", 79],
  ["co", 80],
  ["tl", 81],
  ["tz", 82],
  ["ar", 83],
  ["sa", 84],
  ["pk", 85],
  ["ye", 86],
  ["ae", 87],
  ["ke", 88],
  ["pe", 89],
  ["do", 90],
  ["ht", 91],
  ["pg", 92],
  ["ao", 93],
  ["kh", 94],
  ["vn", 95],
  ["mz", 96],
  ["cr", 97],
  ["bj", 98],
  ["ng", 99],
  ["ir", 100],
  ["sv", 101],
  ["sl", 102],
  ["gw", 103],
  ["hr", 104],
  ["bz", 105],
  ["za", 106],
  ["cf", 107],
  ["sd", 108],
  ["cd", 109],
  ["kw", 110],
  ["de", 111],
  ["be", 112],
  ["ie", 113],
  ["kp", 114],
  ["kr", 115],
  ["gy", 116],
  ["hn", 117],
  ["mm", 118],
  ["ga", 119],
  ["gq", 120],
  ["ni", 121],
  ["lv", 122],
  ["ug", 123],
  ["mw", 124],
  ["am", 125],
  ["sx", 126],
  ["tm", 127],
  ["zm", 128],
  ["nc", 129],
  ["mr", 130],
  ["dz", 131],
  ["lt", 132],
  ["et", 133],
  ["er", 134],
  ["gh", 135],
  ["si", 136],
  ["gt", 137],
  ["ba", 138],
  ["jo", 139],
  ["sy", 140],
  ["mc", 141],
  ["al", 142],
  ["uy", 143],
  ["cnm", 144],
  ["mn", 145],
  ["rw", 146],
  ["so", 147],
  ["bo", 148],
  ["cm", 149],
  ["cg", 150],
  ["eh", 151],
  ["rs", 152],
  ["me", 153],
  ["tg", 154],
  ["la", 155],
  ["af", 156],
  ["ua", 157],
  ["sk", 158],
  ["jk", 159],
  ["bg", 160],
  ["qa", 161],
  ["li", 162],
  ["at", 163],
  ["sz", 164],
  ["hu", 165],
  ["ro", 166],
  ["ne", 167],
  ["lu", 168],
  ["ad", 169],
  ["ci", 170],
  ["lr", 171],
  ["bn", 172],
  ["iq", 173],
  ["ge", 174],
  ["gm", 175],
  ["ch", 176],
  ["td", 177],
  ["kv", 178],
  ["lb", 179],
  ["dj", 180],
  ["bi", 181],
  ["sr", 182],
  ["il", 183],
  ["ml", 184],
  ["sn", 185],
  ["gn", 186],
  ["zw", 187],
  ["pl", 188],
  ["mk", 189],
  ["py", 190],
  ["by", 191],
  ["cz", 192],
  ["bf", 193],
  ["na", 194],
  ["ly", 195],
  ["tn", 196],
  ["bt", 197],
  ["md", 198],
  ["ss", 199],
  ["bw", 200],
  ["bs", 201],
  ["nz", 202],
  ["cu", 203],
  ["ec", 204],
  ["au", 205],
  ["ve", 206],
  ["sb", 207],
  ["mg", 208],
  ["is", 209],
  ["eg", 210],
  ["kg", 211],
  ["np", 212],
];
