import { FC, useEffect } from "react";

import SinglePatientInsurance from "./singleInsurance";
import { PatientInsuranceProps } from "./types";
import { InsuranceAttributesProps } from "src/pages/addCase/types";



const AddCasePatientInsurances: FC<PatientInsuranceProps> = ({ control, getValues, setValue, watch }): JSX.Element => {
  const patientInsurances = watch("insurances_attributes");
  const directPurchase = getValues("prescription_attributes.directPurchase");

  useEffect(() => {
    if (directPurchase) {
      if (patientInsurances && patientInsurances.length > 0 &&
        (patientInsurances[0].insurance_name || patientInsurances[0].policy)
      ) {
        setValue("insurances_attributes", patientInsurances);
      } else {
        setValue("insurances_attributes", []);
      }
    } else if (patientInsurances && patientInsurances.length === 0) {
      setValue("insurances_attributes", [
        {
          insurance_name: "",
          is_primary_insurance: true,
          policy: "",
          relation_to_patient: "self",
          bin: "",
          group_number: "",
          insurance_number: "",
          member_id: "",
          pcn: "",
          relation_firstname: "",
          relation_lastname: "",
          relation_middlename: "",
          rx_group: "",
          global_insurance_id: null,
          insurance_priority: 0,
        } as unknown as InsuranceAttributesProps
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directPurchase]);

  const removePatientInsurance = (index: number): void => {
    const insurancelisting: InsuranceAttributesProps[] | undefined = getValues("insurances_attributes");
    if (insurancelisting) {
      insurancelisting.splice(index, 1);
      if (index === 0 && insurancelisting.length > 0 && !insurancelisting[index].is_primary_insurance)
        insurancelisting[index].is_primary_insurance = true;

      console.log({ insurancelisting });
      setValue("insurances_attributes", insurancelisting);
    }
  };

  const addNewPatientInsurance = (): void => {
    const insurancelisting: InsuranceAttributesProps[] | undefined = getValues("insurances_attributes");
    if (insurancelisting) {
      const newInsurance = {
        insurance_name: "",
        policy: "",
        bin: "",
        group_number: "",
        insurance_number: "",
        member_id: "",
        pcn: "",
        relation_firstname: "",
        relation_lastname: "",
        relation_middlename: "",
        rx_group: "",
        insurance_priority: insurancelisting.length - 1,
        relation_to_patient: "self",
        global_insurance_id: null,
        is_primary_insurance: insurancelisting.length === 0, // only in case of first insurance this check will be true,
      } as InsuranceAttributesProps;
      insurancelisting.push(newInsurance);
      setValue("insurances_attributes", insurancelisting);
    }
  };

  return (
    <div className="card mb-3">
      <div className="card-header">
        <h4 className="m-0">Patient Insurances</h4>
      </div>
      <div className="card-body">
        {patientInsurances && patientInsurances.map((_d, index) => (
          <SinglePatientInsurance
            key={index}
            index={index}
            control={control}
            watch={watch}
            getValues={getValues}
            setValue={setValue}
            removeInsurance={removePatientInsurance}
          />
        ))}
        <div className="text-end">
          <button type="button" className="btn btn-success" onClick={addNewPatientInsurance}>
            <i className="bi bi-plus-lg"></i> Add Insurance
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddCasePatientInsurances;
