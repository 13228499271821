import { FC, useCallback, useContext, useEffect, useState } from "react";
// @ts-ignore
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

import { AuthContext } from "src/context/authContext";
import { shutDownAndEndChat, startChat } from "src/helpers/tawkto";


const TawkToMessengerComp: FC = (): JSX.Element => {
  const [isTawkMounted, setTawkMounting] = useState<boolean>(false);
  const { userData } = useContext(AuthContext);

  /**
   * Handling widget show & hide in tawk.to package while user auth changes
   */
  const onLoadTawk = useCallback(() => {
    if (userData) {
      startChat(userData);
    } else {
      shutDownAndEndChat();
    }
  }, [userData]);

  useEffect(() => {
    onLoadTawk();
  }, [onLoadTawk]);

  const eventListenerOnFocus = useCallback(() => {
    if (document.visibilityState === "visible") {
      if (userData === null && isTawkMounted === true) {
        setTawkMounting(false);
        shutDownAndEndChat();
      } else if (userData !== null && isTawkMounted === false) {
        setTawkMounting(true);
        startChat(userData);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTawkMounted, onLoadTawk, userData]);

  useEffect(() => {
    document.addEventListener("visibilitychange", eventListenerOnFocus);
    return () => document.removeEventListener("visibilitychange", eventListenerOnFocus);
  }, [eventListenerOnFocus]);

  return (
    <TawkMessengerReact
      propertyId="66bb94f5146b7af4a439f120"
      widgetId="1i56bro41"
    />
  );
};

export default TawkToMessengerComp;
