import { FC } from "react";

import { PatientAddressProps } from "./types";

const ShowPatientAddress: FC<PatientAddressProps> = ({ getValues }): JSX.Element => {
  const { patient_attributes: { address } } = getValues();

  return (
    <div className="card my-3">
      <div className="card-header">
        <h4 className="m-0">Patient Address</h4>
      </div>
      <div className="card-body">
        <div className="row p-2 ">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="row">
              <div className="col-4">
                <label className="form-label">Street:</label>
              </div>
              <div className="col-8">
                <p>{address && address.street ? address.street : 'N/A'}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="row">
              <div className="col-4">
                <label className="form-label">Suite:</label>
              </div>
              <div className="col-8">
                <p>{address && address.suite ? address.suite : 'N/A'}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row p-2">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="row">
              <div className="col-4">
                <label className="form-label">City:</label>
              </div>
              <div className="col-8">
                <p>{address && address.city ? address.city : 'N/A'}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="row">
              <div className="col-4">
                <label className="form-label">State:</label>
              </div>
              <div className="col-8">
                <p>{address && address.state ? address.state : 'N/A'}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row p-2">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="row">
              <div className="col-4">
                <label className="form-label">Zip:</label>
              </div>
              <div className="col-8">
                <p>{address && address.zip ? address.zip : 'N/A'}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="row">
              <div className="col-4">
                <label className="form-label">Type:</label>
              </div>
              <div className="col-8">
                <p>{address && address.address_name ? address.address_name : 'N/A'}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowPatientAddress;
