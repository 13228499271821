import { FC } from "react";
import ShowPatientInformation from "../addCase/showPatientInformation";
import ShowPatientAddress from "../addCase/showPatientAddress";
import ShowPrescriberInformation from "../addCase/showPrescriberInformation";
import ShowInsuranceInformation from "../addCase/showInsuranceInformation";
import { CaseStepThreeProps } from "./types";

const CaseStepThree: FC<CaseStepThreeProps> = ({ getValues }): JSX.Element => {
  const { insurances_attributes } = getValues();

  return (
    <div className="">
      <ShowPatientInformation getValues={getValues} />

      <ShowPatientAddress getValues={getValues} />

      <ShowPrescriberInformation getValues={getValues} />

      {insurances_attributes ? insurances_attributes.map((item, index) => (
        <ShowInsuranceInformation key={index} id={index+1} insurance={item} />
      )) : null}

      <div className="alert alert-light mt-3 rounded-1" role="alert">
        HARMOKNEE Reimbursement Solutions assists health care professionals in the determination of
        whether treatment could be covered by the applicable third-party payer based
        on coverage guidelines provided by the payer and patient information provided
        by the health care provider. Third-party reimbursement is affected by many factors.
        Therefore, HARMOKNEE Reimbursement Solutions makes no representation or guarantee that full or partial
        insurance reimbursement or any other payment will be available.
      </div>

      <div className="alert alert-warning rounded-1" role="alert">
        By submitting this form, I certify 1) I am duly licensed and authorized under
        applicable law to prescribe, receive and dispense the medication requested in this application
        to the patient listed above (the 'Patient'); 2) The information provided above is complete and
        accurate; 3) I authorize HARMOKNEE Reimbursement Solutions to forward the above prescription information to the
        appropriate pharmacy in order to dispense 'GenVisc850' and 'TriVisc' to the above patient's provider; 4) I understand
        that state law may require the pharmacy to contact me to confirm the prescription information before
        dispensing. 5) By signing this form, I represent to HARMOKNEE Reimbursement Solutions that I have obtained all necessary
        Federal and state authorizations and consents from my patient to allow me to release health information to
        HARMOKNEE Reimbursement Solutions and its contracted third parties. Signature on this form also provides consent to contact
        this patient's insurance provider for this prescription on the prescriber's behalf.
      </div>
    </div>
  );
};

export default CaseStepThree;
