import { FC, useContext, useEffect, useRef, useState } from "react";

import ContactAndSafetyInfo from "../../components/contactAndSafetyInfo";
import FooterText from "../../components/footerText";

import { TextAlignProps } from "../../components/contactAndSafetyInfo/types";
import { ToastTypes } from "src/types";
import { APIEndpoints } from "src/types/apiTypes";
import { ToastContext } from "src/context/toastContext";
import useFetch from "src/hooks/useFetch";
import { DocumentAPIError, DocumentAPIResponse } from "./types";


const HubUploadPEF: FC = (): JSX.Element => {
 
  const ref = useRef<HTMLInputElement>(null);
  const [uploadFile, changeUploadFile] = useState<FileList | null>(null);
  const { showToast } = useContext(ToastContext);
  const { error: uploadPEFFileError, data: uploadPEFFileResponse, mutate: uploadPEFDocument } =
    useFetch<DocumentAPIResponse>(APIEndpoints.UPLOAD_PEF);

  useEffect(() => {
    if (uploadPEFFileError) {
      changeUploadFile(null);
      reset()
      const {error } = uploadPEFFileError as unknown as DocumentAPIError; 
      showToast([error  || `File Not Uploaded.Please try again`],ToastTypes.ERROR)

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadPEFFileError]);

  useEffect(() => {
    if (uploadPEFFileResponse) {
      changeUploadFile(null);
      reset();
      const { message } = uploadPEFFileResponse;
      showToast([message], ToastTypes.SUCCESS);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadPEFFileResponse]);

  const uploadForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (uploadFile) {
      if (uploadFile[0].type === 'application/pdf') {
        const formData = new FormData();
        formData.append('fax_document', uploadFile[0]);
        uploadPEFDocument(formData);
      } else {
        showToast([`Please Select PDF format only.`], ToastTypes.WARNING);
      }
    } else {
      showToast([`Please Select PDF File first`], ToastTypes.WARNING);
    }
  };

  const reset = () => {
    if(ref && ref.current){
      ref.current.value = ""
    }
  };

  const clearDataAndForm = () => {
    changeUploadFile(null);
    reset();
  };

  return (
    <div className="guest-page-bg">
      <div className="container-fluid row justify-content-center pt-3 pb-4">
        <div className="col-12 col-sm-10 col-md-8">
          <ContactAndSafetyInfo alignText={TextAlignProps.TEXT_END} textColor="white" />

          <div className="card">
            <div className="card-header">
              <h4 className="text-primary p-2 mb-0">Upload PEF</h4>
            </div>

            <div className="card-body">
              <form onSubmit={uploadForm}>
                <p className="form-text">Please select and upload a completed PEF Form to have our team transcribe it and begin the benefits investigation process.</p>

                <div className="input-group mb-3">
                  <input type="file" className="form-control" id="fileUpload" onChange={(e) => changeUploadFile(e.target.files)} ref={ref} />
                  {uploadFile ? (
                    <button className="btn btn-outline-danger" disabled={!uploadFile} type="button" id="fileUpload"
                      onClick={clearDataAndForm}
                    >
                      Clear
                    </button>
                  ) : null}
                  <button className="btn btn-primary" disabled={!uploadFile} type="submit" id="fileUpload">Upload</button>
                </div>
              </form>
            </div>

          </div>
        </div>

        <FooterText footerType="white" />
      </div>
    </div>
  );
};

export default HubUploadPEF;
