import { UseFormResetField, UseFormSetValue } from "react-hook-form";
import { NewEnrollmentType } from "src/pages/newEnrollment/types";
import { NPILookupResponse } from "src/types";


export const populateEnrollmentForm = (setValue: UseFormSetValue<NewEnrollmentType>, npiLookupData: NPILookupResponse, npi: string) => {
  populatePracticeEnrollmentForm(setValue, npiLookupData, npi);
  populatePrescriberEnrollmentForm(setValue, npiLookupData, npi, 0);
};

export const populatePracticeEnrollmentForm = (setValue: UseFormSetValue<NewEnrollmentType>, npiLookupData: NPILookupResponse, npi: string) => {
  if (npiLookupData.practice_data) {
    //:- setting values for practice
    setValue(`enrollment_practices_attributes.0.npi`, npi || "");
    setValue(`enrollment_practices_attributes.0.practice_name`, npiLookupData.practice_data.basic.practice_name || "");
    setValue(`enrollment_practices_attributes.0.street`, npiLookupData.practice_data.address_data.street || npiLookupData.practice_data.address_data.address_1);
    setValue(`enrollment_practices_attributes.0.suite`, npiLookupData.practice_data.address_data.address_2 || "");
    setValue(`enrollment_practices_attributes.0.city`, npiLookupData.practice_data.address_data.city);
    setValue(`enrollment_practices_attributes.0.zip`, npiLookupData.practice_data.address_data.postal_code);
    setValue(`enrollment_practices_attributes.0.state`, npiLookupData.practice_data.address_data.state);
    setValue(
      `enrollment_practices_attributes.0.phone_number`, 
      npiLookupData.practice_data.address_data.telephone_number ? npiLookupData.practice_data.address_data.telephone_number.toString().replace(/-/g, "") : ""
    );
    setValue(
      `enrollment_practices_attributes.0.fax_number`, 
      npiLookupData.practice_data.address_data.fax_number ? npiLookupData.practice_data.address_data.fax_number.toString().replace(/-/g, "") : ""
    );
  }
};

export const populatePrescriberEnrollmentForm = (setValue: UseFormSetValue<NewEnrollmentType>, npiLookupData: NPILookupResponse, npi: string, prescriberIndex: number) => {
  //:- setting values for prescriber
  if (npiLookupData.doctor_data) {
    setValue(`enrollment_doctors_attributes.${prescriberIndex}.npi`, npi || '');
    setValue(`enrollment_doctors_attributes.${prescriberIndex}.firstname`, npiLookupData.doctor_data.basic.first_name || "");
    setValue(`enrollment_doctors_attributes.${prescriberIndex}.middlename`, npiLookupData.doctor_data.basic.middle_name || "");
    setValue(`enrollment_doctors_attributes.${prescriberIndex}.lastname`, npiLookupData.doctor_data.basic.last_name || "");
    setValue(`enrollment_doctors_attributes.${prescriberIndex}.ref_taxonomy_id`, npiLookupData.doctor_data.taxonomy_data.code);
    setValue(
      `enrollment_doctors_attributes.${prescriberIndex}.phone_number`,
      npiLookupData.doctor_data.address_data.telephone_number ? npiLookupData.doctor_data.address_data.telephone_number.toString().replace(/-/g, "") : ""
    );
    setValue(
      `enrollment_doctors_attributes.${prescriberIndex}.fax_number`,
      npiLookupData.doctor_data.address_data.fax_number ? npiLookupData.doctor_data.address_data.fax_number.toString().replace(/-/g, "") : ""
    );
    setValue(`enrollment_doctors_attributes.${prescriberIndex}.tax_id`, npiLookupData.doctor_data.basic.tax_id || "");
  }
};

export const resetPracticeFormFields = (resetField: UseFormResetField<NewEnrollmentType>) => {
  resetField(`enrollment_practices_attributes.0.practice_name`);
  resetField(`enrollment_practices_attributes.0.street`);
  resetField(`enrollment_practices_attributes.0.suite`);
  resetField(`enrollment_practices_attributes.0.city`);
  resetField(`enrollment_practices_attributes.0.zip`);
  resetField(`enrollment_practices_attributes.0.state`);
  resetField(`enrollment_practices_attributes.0.phone_number`);
  resetField(`enrollment_practices_attributes.0.fax_number`);
};

export const resetPrescriberFormFields = (resetField: UseFormResetField<NewEnrollmentType>, index: number) => {
  resetField(`enrollment_doctors_attributes.${index}.firstname`);
  resetField(`enrollment_doctors_attributes.${index}.middlename`);
  resetField(`enrollment_doctors_attributes.${index}.lastname`);
  resetField(`enrollment_doctors_attributes.${index}.ref_taxonomy_id`);
  resetField(`enrollment_doctors_attributes.${index}.phone_number`);
  resetField(`enrollment_doctors_attributes.${index}.fax_number`);
  resetField(`enrollment_doctors_attributes.${index}.tax_id`);
};
