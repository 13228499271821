// import { FC, useEffect, useState } from "react"
// import { CaseDetails } from "../caseDetail/types";

// const Stepper: FC<{ caseDetails: CaseDetails }> = ({ caseDetails }): JSX.Element => {
//   const [step, changeStep] = useState<number>(1);

//   const onStepProgressionClick = (localStep: number) => {
//     changeStep(localStep);
//   };

//   useEffect(() => console.log({ caseDetails }), [caseDetails]);

//   return (
//     <div className="card m-2 rounded-0">
//       <ul className="nav nav-progress row mx-0">
//         <li className={`nav-item col-4 px-0 ${step === 1 ? 'opacity-100' : 'opacity-50'}`}>
//           <a className="nav-link me-2 border-bottom border-2 pb-0 border-primary" href="#step-1" onClick={() => onStepProgressionClick(1)}>
//             <span className="me-5 fs-4 fw-bold">1</span>
//             <span className="fs-5">Order</span>
//           </a>
//         </li>
//         <li className={`nav-item col-4 px-0 ${step === 2 ? 'opacity-100' : 'opacity-50'}`}>
//           <a className="nav-link me-2 border-bottom border-2 pb-0 border-primary" href="#step-2" onClick={() => onStepProgressionClick(2)} >
//             <span className="me-5 fs-4 fw-bold">2</span>
//             <span className="fs-5">Mixing</span>
//           </a>
//         </li>
//         <li className={`nav-item col-4 px-0 ${step === 3 ? 'opacity-100' : 'opacity-50'}`}>
//           <a className="nav-link border-bottom pb-0 border-primary border-2" href="#step-3" onClick={() => onStepProgressionClick(3)}>
//             <span className="me-5 fs-4 fw-bold">3</span>
//             <span className="fs-5">Treatment</span>
//           </a>
//         </li>
//       </ul>

//       <div className="height-animation">
//         <div id="step-1">
//           {step === 1 && 
//             <div className="m-3">
//               <p className="fs-2">{caseDetails.order.order_status}</p>
//               <ul>
//                 {caseDetails.order.info.key_1.length > 0 ? <li>{caseDetails.order.info.key_1}</li> : null}
//                 {caseDetails.order.info.key_2.length > 0 ? <li>{caseDetails.order.info.key_2}</li> : null}
//                 {caseDetails.order.info.key_3.length > 0 ? 
//                   <li><div dangerouslySetInnerHTML={{ __html: caseDetails.order.info.key_3 }}></div></li> 
//                   : null
//                 }
//               </ul>
//             </div>
//           }
//         </div>

//         <div id="step-2">
//           {step === 2 && 
//             <div className="m-3">
//               <p className="fs-2">{caseDetails.mixing.mixing_status}</p>
//               <ul>
//                 {caseDetails.mixing.info.key_1.length > 0 ? <li>{caseDetails.mixing.info.key_1}</li> : null}
//                 {caseDetails.mixing.info.key_2.length > 0 ? <li>{caseDetails.mixing.info.key_2}</li> : null}
//               </ul>
//             </div>
//           }
//         </div>

//         <div id="step-3">
//           {step === 3 && 
//             <div className="m-3">
//               <p className="fs-2">{caseDetails.treatment.treatment_status}</p>
//               <ul>
//                 {caseDetails.treatment.info.key_1.length > 0 ? <li>{caseDetails.treatment.info.key_1}</li> : null}
//                 {caseDetails.treatment.info.key_2.length > 0 ? <li>{caseDetails.treatment.info.key_2}</li> : null}
//                 {caseDetails.treatment.info.key_3.length > 0 ? <li>{caseDetails.treatment.info.key_3}</li> : null}
//                 {caseDetails.treatment.info.key_4.length > 0 ? <li>{caseDetails.treatment.info.key_4}</li> : null}
//               </ul>
//             </div>
//           }
//         </div>
//       </div>

//       <div className="text-end m-2">
//         {step !== 1 &&
//           <button
//             type="button"
//             key="previousStepButton"
//             className="btn btn-primary m-1 rounded-1 opacity-75"
//             onClick={() => onStepProgressionClick(step - 1)}
//           >
//             Previous
//           </button>
//         }
//         <button
//           type="button"
//           key="nextStepButton"
//           style={{ maxWidth: 80 }}
//           disabled={step === 3}
//           className={`btn btn-primary m-1 rounded-1 ${step === 3 ? 'opacity-50' : 'opacity-75'}`}
//           onClick={() => onStepProgressionClick(step + 1)}
//         >
//           Next
//         </button>
//       </div>
//     </div>
//   );
// };

// export default Stepper;


import { FC, useState } from "react"

const Stepper: FC = (): JSX.Element => {
  const [step, changeStep] = useState<number>(1);

  const onStepProgressionClick = (localStep: number) => {
    changeStep(localStep);
  };
  return (
    <div >
      <div className="card mt-2 m-1 rounded-0 ">
        <div id="smartwizard"></div>
        <ul className="nav nav-progress justify-content-between row mx-0">
          <li className="nav-item px-0 col-4 ">
            <a style={{ fontSize: 10 }} className={`nav-link justify-content-around me-2 border-bottom border-primary  ${step === 1 ? 'active' : ''}`} href="#step-1" onClick={() => onStepProgressionClick(1)}>
              <span className="num mx-3 fw-bold ">1</span>Order</a>
          </li>
          <li className="nav-item px-0 col-4 ">
            <a style={{ fontSize: 10 }} className={`nav-link justify-content-around me-2 border-bottom border-primary ${step === 2 ? 'active' : ''}`} href="#step-2" onClick={() => onStepProgressionClick(2)} >
              <span className="num mx-3 fw-bold ">2</span>Mixing</a>
          </li>
          <li className="nav-item px-0 col-4 ">
            <a style={{ fontSize: 10 }} className={`nav-link justify-content-around me-0 border-bottom border-primary ${step >= 3 ? 'active' : ''}`} href="#step-3" onClick={() => onStepProgressionClick(3)}>
              <span className="num mx-3 fw-bold ">3</span>Treatment</a>
          </li>
        </ul>
        <div id="step-1" >
        {step === 1 && <div className="mx-1 my-1">Order Status</div>}
      </div>
      <div id="step-2" >
        {step === 2 && <div className="mx-1 my-1">Mixing Status</div>}
      </div>
      <div id="step-3" >
        {step === 3 && <div className="mx-1 my-1">Instillation Status</div>}
      </div>
      <div className="btn-xs mt-2 me-2 my-1 mx-1 text-end">
        {step !== 1 &&
          <button
            type="button"
            key="previousStepButton"
            className='btn btn-primary  ms-1 me-1 btn-xs '
            onClick={() => onStepProgressionClick(step - 1)}
          >
            Previous
          </button>}
        {step <= 2 && <button
          type="button"
          key="nextStepButton"
          style={{ maxWidth: 80, backgroundColor: 'primary' }}
          className="btn btn-primary btn-xs"
          onClick={() => onStepProgressionClick(step + 1)}
        >
          Next
        </button>}
      </div>
      </div>
    </div>
  );
};

export default Stepper;