import axios from "axios";

import { apiConstants } from "../constants/api";

const instance = axios.create({
  baseURL: apiConstants.baseURL,
  headers: apiConstants.headers,
  timeout: 80000,
});

instance.interceptors.request.use((config) => {
  if (!config.url)
    return config;

  const currentUrl = new URL(config.url, config.baseURL);
  // parse pathName to implement variables
  Object.entries(config.urlParams || {})
    .forEach(([ key, value ]) => {
      currentUrl.pathname = currentUrl.pathname.replace(`:${key}`, encodeURIComponent(value));
    });

  const authPart = currentUrl.username && currentUrl.password ? `${currentUrl.username}:${currentUrl.password}` : '';

  return {
    ...config,
    baseURL: `${currentUrl.protocol}//${authPart}${currentUrl.host}`,
    url: currentUrl.pathname,
  };
});

export default instance;
