export enum TextAlignProps {
  TEXT_START = 'text-start',
  TEXT_CENTER = 'text-center',
  TEXT_END = 'text-end',
}

export type ContactAndSafetyInfoProps = {
  alignText: TextAlignProps;
  textColor: 'white' | 'black' | 'primary';
  isLogin?: boolean;
};
