import { FC,
   //useContext
   } from "react";
// import { useNavigate } from "react-router-dom";

import ContactAndSafetyInfo from "../contactAndSafetyInfo";
import { TextAlignProps } from "../contactAndSafetyInfo/types";
// import { AuthContext } from "src/context/authContext";
// import { HCPUserSubHeaderProps } from "src/types/users";

const AuthSubHeader: FC = (): JSX.Element => {
  // const navigate = useNavigate();
  // const { sub_header } = useContext(AuthContext);

  return (

    <div className="row align-items-center">
      <div className="col-12 col-md-6">
        {/* <p className="mb-0"><span className="fw-bold">REP:</span>{sub_header ? (sub_header as HCPUserSubHeaderProps).Rep : ''}</p>
        <p><span className="fw-normal">Email:</span>{sub_header ? (sub_header as HCPUserSubHeaderProps).Email : ''}</p> */}
      </div>
      <div className="col-12 col-md-6">
        <ContactAndSafetyInfo textColor="black" alignText={TextAlignProps.TEXT_END} />
      </div>
    </div>

  );
};

export default AuthSubHeader;
