import { FC,
   //useContext
   } from "react";

// import { FAX_NUMBER, PHONE_NUMBER } from "../../constants/contact";
import { ContactAndSafetyInfoProps } from "./types";
// import { AuthContext } from "src/context/authContext";
// import { FooterContext } from "src/context/footerContext";

const ContactAndSafetyInfo: FC<ContactAndSafetyInfoProps> = ({ alignText, textColor, isLogin = false }): JSX.Element => {
  // const { isUserAuthorized } = useContext(AuthContext);
  // const {changeAccordianVisibility} =  useContext(FooterContext)

  return (
    <>
      {/* <div className={`${alignText} my-3`}>
        <button
          type="button"
          onClick={changeAccordianVisibility}
          className={`btn btn-link ${!isUserAuthorized ? `text-${textColor}` : ''} ${!isUserAuthorized ? `link-${textColor}` : ''} link-offset-0 link-offset-0-hover link-underline-${isUserAuthorized ? 'primary' : textColor === 'black' ? 'dark' : 'light'} ${isLogin ? 'fw-bold' : ''} link-underline-opacity-0 link-underline-opacity-100-hover p-0 align-baseline`}
        >
          Important Safety Information
        </button>
        <span className={`text-${textColor} ${isLogin ? 'fw-bold' : ''}`}> | </span>
        <a
          href="/assets/jelmyto_prescribing_information.pdf"
          target="_blank"
          className={`${!isUserAuthorized ? `text-${textColor}` : ''} ${!isUserAuthorized ? `link-${textColor}` : ''} ${isLogin ? 'fw-bold' : ''} link-offset-0 link-offset-0-hover link-underline-${isUserAuthorized ? 'primary' : textColor === 'black' ? 'dark' : 'light'} link-underline-opacity-0 link-underline-opacity-75-hover`}
        >
          Full Prescribing Information
        </a>
      </div> */}

      {/* <div className={`${alignText} mb-3`}>
        <p className={`text-${textColor}`}>
          Phone: {PHONE_NUMBER} | Fax: {FAX_NUMBER}
        </p>
      </div> */}
    </>
  );
};

export default ContactAndSafetyInfo;
