import { FC } from "react";

import { PrescriberInfoProps } from "./types";

const ShowPrescriberInformation: FC<PrescriberInfoProps> = ({ getValues }): JSX.Element => {
  const { prescription_attributes } = getValues();
  return (
    <div className="card my-3">
      <div className="card-header">
        <h4 className="m-0">Prescriber Information</h4>
      </div>
      <div className="card-body">
        <div className="row p-2">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="row">
              <div className="col-4">
                <label className="form-label">Drug:</label>
              </div>
              <div className="col-8">
                {getValues()?.prescription_attributes?.drug_name === "TriVisc" ?  "TriVisc" : "GenVisc" }
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="row">
              <div className="col-4">
                <label className="form-label">Benefits being requested:</label>
              </div>
              <div className="col-8">
                <p>Medical</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row p-2">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="row">
              <div className="col-4">
                <label className="form-label">Diagnosis Code (ICD):</label>
              </div>
              <div className="col-8">
                <p>{prescription_attributes && prescription_attributes.diagnosis_code ? prescription_attributes.diagnosis_code.join(", ") : 'N/A'}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12"></div>
        </div>
        <div className="row p-2">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="row">
              <div className="col-4">
                <label className="form-label">Written date:</label>
              </div>
              <div className="col-8">
                <p>{prescription_attributes && prescription_attributes.written_date ? prescription_attributes.written_date : 'N/A'}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="row">
              <div className="col-4">
                <label className="form-label">Notes:</label>
              </div>
              <div className="col-8">
                <p>{prescription_attributes && prescription_attributes.note ? prescription_attributes.note : 'N/A'}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row p-2">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="row">
              <div className="col-4">
                <label className="form-label">Directions:</label>
              </div>
              <div className="col-8">
                <p>{prescription_attributes && prescription_attributes.direction ? prescription_attributes.direction : 'N/A'}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowPrescriberInformation;
