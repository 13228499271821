export type TryCatchError = {
  message?: string;
};

export enum ToastTypes {
  SUCCESS = 'success',
  INFO = 'primary',
  ERROR = 'danger',
  WARNING = 'warning'
};

export enum HeaderElementType {
  BUTTON = 'button',
  LINK = 'link',
  ROUTE = 'route',
  DRPODOWN = 'dropdown'
};

export type HeaderElement = {
  title: string | JSX.Element;
  redirectLink: string;
  actionType: HeaderElementType;
  dropdown?: Omit<HeaderElement, 'actionType'>[];
};

export type NPIBasic = {
  first_name: string;
  last_name: string;
  middle_name: string;
  credential: string;
  sole_proprietor: string;
  gender: string;
  enumeration_date: string;
  last_updated: string;
  certification_date: string;
  status: string;
  tax_id: string | null;
};

export type NPIAddress = {
  country_code: string;
  country_name: string;
  address_purpose: string;
  address_type: string;
  address_1: string;
  address_2?: string;
  city: string;
  state: string;
  postal_code: string;
  street: string;
  telephone_number: string;
  fax_number: string;
};

export type NPITaxonomy = {
  code: string;
  taxonomy_group: string;
  desc: string;
  state: string;
  license: string;
  primary: boolean;
};

export type NPIPracticeType = {
  basic: {
    practice_name: string | null;
  }
  address_data: NPIAddress;
};

export type NPIDoctorType = {
  basic: NPIBasic;
  address_data: NPIAddress;
  taxonomy_data: NPITaxonomy;
};

export type NPILookupResponse = {
  practice_data: NPIPracticeType;
  doctor_data: NPIDoctorType;
};
