import { PatientTitleProps } from "src/types/case";

export const DEFAULT_DRUG_ID = 119717;

export const PATIENT_TITLE: readonly PatientTitleProps[] = [
  {
    label: 'Mr.',
    value: 'mr',
  },
  {
    label: 'Ms.',
    value: 'ms',
  },
  {
    label: 'Mrs.',
    value: 'mrs',
  },
  {
    label: 'Miss',
    value: 'miss',
  },
];

export const PATIENT_GENDER: PatientTitleProps[] = [
  {
    label: 'Male',
    value: 'M',
  },
  {
    label: 'Female',
    value: 'F',
  },
];

export const PATIENT_TIME_TO_CONTACT: PatientTitleProps[] = [
  {
    label: 'Morning',
    value: 'morning',
  },
  {
    label: 'Afternoon',
    value: 'afternoon',
  },
  {
    label: 'Evening',
    value: 'evening',
  },
];

export const PATIENT_PHONE_TYPES: PatientTitleProps[] = [
  {
    label: 'Cell',
    value: 'cell',
  },
  {
    label: 'Home',
    value: 'home',
  },
  {
    label: 'Work',
    value: 'work',
  },
];

export const PATIENT_ADDR_TYPES: PatientTitleProps[] = [
  {
    label: 'Home',
    value: 'home',
  },
  {
    label: 'Work',
    value: 'work',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const PATIENT_INSURANCE_TYPE: PatientTitleProps[] = [
  {
    label: 'Self',
    value: 'self',
  },
  {
    label: 'Spouse',
    value: 'spouse',
  },
  {
    label: 'Guardian',
    value: 'guardian',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const PATIENT_SITE_TYPE: PatientTitleProps[] = [
  {
    label: 'Physician Office',
    value: 'office',
  },
  {
    label: 'Ambulatory Surgical Center',
    value: 'asc',
  },
  {
    label: 'Hospital Outpatient',
    value: 'hospital',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const PATIENT_PHARMACY_INSTRUCTIONS: PatientTitleProps[] = [
  {
    label: 'Initial Course - 5 Refills',
    value: 'standard'
  },
  {
    label:'Maintenance Course - 10 Refills',
    value: 'maintenance'
  },
  {
    label: 'Initial Course Bilateral - 5 Refills',
    value: 'bilateral'
  },
  {
    label: 'Bilateral Maintenance Course - 10 Refills',
    value: 'bilateral_maintenance',
  }
];

export const CASE_STATUS_LISTING: { label: string; value: string | boolean; }[] = [
  {
    label: 'All Cases',
    value: '',
  },
  {
    label: 'Active Cases',
    value: true,
  },
  {
    label: 'Closed Cases',
    value: false,
  },
  {
    label: 'Direct Purchase',
    value: "pdp",
  },
  {
    label: 'Patient Assistance',
    value: "pap",
  },
  {
    label: 'Benefit Investigation',
    value: "medrx",
  },
];

export const TREATMENT_STATUS_LISTING: { label: string; value: string | boolean; }[] = [
  {
    label: 'All Treatments',
    value: '',
  },
  {
    label: 'Active Treatments',
    value: true,
  },
  {
    label: 'Closed Treatments',
    value: false,
  },
];

export const ORDER_STATUS_LISTING: { label: string; value: string | boolean; }[] = [
  {
    label: 'All Orders',
    value: '',
  },
  {
    label: 'Active Orders',
    value: true,
  },
  {
    label: 'Closed Orders',
    value: false,
  },
];

export const CASE_HEADER_LISTING = [
  {
    value: 'id',
    label: 'ID',
  },
  {
    value: 'created_at',
    label: 'Submitted',
  },
  {
    value: 'patient_firstlastname',
    label: 'Patient',
  },
  {
    value: 'doctor_firstlastname',
    label: 'Physican',
  },
  {
    value: '',
    label: 'Case Type'
  },
  {
    value: 'status_name',
    label: 'Status',
  },
  {
    value: '',
    label: 'Next Steps',
  },
  {
    value: '',
    label: 'Patient Actions',
  },
];

export const TREATMENT_HEADER_LISTING = [
  {
    value: 'case_id',
    label: 'Case ID',
  },
  {
    value: '',
    label: 'Instillation',
  },
  {
    value: '',
    label: 'Ordered'
  },
  {
    value: '',
    label: 'Delivery ETA',
  },
  {
    value: '',
    label: 'Patient',
  },
  {
    value: '',
    label: 'Provider',
  },
  {
    value: 'status_name',
    label: 'Treatment Status',
  },
  {
    value: '',
    label: 'Next Steps',
  },
];

export const ORDER_HEADER_LISTING = [
  {
    value: 'id',
    label: 'ID',
  },
  {
    value: 'case_id',
    label: 'Case ID',
  },
  {
    value: 'po_number',
    label: 'Customer PO',
  },
  {
    value: 'user_name',
    label: 'Ordered By',
  },
  {
    value: 'created_at',
    label: 'Ordered Date',
  },
  {
    value: 'completed_at',
    label: 'Completed Date',
  },
  {
    value: '',
    label: 'Status',
  },
];

export const STATE_TREATMENT = [
  {
    value: 'Cost',
    label: 'Cost',
  }, {
    value: 'Off label',
    label: 'Off label',
  }, {
    value: 'PA denied',
    label: 'PA denied',
  }, {
    value: 'Patient refused',
    label: 'Patient refused',
  }, {
    value: 'Step-edits not met',
    label: 'Step-edits not met',
  }, {
    value: 'Unable to contact patient',
    label: 'Unable to contact patient',
  }, {
    value: 'Other',
    label: 'Other',
  }
];

export const GENVISC_QUANTITY = [
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 6,
    label: "6",
  },
  {
    value: 8,
    label: "8",
  },
  {
    value: 10,
    label: "10",
  },
];

export const TRIVISC_QUANTITY = [
  {
    value: 3,
    label: "3",
  },
  {
    value: 6,
    label: "6",
  }
];
