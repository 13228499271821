/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import StatsReports from "../statsReports";
import { Controller, useForm } from "react-hook-form";
import { StatisticsPropsType, StatisticsSearchProps } from "./types";
import HubInputField from "src/components/inputType";
import Select, { GroupBase, OptionsOrGroups } from "react-select";

const CaseFilter: FC<StatisticsPropsType> = ({
  updateQueryParams,
  defaultQueryParams,
}): JSX.Element => {
  const { control, handleSubmit, getValues } = useForm<StatisticsSearchProps>({
    mode: "all",
    defaultValues: defaultQueryParams,
  });
  const timeData = [
    { value: "day", label: "Day" },
    { value: "week", label: "Week" },
    { value: "month", label: "Month" },
    { value: "quarter", label: "Quarter" },
  ];
  // const periodData = [
  //   { value: 'period', label: 'vs Previous Period' },
  //   { value: 'year', label: 'vs Previous Year' },
  // ]
  const filterCaseData = [
    { value: "all", label: "All Cases" },
    { value: "ecrall", label: "ECR Cases" },
    { value: "ncrall", label: "Non-ECR Cases" },
    { value: "parecrall", label: "PA Pending" },
    { value: "ncrparall", label: "Missing Docs" },
  ];

  const searchForStats = (values: StatisticsSearchProps) => {
    const updatedValues = {
      ...values,
      period: values.period
        ? typeof values.period === "string"
          ? values.period
          : (values.period as unknown as { value: string; label: string }).value
        : "",
      // period:
      //   values.period ?
      //     (typeof values.period === "string" ? values.period : (values.period as unknown as { value: string; label: string; }).value)
      //     : "",
      filter: values.filter
        ? typeof values.filter === "string"
          ? values.filter
          : (values.filter as unknown as { value: string; label: string }).value
        : "",
    };

    updateQueryParams(updatedValues, false);
  };

  return (
    <form onSubmit={handleSubmit(searchForStats)}>
      <div className="card mt-4">
        <div className="card-header">
          <h4 className="m-0">Statistics</h4>
        </div>
        <div className="card-body px-3 pt-3">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-10 col-lg-10">
              <div className="row g-2">
                <div className="col-12 col-sm-12 col-md-3">
                  <Controller
                    name="start_date"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <HubInputField
                        inputId="start_date"
                        isRowBased={false}
                        isRequired={false}
                        field={field}
                        inputType="date"
                      />
                    )}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-3">
                  <Controller
                    name="end_date"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <HubInputField
                        inputId="end_date"
                        isRowBased={false}
                        isRequired={false}
                        field={field}
                        inputType="date"
                      />
                    )}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-3">
                  <Controller
                    name="period"
                    control={control}
                    defaultValue={timeData[0].value}
                    render={({ field }) => (
                      <Select
                        isClearable={true}
                        options={
                          (timeData && timeData.length > 0
                            ? timeData.map((item) => ({
                                value: item.value,
                                label: item.label,
                              }))
                            : []) as unknown as OptionsOrGroups<
                            string,
                            GroupBase<string>
                          >
                        }
                        id="time"
                        {...field}
                      />
                    )}
                  />
                </div>
                {/* <div className="col-12 col-sm-12 col-md-3">
                  <Controller
                      name="period"
                      control={control}
                      render={({field}) => (
                          <Select
                              isClearable={true}
                              options={(periodData && periodData.length > 0 ?
                                      periodData.map((item) => ({
                                        value: item.value,
                                        label: item.label
                                      }))
                                      : []
                              ) as unknown as OptionsOrGroups<string, GroupBase<string>>}
                              id="period"
                              {...field}
                              placeholder={'Compare...'}
                          />
                      )}
                  />
                </div> */}
                <div className="col-12 col-sm-12 col-md-3">
                  <Controller
                    name="filter"
                    control={control}
                    render={({ field }) => (
                      <Select
                        isClearable={true}
                        options={
                          (filterCaseData && filterCaseData.length > 0
                            ? filterCaseData.map((item) => ({
                                value: item.value,
                                label: item.label,
                              }))
                            : []) as unknown as OptionsOrGroups<
                            string,
                            GroupBase<string>
                          >
                        }
                        id="filter"
                        {...field}
                        placeholder={"Filter ..."}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-2 col-lg-2">
              <button type="submit" className="btn btn-primary w-100">
                <i className="bi bi-arrow-repeat text-white"></i> Refresh
              </button>
            </div>
          </div>
          <StatsReports getValues={getValues} />
        </div>
      </div>
    </form>
  );
};

export default CaseFilter;
