import * as yup from "yup";

import { EMAIL_REGEX } from "../constants";

export const forgotpasswordSchema = yup.object({
  email:  yup
  .string()
  .email(`Provided email should be a valid string`)
  .required('can\'t be blank')
  .test('EmailVerification', `Provided email should be a valid string`, (val) => (EMAIL_REGEX.test(val)), ),
});
