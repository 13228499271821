import {
  useEffect,
  useContext,
  useReducer,
  useMemo,
} from "react";
import { AxiosRequestConfig, AxiosHeaders } from "axios";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../../context/authContext";
import axios from "../../api/api_instance";

import { authAPIs } from "../../constants/api";
import { APIEndpoints, APITypes } from "../../types/apiTypes";
import { ToastTypes } from "../../types";
import packageInfo from "../../../package.json";

import { fetchReducer } from "./state";
import { ActionTypes, FetchHookStateType } from "./types";
import { handlingAPIError } from "src/helpers/fetch";
import { ToastContext } from "src/context/toastContext";

function useFetch<TResponseData, TQueryParams = {}, TPathParams = {}>(
  apiType: APIEndpoints,
  queryParams?: TQueryParams,
  pathParams?: TPathParams,
  shouldAPIBeCalledImmediately: boolean = true
) {
  const [state, dispatch] = useReducer(fetchReducer<TResponseData>(), {
    loading: false,
    apiData: authAPIs[apiType],
    shouldAPIBeCalled: null,
  } as unknown as FetchHookStateType<TResponseData>);

  const { shouldAPIBeCalled, loading, apiData, data, error } = state;
  const { authToken, isUserAuthorized, destroySession } =
    useContext(AuthContext);
  const { showToast } = useContext(ToastContext);
  const navigate = useNavigate();

  const versionChannel = useMemo(() => new BroadcastChannel("new_version"), []);



  
  /**
   * @CheckIfAPICallAtInstanceIsRequired
   * Will determine whether api can be called on initializing of useFetch hook
   */
  useEffect(() => {
    const shouldAPIbeCalled =
      (apiData.isPathParamsRequired && pathParams !== undefined) ||
      (apiData.isQueryParamsRequired && queryParams !== undefined);
    const shouldAPIBeCalledWithoutDependency =
      !apiData.isPathParamsRequired && !apiData.isQueryParamsRequired;

    let latestAPICallCheck = null;
    if (apiData.isAuthHeaderRequired)
      latestAPICallCheck =
        (shouldAPIbeCalled || shouldAPIBeCalledWithoutDependency) &&
        apiData.method === APITypes.GET &&
        isUserAuthorized &&
        shouldAPIBeCalledImmediately;
    else
      latestAPICallCheck =
        (shouldAPIbeCalled || shouldAPIBeCalledWithoutDependency) &&
        apiData.method === APITypes.GET &&
        shouldAPIBeCalledImmediately;

    dispatch({
      type: ActionTypes.SET_API_CALLING_CHECK,
      payload: latestAPICallCheck,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

 
  
  
  
  const toggleLoading = () => {
    dispatch({
      type: ActionTypes.SET_LOADING,
      payload: !loading,
    });
  };

  const fetchData = async <TBodyType, TPathParams, TQueryParams>(
    body?: TBodyType,
    pathFromData?: TPathParams,
    queryFromData?: TQueryParams
  ) => {
    toggleLoading();

    let apiVersion = "";
    try {
      const { url, method } = apiData;
      const options: AxiosRequestConfig = {
        method,
        url,
        headers: new AxiosHeaders(),
      };

      if (apiData.isPathParamsRequired) {
        if (pathFromData) options.urlParams = pathFromData;
        else if (pathParams) options.urlParams = pathParams;
        else
          throw new Error(
            "Specific values are required for fetching relevant data"
          );
      }

      if (apiData.isBodyRequired) {
        if (body) options.data = body;
        else
          throw new Error(
            "Specific values are required for fetching relevant data"
          );
      }

      if (apiData.isQueryParamsRequired) {
        if (queryFromData) options.params = queryFromData;
        else if (queryParams) options.params = queryParams;
        else
          throw new Error(
            "Specific values are required for fetching relevant data"
          );
      }

      //:- Authorization header has been added
      if (
        apiData.isAuthHeaderRequired &&
        options.headers instanceof AxiosHeaders
      )
        options.headers.setAuthorization(authToken);

      if (apiData.isFileUpload)
        options.headers = {
          ...options.headers,
          "Content-Type": "multipart/form-data",
        };

      //:- axios call has been generated
      const response = await axios(options);

      const resHeaders =
        response.headers instanceof AxiosHeaders ? response.headers : null;

      const { data } = response;
      if (resHeaders && resHeaders.has("Authorization")) {
        data.token = resHeaders.get("Authorization");
        // if (apiType !== APIEndpoints.USER_SIGNIN) {
        //   updateUserToken((resHeaders.get("Authorization") as string) || "");
        // }
      }
      if (resHeaders && resHeaders.has("App_version")) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        apiVersion = (resHeaders.get("App_version") as unknown as string) || "";
        console.log({ reactAppVersion: packageInfo.version, apiVersion });
        if (apiVersion && packageInfo.version && apiVersion !== packageInfo.version) {
          //:- getting new version and reloading the screen
          window.alert(
            "A new version is released. Please click on OK button to get the latest version."
          );
          //const response = await updateApiVersion();
          //console.log(" response ....." , response)
          // localStorage.setItem("API_VERSION", apiVersion);
          versionChannel.postMessage("new_version");
          window.location.reload();
        }
      }

      dispatch({ type: ActionTypes.SET_DATA, payload: data });
    } catch (error: unknown) {
    
      const { isAuthIssue, error: errorMessage } = handlingAPIError(error);
      let finalErrorMessage = "";

      if (isAuthIssue) {
        destroySession();
        navigate(`/users/sign_in`);
        showToast([errorMessage], ToastTypes.ERROR);
        finalErrorMessage = errorMessage;
      } else {
        finalErrorMessage = (apiType === APIEndpoints.USER_SIGNIN &&
            "Provided email and password is not correct"
          ) || 
          (apiType === APIEndpoints.GET_DIAGNOSIS_DROPDOWN &&
            "No diagnosis exists against provided search"
          ) || errorMessage;

        showToast([finalErrorMessage], ToastTypes.ERROR);
      }
      dispatch({
        type: ActionTypes.SET_ERROR,
        payload: finalErrorMessage
      });
    } finally {
      dispatch({ type: ActionTypes.SET_LOADING, payload: false });
    }
  };

  useEffect(() => {
    if (typeof shouldAPIBeCalled === "boolean" && shouldAPIBeCalled)
      fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldAPIBeCalled]);

  useEffect(() => {
    versionChannel.onmessage = (bdChannel: { data: string; }) => {
      if (bdChannel.data === "new_version") {
        window.document.addEventListener("visibilitychange", () => {
          if (!window.document.hidden) {
            if (packageInfo.version !== localStorage.getItem("API_VERSION")) {
              window.location.reload();
            }
          }
        });
      }
    };
  }, [versionChannel]);

  /**
   * This function will trigger the API call
   * @param body : Body will contain the values passed to API call
   */
  const mutate = <TBodyType, TPathParams, TQueryParams>(
    body?: TBodyType,
    pathFromData?: TPathParams,
    queryFromData?: TQueryParams
  ): void => {
    fetchData(body, pathFromData, queryFromData);
  };

  const removeErrorAndData = () => {
    dispatch({ type: ActionTypes.CLEAR_API_RES });
  };

  return { data, loading, error, mutate, removeErrorAndData };
}

export default useFetch;
