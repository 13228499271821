import { FC } from "react"
import { Link } from "react-router-dom";
import { FAMsList } from "../TBMsRBDsFRMs/types";

const FAMs: FC<FAMsList> = ({ data,ecr_total,case_total }): JSX.Element => {

 
  return (
    <div className="card p-0">
      <div className="d-flex flex-column flex-fill">
        <div className="card-header order-1 custom-card-header">
          <h4 className="p-0 mb-0 text-black text-center " >FAMs</h4>
        </div>
        <div className="order-2 card-body p-0 overflow-y-auto" style={{ maxHeight: 400 }}>
          <table className="table table-responsive table-hover" >
            <thead className="table-light">
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Cases</th>
                <th scope="col">ECR</th>
              </tr>
            </thead>
            <tbody>
              {data && data.length>0 &&
                data.map((item,key) =>
                  <tr key={key}>
                    <td>
                      <Link to={{pathname:'/territories'
                      }} target="_blank" className="fw-normal fs-6 btn-link link-underline link-underline-opacity-0 link-offset-0-hover link-underline-opacity-75-hover" >{item?.name} </Link>
                    </td>
                    <td>{item?.cases}</td>
                    <td>{item?.ecr}</td>
                  </tr>
                )
              }
            </tbody>
            <tfoot>
              <tr>
                <td className="fw-bold">Total</td>
                <td className="fw-bold">{case_total}</td>
                <td className="fw-bold">{ecr_total}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
};

export default FAMs;