import { Dispatch, SetStateAction } from "react";

import { OrderType } from "../orderListing/types";

export type TreatmentListingType = {
  case_id: number,
  instillation: string,
  schedule_treatment_popup: boolean,
  ordered: string,
  delivery_eta: string,
  patient: string,
  provider: string,
  treatment_status: string,
  status_color: 1 | 2 | 3 | 4 | 5,
  schedule_button: boolean,
  order_button: boolean,
  treated_button: boolean,
  not_treated_button: boolean,
  treatment_id:number,
  treated_at: string
};

export type TreatmentListErrorResponse = {
  code: number;
  error: string;
};

export type TreatmentListAPIResponse = {
  code: number;
  message: string;
  data: {
    total_pages:number;
    treatments:TreatmentListingType[];
    order_type:OrderType[]
  }
};

export type TreatmentListAPIResponseType = {
  status: number;
  cases: TreatmentListingType[];
  total_pages: number;
  investigated: number;
  pending: number;
};



export type QueryParamsState = {
  open: string | boolean;
  sort_by: string;
  direction: 'asc' | 'desc';
  search_query: string;
  date_from: string;
  date_to: string;
  page: number;
};

export enum QueryStateActions {
  CHANGE_CASE_STATUS = 'changeCaseStatus',
  CHANGE_SORT_PARAMS = 'changeSortParams',
  CHANGE_SEARCH_QUERY = 'changeSearchQuery',
  CHANGE_FROM_DATE = 'changeFromDate',
  CHANGE_TO_DATE = 'changeToDate',
  CLEAR_STATE = 'clearState',
  CHANGE_PAGE = 'changePage',
};

export type QueryStateActionType =
  {
    type: QueryStateActions.CHANGE_CASE_STATUS;
    payload: boolean | string;
  } | {
    type: QueryStateActions.CHANGE_SORT_PARAMS;
    payload: {
      sort_by: string;
      direction: 'asc' | 'desc';
    };
  } | {
    type: QueryStateActions.CHANGE_SEARCH_QUERY;
    payload: string;
  } | {
    type: QueryStateActions.CHANGE_FROM_DATE;
    payload: string;
  } | {
    type: QueryStateActions.CHANGE_TO_DATE;
    payload: string;
  } | {
    type: QueryStateActions.CHANGE_PAGE;
    payload: number;
  } | {
    type: QueryStateActions.CLEAR_STATE;
  };

export type TreatmentStatusUpdateApiResponse = {
  code:number;
  message :string
};

export type ScheduleTreatmentModalProps = {
  isModalVisible: boolean;
  patientName: string;
  treatmentId: number;
  instillation: string;
  treatedAt: string;
}

export type TreatmentListingItemProps = {
  item: TreatmentListingType;
  isAPICalling: boolean;
  setDataForOrderModal: Dispatch<SetStateAction<{
    isModalVisible: boolean;
    patientName: string;
    caseId: number;
  }>>;
  onTreatedOrNotTreatedButtonClicked: (value: string, treatment_id: number) => void;
  setDataForscheduleTreatmentModal: Dispatch<SetStateAction<ScheduleTreatmentModalProps>>;
};
