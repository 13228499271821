import { FC, useContext } from "react"
import { LoaderContext } from "../../context/loaderContext";

const HubLoader: FC = (): JSX.Element => {
  const { isVisible } = useContext(LoaderContext);

  return (
    <div
      className={`progress w-100 loader ${isVisible ? 'd-flex' : 'd-none'}`}
      role="progressbar"
      aria-valuenow={100}
      aria-valuemin={0}
      aria-valuemax={100}
    >
      <div
        className="progress-bar progress-bar-striped progress-bar-animated"
        style={{ width: '100%' }}
      ></div>
    </div>
  );
};

export default HubLoader;
