// import { FC, useEffect ,useContext, useState} from "react";
// import Stepper from "../stepper";
// import { StepperType, caseDetailForModalError } from "./types";
// import useFetch from "src/hooks/useFetch";
// import { APIEndpoints } from "src/types/apiTypes";
// import { CaseDetails } from "../caseDetail/types";

// import { ToastTypes } from "src/types";
// import { AuthContext } from "src/context/authContext";
// import { ToastContext } from "src/context/toastContext";
// import { useNavigate } from "react-router-dom";
// import { LoaderContext } from "src/context/loaderContext";
// import { DownloadPDFResponseTypeApi, DownloadZipErrorType } from "src/pages/dashboard/types";
// import { downloadPdfFile } from "src/helpers/downloadFile";

// const StepperModal: FC<StepperType> = ({ caseDetails, onClick }): JSX.Element => {
//   const {destroySession} = useContext(AuthContext)
//   const { showToast } = useContext(ToastContext);
//   const { setLoader, hideLoader } = useContext(LoaderContext);
//   const navigate = useNavigate();
//   const [enabledButton,setEnabledButton] = useState<boolean>(false);

//   /////////////////////////////////////////////////Case Detail Fetch ////////////////////////////
//   const { error: casePopupError, data: casePopupData } = 
//     useFetch<{ code: number; message: string; data: CaseDetails }>(APIEndpoints.CASE_DETAIL_POPUP, 
//       { case_id: caseDetails ? caseDetails.caseId : 0 });

//   useEffect(() => {
//     if (casePopupError) {
//       // console.log("Case Pop up Error.......",{ casePopupError });
//       const { code, error } = casePopupError as unknown as caseDetailForModalError;
//       if (code === 401) {
//         destroySession();
//         showToast([`You need to sign up or sign in to continue`], ToastTypes.ERROR);
//         navigate(`/users/sign_in`);
//       }
//       showToast([error], ToastTypes.ERROR);

//     }
//   // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [casePopupError]);

//   useEffect(() => {
//     if (casePopupData) {
//       //onClick()
//        console.log({ casePopupData });
//     }
//   }, [casePopupData]);

//   //////////////////////////////////////////////////Download PDF////////////////////////////////////////////
//   const {
//     data: downloadCaseData, error: downloadCaseError, mutate: downloadRequestForm, removeErrorAndData: clearRequestFileData
//   } = useFetch<DownloadPDFResponseTypeApi>(APIEndpoints.MANUFACTURE_STATISTICS_PDF_DOWNLOAD);

//   useEffect(() => {
//     if (downloadCaseError) {
//       if (typeof downloadCaseError === 'string') {
//         showToast([downloadCaseError], ToastTypes.ERROR);
//         hideLoader();
//         setEnabledButton(false);
//       } else {
//         const { code, error } = downloadCaseError as unknown as DownloadZipErrorType;
//         hideLoader();
//         setEnabledButton(false);
//         if (code === 401) {
//           destroySession();
//           showToast([`You need to sign up or sign in to continue`], ToastTypes.ERROR);
//           navigate(`/users/sign_in`);
//         }
//         showToast([error], ToastTypes.ERROR);
//       }
//     }

//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [downloadCaseError]);

//   useEffect(() => {
//     if (downloadCaseData) {
//       const { response,report_name } = downloadCaseData;
//       downloadPdfFile(response, `${report_name}`);
//       hideLoader();
//       setEnabledButton(false);
//     }

//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [downloadCaseData]);

//   const downloadFileByMixingID = (value:number) => {
//      if (downloadCaseData || downloadCaseError) clearRequestFileData();
//     setLoader();
//     setEnabledButton(true);
//     downloadRequestForm({}, {Id:value},{type:'request',ref_type:'Case' });
//   };


//   return (
//     <div className={`modal fade show show-modal modal-lg`} id="typeModal" aria-labelledby="typeModalLabel" aria-hidden="true" >
//       <div className="modal-dialog modal-dialog-scrollable">
//         <div className="modal-content">
//           <div className="modal-header modal-ben-header row">
//             <div className="col-8">
//               <div id="modal-case-title" className="row align-content-center">
//                 <span className="text-success fs-3 col-7">Case {caseDetails?.caseId} Details</span>

//                 <button className="btn btn-primary col-md-3 col-lg-2 rounded-0" onClick={ ()  => {downloadFileByMixingID(caseDetails ? caseDetails.caseId : 0)} } disabled={enabledButton}>
//                   <i className="bi bi-file-pdf-fill">PEF</i>
//                 </button>
//               </div>
//             </div>

//             <div className="col-3 text-end">
//               <button type="button" className="btn-close btn-sm pt-1" data-bs-dismiss="modal" aria-label="Close" onClick={onClick}></button>
//             </div>
//           </div>

//           <div className="modal-body p-0 mt-0">
//             {casePopupData && casePopupData.data ? (
//               <Stepper caseDetails={casePopupData.data} />
//             ) : null}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default StepperModal;
import { FC } from "react";
import Stepper from "../stepper";
import { StepperType } from "./types";


const StepperModal: FC<StepperType> = ({ onClick }): JSX.Element => {

  return (
    <div className={`modal fade show show show-modal`} id="typeModal" aria-labelledby="typeModalLabel" aria-hidden="true" >
      <div className="modal-dialog modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header modal-ben-header row">
            <div className="col-8">
              <div id="modal-case-title" >
                <span className="text-success fw-bold bg">Case 15682 Details</span>
                <a target="_blank" id="request-link" className="ml-2 mt-1 btn btn-primary ms-1 my-1 btn-xs" download="true" href="/cases/15682/stats_request_pdf">
                  <i className="bi bi-file-pdf-fill">PEF</i>
                </a>
              </div>
            </div>
            <div className='col-3 text-end'>
              <button type="button" className="btn-close btn-sm pt-1 " data-bs-dismiss="modal" aria-label="Close" onClick={onClick} ></button>
            </div>
          </div>
          <div className="modal-body p-0 mt-0">
            <Stepper />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepperModal;

