import { saveAs } from "file-saver";
import { ACCEPTED_FILE_EXTENSIONS_CASE } from "src/constants";

export const downloadZipFile = (base64Data: string, fileName: string) => {
  fetch(`data:applicatiaon/x-zip-compressed;base64,${base64Data}`)
    .then(res => res.blob())
    .then((blob) => {
      saveAs(blob, fileName);
    });
};

export const downloadPdfFile = (base64Data: string, fileName: string) => {
  fetch(`data:applicatiaon/pdf;base64,${base64Data}`)
    .then(res => res.blob())
    .then((blob) => {
      saveAs(blob, fileName);
    });
};
export const downloadExcelFile = (base64Data: string, fileName: string) => {
  fetch(`data:applicatiaon/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64Data}`)
    .then(res => res.blob())
    .then((blob) => {
      saveAs(blob, fileName);
    });
};

export const downloadFileAccordingToExtension = (base64Data: string, fileName: string) => {
  const fileExtension = `.${fileName.split(".").pop()}`;
  if (fileExtension === ".xlsx") downloadExcelFile(base64Data, fileName);
  else
    fetch(`data:${ACCEPTED_FILE_EXTENSIONS_CASE[fileExtension]};base64,${base64Data}`)
      .then(res => res.blob())
      .then((blob) => {
        saveAs(blob, fileName);
      });
};

