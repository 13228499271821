import { FC, createContext, useState } from "react";
import { LoaderProps, LoaderProviderType } from "./types";

export const LoaderContext = createContext<LoaderProps>({
  isVisible: false,
  setLoader: () => {},
  hideLoader: () => {},
 });

const LoaderProvider: FC<LoaderProviderType> = ({ children }): JSX.Element => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const setLoader = () => {
    setIsVisible(true);
  };

  const hideLoader = () => {
    setIsVisible(false);
  };

  return (
    <LoaderContext.Provider
      value={{
        isVisible,
        setLoader,
        hideLoader,
      }}
    >
      {children}
    </LoaderContext.Provider>
  );
};

export default LoaderProvider;
