export const CASE_DETAIL_HEADER = [
  {
    value: 'case_id',
    label: 'Case Id',
  },
  {
    value: 'patient_id',
    label: 'Patient ID',
  },
  {
    value: 'b_cti',
    label: 'BI #',
  },
  {
    value: 'qty',
    label: 'Qty',
  },
  {
    value: 'received',
    label: 'Received',
  },
  {
    value: 'completed',
    label: 'Completed',
  },
  {
    value: '',
    label: 'Treatment',
  },
  {
    value: 'payer',
    label: 'Payer',
  },
  {
    value: '',
    label: 'Outcome',
  },
  {
    value: '',
    label: 'Rx Cvge',
  },
  {
    value: '',
    label: 'Med Cvge',
  },
  {
    value: '',
    label: 'Rx PA',
  },
  {
    value: '',
    label: 'Med PA',
  },
  {
    value: 'case_status',
    label: 'Case Status',
  },
  {
    value: '',
    label: 'update',
  },
  {
    value: 'provider',
    label: 'provider',
  },
];

export const ONGOING_TREATMENTS_HEADER = [
  {
    value: 'case_id',
    label: 'Case Id',
  },
  {
    value: 'patient_id',
    label: 'Patient ID',
  },
  {
    value: 'Bi',
    label: 'BI #',
  },
  {
    value: 'qty',
    label: 'Qty',
  },
 
  {
    value: 'Prevous_treatment_date',
    label: 'Prevous Treatment Date',
  },
  {
    value: 'payer',
    label: 'Payer',
  },
  {
    value: '',
    label: 'Outcome',
  },
  {
    value: '',
    label: 'Rx Cvge',
  },
  {
    value: '',
    label: 'Med Cvge',
  },
  {
    value: '',
    label: 'Rx PA',
  },
  {
    value: '',
    label: 'Med PA',
  },
  {
    value: 'case_status',
    label: 'Case Status',
  },
  {
    value: '',
    label: 'Type',
  },
  {
    value: 'provider',
    label: 'provider',
  },
];


export const PROVIDER_HEADER = [
  {
    value: '',
    label: 'Provider',
  },
  {
    value: '',
    label: 'Commercial',
  },
  {
    value: '',
    label: 'Medicare',
  },
  {
    value: '',
    label: 'Medicare advantage',
  },
 
  {
    value: '',
    label: 'Unspecified',
  },
  {
    value: '',
    label: 'Total Cases',
  },
  
];
