import { FC } from "react";
import { PhoneProps } from "./types";


const ShowPhoneNumber: FC<PhoneProps> = ({ id, phone }): JSX.Element => {
  const { phone_type, number, is_preferred, is_ok_to_leave_message } = phone;

  return (
    <div className="row p-2">
        <div className="col-lg-2 col-md-2 col-4">
            <label className="form-label">Phone Number # {id}:</label>
        </div>
        <div className="col-lg-2 col-md-2 col-4">
            <p>{phone_type || 'N/A'}</p>
        </div>

        <div className="col-lg-2 col-md-2 col-4">
            <p>{number || 'N/A'}</p>
        </div>

        <div className="col-lg-2 col-md-2 col-6">
            <p>
                {
                    is_preferred ?
                        <> <i className="bi bi-check-square-fill text-success"></i> Preferred number </> : 'N/A'
                }
            </p>
        </div>

        <div className="col-3">
            <p>
          {
            is_ok_to_leave_message ?
              <> <i className="bi bi-check-square-fill text-success"></i> Okay to Leave Message </> : 'N/A'
          }
        </p>
      </div>
    </div>
  );
};

export default ShowPhoneNumber;
