import * as yup from "yup";
import {PASSWORD_REGEX} from "../constants";

export const resetpasswordSchema = yup.object({
  password: yup
    .string()
    .when({
      is: (val:string) =>  val.length > 0,
      then: (schema)  => schema.test('PasswordValidation',
          `Password should contain <ul><li> Length should be 10,</li>
            <li> must contain at least 1 lowercase letter,</li>
            <li> must contain at least 1 uppercase letter,</li>
            <li> and 1 number</li>
            <li> Can contain special character.</li></ul>`,
          (val) => val ? PASSWORD_REGEX.test(val) : false,
      ) ,
    })
      .required(`Password is required`),

  confirmPassword: yup
    .string()
    .required(`Please re enter password to confirm`)
    // .min(8, `Password should be at least 8 characters`)
    .oneOf([yup.ref('password')], 'Passwords must match')
});
