import Highcharts from "highcharts";
const mapData = require("@highcharts/map-collection/countries/us/us-all.geo.json");

export const BAR_CHART: Highcharts.Options = {
  chart: {
    type: "bar",
    height: 150,
  },

  title: {
    text: "",
    align: "left",
  },

  xAxis: {
    categories: ["Pending Cases", "Investigated Cases"],
    title: {
      text: null,
    },
    gridLineWidth: 0,
    lineWidth: 1,
  },

  yAxis: {
    min: 0,
    title: {
      text: "",
    },
    labels: {
      enabled: false,
    },
    gridLineWidth: 1,
  },

  plotOptions: {
    bar: {
      borderRadius: "0",
      dataLabels: {
        enabled: false,
      },
      groupPadding: 0.1,
    },
  },

  colors: ["#FF9E1B"],

  credits: {
    enabled: false,
  },

  legend: {
    enabled: false,
  },

  accessibility: {
    enabled: false,
  },
};

export const LINE_CHART: Highcharts.Options = {
  // Highcharts.chart('container', {

  title: {
    text: "",
    align: "left",
  },
  chart:{
    type:'area'
  },

  // subtitle: {
  //   text: 'By Job Category. Source: <a href="https://irecusa.org/programs/solar-jobs-census/" target="_blank">IREC</a>.',
  //   align: "left",
  // },

  yAxis: {
    title: {
      text: "",
    },
    accessibility:{
        rangeDescription:"Range:  22Jan to 13Feb"
    }
  },

  credits: {
    enabled: false
  },

  legend: {
    layout: 'horizontal',
    align: "center",
    verticalAlign: 'bottom',
  },

  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
      pointStart: 0,
    },
  },
  series: 
  [
    {
      type: "line",
      name: "Received",
      data: [
        43934, 48656, 65165, 81827, 112143, 142383, 171533, 165174, 155157,
        161454, 154610,
      ],
    },
    {
      type: "line",
      name: "Completed",
      data: [
        24916, 37941, 29742, 29851, 32490, 30282, 38121, 36885, 33726, 34243,
        31050,
      ],
    },
    {
      type: "line",
      name: "Sameday Completed",
      data: [
        11744, 30000, 16005, 19771, 20185, 24377, 32147, 30912, 29243, 29213,
        25663,
      ],
    },
    {
      type: "line",
      name: "PA Required",
      data: [
        11211,
        11299,
        11800,
        13456,
        14534,
        16785,
        90009,
        12323,
        11164,
        11218,
        10077,
      ],
    }
  ],

  responsive: {
    rules: [{
      condition: {
        maxWidth: 500,
      },
      chartOptions: {
        legend: {
          layout: "horizontal",
          align: "center",
          verticalAlign: "bottom",
        },
      },
    }],
  },

  accessibility: {
    enabled: false,
  },

  //});
};

export const PIE_CHART_TOP_10PAYOR: Highcharts.Options = {
  chart: {
    type: "pie",
  },
  title:{
    text:""
  },
  tooltip: {
    valueSuffix: "",
  },
  credits: {
    enabled: false
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      showInLegend: true,
      dataLabels: {
        enabled: false,
        format: '<b>{point.name}</b>: {point.percentage:.1f}%',
        style: {
          fontWeight: 'normal',
          textOverflow: 'clip'
        },
        connectorPadding: 1,
        connectorWidth: 1,
        distance: 20,
      },
    },
  },
  series: [{
    type: "pie",
    name: "Percentage",
    // colorByPoint: true,
    data: [
      {
        "name": "",
        "y": 597
      },
      {
        "name": "MOBILE",
        "y": 10
      },
      {
        "name": "JUBILLE",
        "y": 10
      },
      {
        "name": "PERX1",
        "y": 10
      },
      {
        "name": "ABC TEST",
        "y": 8
      },
      {
        "name": "PAPER",
        "y": 7
      },
      {
        "name": "BLUE CROSS",
        "y": 6
      },
      {
        "name": "MELVIN SHAW",
        "y": 5
      },
      {
        "name": "MED-INS1",
        "y": 5
      },
      {
        "name": "Hea CS",
        "y": 5
      }
    ]
  }],
  accessibility: {
    enabled: false,
  },
};

export const MAP_CHART: Highcharts.Options = {
  chart: {
    map: mapData,
  },

  title: {
    text: "",
  },

  subtitle: {
    text: "",
  },
  credits: {
    enabled: false,
  },
  mapNavigation: {
    enabled: false,
    buttonOptions: {
      verticalAlign: "bottom",
    },
  },

  colorAxis: {
    min: 0,
    max: 30,
    //type: 'logarithmic',
    minColor: "#EEEEFF",
    maxColor: "#000022",
    stops: [
      [0, "#EFEFFF"],
      [0.67, "#4444FF"],
      [1, "#000022"],
    ],
  },
  accessibility: {
    enabled: false,
  },
};
