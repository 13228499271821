// import { FC, useContext, useEffect, useState } from "react"
// import { useNavigate } from "react-router-dom";

// import { APIEndpoints } from "src/types/apiTypes";
// import useFetch from "src/hooks/useFetch";
// import { DownloadZipErrorType } from "src/pages/dashboard/types";
// import { ToastTypes } from "src/types";
// import { downloadExcelFile } from "src/helpers/downloadFile";
// import { AuthContext } from "src/context/authContext";
// import { ToastContext } from "src/context/toastContext";
// import { LoaderContext } from "src/context/loaderContext";
// import { DownloadReportsResponseTypeApi, StatsReportProps } from "./types";

// const StatsReports: FC<StatsReportProps> = ({ getValues }): JSX.Element => {
//   const [enabledButton, setEnabledButton] = useState<boolean>(false);
//   const { destroySession } = useContext(AuthContext)
//   const { showToast } = useContext(ToastContext);
//   const { setLoader, hideLoader } = useContext(LoaderContext);
//   const navigate = useNavigate();

//   const {
//     data: downloadReportsData, error: downloadReportsError, mutate: downloadRequestForm, removeErrorAndData: clearRequestFileData
//   } = useFetch<DownloadReportsResponseTypeApi>(APIEndpoints.MANUFACTURE_STATISTICS_REPORT_DOWNLOAD);

//   useEffect(() => {
//     if (downloadReportsError) {
//       if (typeof downloadReportsError === 'string') {
//         showToast([downloadReportsError], ToastTypes.ERROR);
//         hideLoader();
//         setEnabledButton(false);
//       } else {
//         const { code, error } = downloadReportsError as unknown as DownloadZipErrorType;
//         hideLoader();
//         setEnabledButton(false);
//         if (code === 401) {
//           destroySession();
//           showToast([`You need to sign up or sign in to continue`], ToastTypes.ERROR);
//           navigate(`/users/sign_in`);
//         }
//         showToast([error], ToastTypes.ERROR);
//       }
//     }

//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [downloadReportsError]);

//   useEffect(() => {
//     if (downloadReportsData) {
//       const { response, report_name } = downloadReportsData;
//       downloadExcelFile(response, `${report_name}.xlsx`);
//       hideLoader();
//       setEnabledButton(false);

//     }

//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [downloadReportsData]);


//   const DownloadReport = (reportName: string) => {
//     if(getValues){
//       let params = {
//         ...getValues(),
//         'report_name': reportName
//       }
//       if (downloadReportsData || downloadReportsError) clearRequestFileData();
//       setLoader();
//       setEnabledButton(true)
//       downloadRequestForm({}, {}, params);
//     }



//   }
//   return (
//     <div className="row justify-content-between align-items-center">
//       <div className="col-20">
//         <button className="btn btn-success rounded-0 w-100" onClick={() => { DownloadReport("urogen_pef_report") }} disabled={enabledButton}>
//           PEF Report
//         </button>
//       </div>

//       <div className="col-20">
//         <button className="btn btn-success rounded-0 w-100" onClick={() => { DownloadReport("urogen_payer_report") }} disabled={enabledButton}>
//           Payer Report
//         </button>
//       </div>

//       <div className="col-20">
//         <button className="btn btn-success rounded-0 w-100" onClick={() => { DownloadReport("urogen_full_report") }} disabled={enabledButton}>
//           Full Report
//         </button>
//       </div>

//       <div className="col-20">
//         <button className="btn btn-success rounded-0 w-100" onClick={() => { DownloadReport("urogen_mixing_report") }} disabled={enabledButton}>
//           Mixing Report
//         </button>
//       </div>

//       <div className="col-20">
//         <button className="btn btn-success rounded-0 w-100" onClick={() => { DownloadReport("urogen_orders_audit_report") }} disabled={enabledButton}>
//           Order Report
//         </button>
//       </div>

//       {/* <div className="col-4 mt-3">
//         <button className="btn btn-success rounded-0 w-100" onClick={ ()  => {DownloadReport()} }>
//           Orders Report
//         </button>
//       </div> */}
//     </div>
//   );
// };

// export default StatsReports;

import { FC, useContext, useEffect, useState } from "react"
import { DownloadReportsResponseTypeApi, StatsReportProps } from "./types";
// import { getValues } from "src/constants/highCharts";
import useFetch from "src/hooks/useFetch";
import { DownloadZipErrorType } from "src/pages/dashboard/types";
import { ToastTypes } from "src/types";
import { APIEndpoints } from "src/types/apiTypes";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "src/context/authContext";
import { LoaderContext } from "src/context/loaderContext";
import { ToastContext } from "src/context/toastContext";
import { downloadExcelFile } from "src/helpers/downloadFile";

const StatsReports: FC<StatsReportProps> = ({ getValues }): JSX.Element => {

  const [enabledButton, setEnabledButton] = useState<boolean>(false);
  const { destroySession } = useContext(AuthContext)
  const { showToast } = useContext(ToastContext);
  const { setLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  const {
    data: downloadReportsData, error: downloadReportsError, mutate: downloadRequestForm, removeErrorAndData: clearRequestFileData
  } = useFetch<DownloadReportsResponseTypeApi>(APIEndpoints.MANUFACTURE_STATISTICS_REPORT_DOWNLOAD);

  useEffect(() => {
    hideLoader();
    if (downloadReportsError) {
      hideLoader();
      if (typeof downloadReportsError === 'string') {
        showToast([downloadReportsError], ToastTypes.ERROR);
        setEnabledButton(false);
      } else {
        const { code, error } = downloadReportsError as unknown as DownloadZipErrorType;
        setEnabledButton(false);
        if (code === 401) {
          destroySession();
          showToast([`You need to sign up or sign in to continue`], ToastTypes.ERROR);
          navigate(`/users/sign_in`);
        }
        showToast([error], ToastTypes.ERROR);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadReportsError]);

  useEffect(() => {
    if (downloadReportsData) {
      const { response, report_name } = downloadReportsData;
      downloadExcelFile(response, `${report_name}.xlsx`);
      hideLoader();
      setEnabledButton(false);

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadReportsData]);


  const DownloadReport = (reportName: string) => {
   if(getValues){
    let params = {
      'start_date':getValues().start_date,
      'end_date' : getValues().end_date,
      'report_name': reportName
    }
    if (downloadReportsData || downloadReportsError) clearRequestFileData();
    setLoader();
    setEnabledButton(true)
    downloadRequestForm({}, {}, params);
   }



  }
  return (
    <div className="row">
      <div className="col-2">
        <button className="btn btn-primary w-100" onClick={() => { DownloadReport("sp_transfers_report") }} disabled={enabledButton}>
            SP Transfers Report
        </button>
      </div>
      <div className="col-2">
        <button className="btn btn-primary w-100" onClick={() => { DownloadReport("full_report") }} disabled={enabledButton}>
            Full Report
        </button>
      </div>
    </div>
  );
};

export default StatsReports;
