import { FC, useEffect, useContext, useState } from "react";
import { Controller } from "react-hook-form";
import { GroupBase, OptionsOrGroups } from "react-select";

import HubInputField from "../../inputType";

import { US_STATES } from "src/constants";
import useFetch from "src/hooks/useFetch";

import { APIEndpoints } from "src/types/apiTypes";
import { NPILookupResponse, ToastTypes } from "src/types";
import { ToastContext } from "src/context/toastContext";
import { PracticeFormPropsType } from "./types";
import { LoaderContext } from "src/context/loaderContext";
import { populatePracticeEnrollmentForm, resetPracticeFormFields } from "src/helpers/enrollment";

const EnrollmentPracticeForm: FC<PracticeFormPropsType> = ({ parentNpi, control, formState, getValues, setValue, resetField, trigger }): JSX.Element => {
  const [preservedNpi, updatePreservedNpi] = useState<string>(parentNpi);

  const { showToast } = useContext(ToastContext);
  const { setLoader, hideLoader } = useContext(LoaderContext);

  /**
   * @NPILookup calling npi lookup for details
   * @first useFetch hook for api call
   * @second useFetch error handler
   * @third useFetch success handler
   * @fourth npi lookup api trigger
   */
  const { data: npiLookupData, error: npiLookupError, mutate: lookupNpi, removeErrorAndData: clearNpiData } = useFetch<NPILookupResponse>(APIEndpoints.NPI_LOOKUP);

  /** @second */
  useEffect(() => {
    if (npiLookupError) {
      hideLoader();
      showToast([npiLookupError || ''], ToastTypes.ERROR);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [npiLookupError]);

  /** @third */
  useEffect(() => {
    if (npiLookupData) {
      hideLoader();
      if (npiLookupData.practice_data !== null) {
        showToast([`Practice Data Found`], ToastTypes.INFO);
        populatePracticeEnrollmentForm(setValue, npiLookupData, preservedNpi);
        trigger(["enrollment_practices_attributes.0"]);
      }
  
      if (npiLookupData.practice_data === null) {
        resetPracticeFormFields(resetField);
        showToast([`No Data Found`], ToastTypes.ERROR);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [npiLookupData]);

  /** @fourth */
  const callNPILookup = () => {
    const npi = getValues("enrollment_practices_attributes.0.npi");

    if (!npiLookupData || npi !== preservedNpi) {
      const npiError = formState.errors && formState.errors.enrollment_practices_attributes && formState.errors.enrollment_practices_attributes[0]?.npi;

      if (
        !npi || 
        (npi && npi.length === 0) || 
        npiError
      )
        showToast([`Please enter valid NPI for lookup`], ToastTypes.ERROR);
      else {
        setLoader();
        clearNpiData();
        updatePreservedNpi(npi);
        const data = {
          'global_doctor[npi]': npi,
        };
        lookupNpi(null, null, data);
      }
    }
  };

  return (
    <>
      <Controller
        name="enrollment_practices_attributes.0.practice_name"
        control={control}
        defaultValue=""
        render={({ field, fieldState: { error } }) => (
          <HubInputField
            inputId="enrollment_practices_attributes.0.practice_name"
            inputLabel="Practice Name"
            inputPlaceholder="Specify Practice Name"
            isRequired={true}
            isRowBased={true}
            field={field}
            error={error}
          />
        )}
      />

      <div className="row my-2">
        <div className="col-lg-6">
          <Controller
            name="enrollment_practices_attributes.0.npi"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { error } }) => (
              <HubInputField
                inputId="enrollment_practices_attributes.0.npi"
                inputLabel="NPI"
                isRequired={true}
                isRowBased={true}
                isRowInsideAnother={true}
                field={field}
                error={error}
                onBlur={callNPILookup}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    callNPILookup();
                  }
                }}
              />
            )}
          />
        </div>
        <div className="col-lg-6">
          <Controller
            name="enrollment_practices_attributes.0.tax_id"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { error } }) => (
              <HubInputField
                inputId="enrollment_practices_attributes.0.tax"
                inputLabel="Tax ID"
                isRequired={false}
                isRowBased={true}
                isRowInsideAnother={true}
                field={field}
                error={error}
              />
            )}
          />
        </div>
      </div>

      <div className="row my-2">
        <div className="col-lg-6">
          <Controller
            name="enrollment_practices_attributes.0.phone_number"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { error } }) => (
              <HubInputField
                inputId="enrollment_practices_attributes.0.phone_number"
                inputLabel="Phone Number"
                inputPlaceholder="e.g. (555) 555-5555"
                isRequired={true}
                isRowBased={true}
                isRowInsideAnother={true}
                field={field}
                error={error}
              />
            )}
          />
        </div>
        <div className="col-lg-6">
          <Controller
            name="enrollment_practices_attributes.0.fax_number"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { error } }) => (
              <HubInputField
                inputId="enrollment_practices_attributes.0.fax_number"
                inputLabel="Fax Number"
                inputPlaceholder="e.g. (555) 555-5555"
                isRequired={true}
                isRowBased={true}
                isRowInsideAnother={true}
                field={field}
                error={error}
              />
            )}
          />
        </div>
      </div>

      <div className="row my-2">
        <div className="col-lg-6">
          <Controller
            name="enrollment_practices_attributes.0.street"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { error } }) => (
              <HubInputField
                inputId="enrollment_practices_attributes.0.street"
                inputLabel="Street"
                isRequired={true}
                isRowBased={true}
                isRowInsideAnother={true}
                field={field}
                error={error}
              />
            )}
          />
        </div>
        <div className="col-lg-6">
          <Controller
            name="enrollment_practices_attributes.0.suite"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { error } }) => (
              <HubInputField
                inputId="enrollment_practices_attributes.0.suite"
                inputLabel="Suite"
                isRequired={false}
                isRowBased={true}
                isRowInsideAnother={true}
                field={field}
                error={error}
              />
            )}
          />
        </div>
      </div>

      <div className="row my-2">
        <div className="col-lg-6">
          <Controller
            name="enrollment_practices_attributes.0.city"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { error } }) => (
              <HubInputField
                inputId="enrollment_practices_attributes.0.city"
                inputLabel="City"
                isRequired={true}
                isRowBased={true}
                isRowInsideAnother={true}
                field={field}
                error={error}
              />
            )}
          />
        </div>
        <div className="col-lg-6">
          <Controller
            name="enrollment_practices_attributes.0.state"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { error } }) => (
              <HubInputField
                field={field}
                error={error}
                inputId="enrollment_practices_attributes.0.state"
                inputPlaceholder="Select a State..."
                inputLabel="State"
                isRequired={true}
                isRowBased={true}
                isRowInsideAnother={true}
                isDropdown={true}
                isSearchable={false}
                dropdownData={US_STATES as unknown as OptionsOrGroups<string, GroupBase<string>>}
              />
            )}
          />
        </div>
      </div>

      <div className="row my-2">
        <div className="col-lg-6">
          <Controller
            name="enrollment_practices_attributes.0.zip"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { error } }) => (
              <HubInputField
                inputId="enrollment_practices_attributes.0.zip"
                inputLabel="Zip"
                isRequired={true}
                isRowBased={true}
                isRowInsideAnother={true}
                field={field}
                error={error}
              />
            )}
          />
        </div>
      </div>
    </>
  );
};

export default EnrollmentPracticeForm;
