import { FC, useContext, useEffect, useState } from "react";
import Select, { GroupBase, OptionsOrGroups, PropsValue, SingleValue } from "react-select";

import { RequestDateOfTreatmentProps, RequestTreatmentNotTreatingApiError, RequestTreatmentNotTreatingApiResponse } from "../requestDateOfTreatment/types";
import { STATE_TREATMENT } from "src/constants/cases";
import { AuthContext } from "src/context/authContext";
import { ToastContext } from "src/context/toastContext";
import { useNavigate } from "react-router-dom";
import useFetch from "src/hooks/useFetch";
import { APIEndpoints } from "src/types/apiTypes";
import { ToastTypes } from "src/types";

const CancelTreatment: FC<RequestDateOfTreatmentProps> = ({ isModalVisible, caseId, hideModal, prescriptionId ,refreshListing}): JSX.Element => {
  const [cancelReason, setCancelReason] = useState<string>('Cost');

  const { showToast } = useContext(ToastContext);
  const { destroySession } = useContext(AuthContext);
  const navigate = useNavigate();


  const getValue = () => {
    const data = STATE_TREATMENT.find((option) => option.value === cancelReason);
    if (data) return data as unknown as PropsValue<string>;
  };

  const onChangeSelect = (value: SingleValue<string>) => {
    setCancelReason((value as unknown as { value: string; label: string; }).value);
  };

  const resetAndHideModal = () => {
    setCancelReason("Cost");
    hideModal();
  };





  const { loading, data: requestTreatmentData, mutate: updateRequestTreatment, error: treatmentUpdateError } = 
    useFetch<RequestTreatmentNotTreatingApiResponse>(APIEndpoints.REQUEST_TREATMENT_NOT_TREATING);

  const saveTreatmentReason = () => {
    const requestBody = {
      prescription: {
        bentype: "non",
        status_reason: cancelReason
      }
    };
    const queryParam = {
      buy_and_bill:false
    }
    updateRequestTreatment(requestBody, { caseId, prescriptionId },queryParam);
  };

  useEffect(() => {
    if (treatmentUpdateError) {
      const { code, error } = treatmentUpdateError as unknown as RequestTreatmentNotTreatingApiError;
      if (code === 401) {
        destroySession();
        showToast([`You need to sign up or sign in to continue`], ToastTypes.ERROR);
        resetAndHideModal()
        navigate(`/users/sign_in`);
      } else {
        showToast([error || `Some Error Occurred.Please Try Again in a while`], ToastTypes.ERROR);
        resetAndHideModal()
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treatmentUpdateError]);

  useEffect(() => {
    if (requestTreatmentData) {
      setCancelReason("Cost");
      const { code } = requestTreatmentData;
      if (code === 200) {
        showToast([`Treatment Cancel Successfully`], ToastTypes.SUCCESS);
        refreshListing()
        resetAndHideModal()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestTreatmentData]);





  return (
    <div
      className={`modal fade ${isModalVisible ? 'show show-modal' : ''}`}
      id="exampleModal"
      tabIndex={-1}
      data-bs-backdrop="true"
      aria-hidden="true"
      onClick={resetAndHideModal}
    >
      <div className="modal-dialog modal-lg" onClick={e => e.stopPropagation()}>
        <div className="modal-content">
          <div className="modal-header">
            <p className="modal-title fs-2 text-primary" id="exampleModalLabel">
              Non-treatment Reason for Case {caseId}
            </p>
            <button type="button" className="btn-close" aria-label="Close" onClick={hideModal}></button>
          </div>

          <div className="modal-body">
            <p className="text-center"> Please specify reason for non-treatment... </p>

            <div className="row align-items-center">
              <div className="col-3 text-end">
                <p className="mb-0"> Status Reason </p>
              </div>

              <div className="col-9">
                <Select
                  options={STATE_TREATMENT as unknown as OptionsOrGroups<string, GroupBase<string>>}
                  id="cancelTreatment"
                  isSearchable={false}
                  noOptionsMessage={() => "Select Option..."}
                  value={getValue()}
                  onChange={(value) => onChangeSelect(value)}
                />
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary rounded-0 px-4"
              data-bs-dismiss="modal"
              onClick={saveTreatmentReason}
              disabled={loading}
            >
              <div className="row align-items-center px-1">
                {loading ? (
                  <div className="spinner-border text-light col-3" role="status">
                    <span className="sr-only"></span>
                  </div>
                ) : null}
                <p className="col-auto mb-0"> Save </p>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelTreatment;
