import { FC, createContext, useState } from "react";

import { ToastContextProps, ToastProviderProps } from "./types";
import { ToastTypes } from "../../types";

export const ToastContext = createContext<ToastContextProps>({
  isToastVisible: false,
  message: [],
  toastType: ToastTypes.INFO,
  showToast: (message: string[], toastType: ToastTypes) => {},
  hideToast: () => {},
});

const ToastProvider: FC<ToastProviderProps> = ({ children }): JSX.Element => {
  const [isToastVisible, setToastVisibility] = useState<boolean>(false);
  const [message, setMessage] = useState<string[]>([]);
  const [toastType, changeToastType] = useState<ToastTypes>(ToastTypes.INFO);

  const hideToast = (): void => {
    setMessage([]);
    changeToastType(ToastTypes.INFO);
    setToastVisibility(false);
  };

  const showToast = (message: string[], toastType: ToastTypes): void => {
    setMessage(message);
    changeToastType(toastType);
    setToastVisibility(true);

    setTimeout(() => {
      hideToast();
    }, 2000);
  };

  return (
    <ToastContext.Provider value={{ isToastVisible, message, toastType, showToast, hideToast }}>
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
