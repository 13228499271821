export type OrderListingType = {
  order_id: number;
  case_id: number | string;
  po_number: string;
  ordered_by: string;
  order_date: string;
  completion_date: string;
  status: string;
};
export type  OrderType =  {
  type:string;
  name:string;
}
export type OrderListErrorResponse = {
  code: number;
  error: string;
};

export type OrderListAPIResponse = {
  code: number;
  message: string;
  data: {
    order_button: boolean;
    total_pages:number;
    orders:OrderListingType[]
    order_type:OrderType[]
  }
};

export type TreatmentListAPIResponseType = {
  status: number;
  cases: OrderListingType[];
  total_pages: number;
  investigated: number;
  pending: number;
};



export type QueryParamsState = {
  open: string | boolean;
  sort_by: string;
  direction: 'asc' | 'desc';
  search_query: string;
  date_from: string;
  date_to: string;
  page: number;
};

export enum QueryStateActions {
  CHANGE_CASE_STATUS = 'changeCaseStatus',
  CHANGE_SORT_PARAMS = 'changeSortParams',
  CHANGE_SEARCH_QUERY = 'changeSearchQuery',
  CHANGE_FROM_DATE = 'changeFromDate',
  CHANGE_TO_DATE = 'changeToDate',
  CLEAR_STATE = 'clearState',
  CHANGE_PAGE = 'changePage',
};

export type QueryStateActionType =
  {
    type: QueryStateActions.CHANGE_CASE_STATUS;
    payload: boolean | string;
  } | {
    type: QueryStateActions.CHANGE_SORT_PARAMS;
    payload: {
      sort_by: string;
      direction: 'asc' | 'desc';
    };
  } | {
    type: QueryStateActions.CHANGE_SEARCH_QUERY;
    payload: string;
  } | {
    type: QueryStateActions.CHANGE_FROM_DATE;
    payload: string;
  } | {
    type: QueryStateActions.CHANGE_TO_DATE;
    payload: string;
  } | {
    type: QueryStateActions.CHANGE_PAGE;
    payload: number;
  } | {
    type: QueryStateActions.CLEAR_STATE;
  };
