import * as yup from "yup";

import { EMAIL_REGEX } from "../constants";

export const LoginSchema = yup.object({
  email: yup
    .string()
    .email(`Provided value should be a valid email`)
    .required('can\'t be blank')
    .test('EmailVerification', `Provided value should be a valid email`, (val) => (EMAIL_REGEX.test(val)), ),

  password: yup
    .string()
    .required('can\'t be blank'),
});
