import { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "src/context/authContext";
import { FooterContext } from "src/context/footerContext";
import TriViscLogo  from "../../assets/images/triVisc-logo.png";
import GenViscLogo  from "../../assets/images/GenVisc850-logo.webp";

const Footer = () => {

  const { isUserAuthorized, isManufacturer } = useContext(AuthContext);

  const { isAccordianShown, changeAccordianVisibility }  = useContext(FooterContext);


  return (
    <footer className="sticky-bottom">
      <div className="container-fluid bg-primary d-none">
        <div className="row justify-content-center text-uppercase mw-100">
          <Link to={isUserAuthorized ? (isManufacturer ? `/statistics` : `/cases`) : `/users/sign_in`}
                className="text-white link-underline link-underline-opacity-0 col-auto Hub-Footer-link my-1">
            Home
          </Link>
          <a target="_blank" rel="noreferrer" href="https://activekeysolutions.com/"
             className="text-white link-underline link-underline-opacity-0 col-auto Hub-Footer-link my-1">
            Privacy Policy
          </a>
        </div>
      </div>
      <div className="accordion prescribing-accordion" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button onClick={changeAccordianVisibility} className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne">
              Please see important information and full prescribing information
              {isAccordianShown ? (
                  <i className="bi bi-chevron-down ps-1"></i>
              ) : (
                  <i className="bi bi-chevron-up ps-1"></i>
              )}
            </button>
          </h2>
          <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
            <div className="accordion-body ps-2 pe-2">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <img width="140" src={TriViscLogo} className="mb-2" alt="TriVisc logo"/>
                        <p>
                          <b className="d-block">Important Safety Information</b>
                          TriVisc is indicated for the treatment of pain in osteoarthritis (OA) of the knee in patients
                          who have failed to respond adequately to conservative non-pharmacologic therapy and to simple
                          analgesics (e.g. acetaminophen).
                          <br/><br/>
                          TriVisc is contraindicated in patients with known hypersensitivity to hyaluronate
                          preparations. Intra-articular injections are contraindicated in cases of present infections or
                          skin diseases in the area of the injection site to reduce the potential for developing septic
                          arthritis.
                          <br/><br/>
                          The most commonly reported adverse events in the TriVisc group included injection site pain,
                          allergic reaction, arthralgia, and bleeding at the injection site.
                        </p>
                        <a href="https://orthogenrx.com/trivisc-full-prescribing-and-safety-information" target="_blank" className="btn btn-primary" rel="noreferrer">Full Prescribing Information</a>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <img width="180" src={GenViscLogo} className="mb-5" alt="TriVisc logo"/>
                        <p>
                          <b className="d-block">Important Safety Information</b>
                          GenVisc*850 is indicated for the treatment of pain in osteoarthritis (A) of the knee in
                          patients who have failed to respond adequately to conservative non-pharmacologic therapy and
                          to simple analgesics (e.g. acetaminophen).
                          <br/><br/>
                          GenVics850 is contraindicated in patients with known hypersensitivity to hyaluronate
                          preparations. Intra-articular injections are contraindicated in cases of present infections or
                          skin diseases in the area of the injection site to reduce the potential for developing septic
                          arthritis.
                          <br/><br/>
                          The most commonly reported adverse events in the GenVisc*850 group included injection site
                          pain, allergic reaction, arthralgia, and bleeding at the injection site.
                        </p>
                        <a href="https://orthogenrx.com/genvisc-full-prescribing-and-safety-information/"
                           target="_blank" className="btn btn-primary" rel="noreferrer">Full Prescribing Information</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
