import { AuthStateAction, AuthStateActions, AuthStateProps } from "./types";

export const initialState: AuthStateProps = {
  isUserAuthorized: false,
  isManufacturer: false,
  authToken: "",
  userData: null,
  sub_header: null,
  isRestoreCalled: false,
};

export const authContextReducer = (state: AuthStateProps, action: AuthStateAction): AuthStateProps => {
  switch (action.type) {
    case AuthStateActions.SET_SESSION:
      return {
        ...state,
        ...action.payload,
      };

    case AuthStateActions.UPDATE_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };

    case AuthStateActions.UPDATE_USER_TOKEN:
      return {
        ...state,
        authToken: action.payload,
      };

    case AuthStateActions.CLEAR_SESSION:
      return initialState;

    default:
      return state;
  }
};
