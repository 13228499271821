import { FC } from "react";

import { TreatmentListingItemProps } from "./types";


const RenderNextStep: FC<TreatmentListingItemProps> = ({ 
  item,
  isAPICalling,
  setDataForscheduleTreatmentModal,
  setDataForOrderModal,
  onTreatedOrNotTreatedButtonClicked,
}): JSX.Element => {
  let caseAction = <></>;

  if (item.schedule_button) {
    caseAction = (
      <>
        <button
          type="button"
          className="btn btn-success fs-6 rounded-0"
          onClick={() => setDataForscheduleTreatmentModal({ isModalVisible: true, patientName: item.patient, treatmentId: item.treatment_id, instillation: item.instillation, treatedAt: item.treated_at })}
        >
          <i className="bi bi-clipboard-fill"></i>{' '}
          Schedule
        </button>
        {item.treated_button ?
          <button
            type="button"
            className="btn btn-success fs-6 rounded-0 me-1"
            onClick={() => onTreatedOrNotTreatedButtonClicked("Treated", item.treatment_id)}
            disabled={isAPICalling}
          >
            <i className="bi bi-check-lg"></i>{' '}
            Treated
          </button>
        : null}
        {item.not_treated_button ?
          <button
            type="button"
            className="btn btn-danger fs-6 rounded-0"
            onClick={() => onTreatedOrNotTreatedButtonClicked("Not-Treated", item.treatment_id)}
            disabled={isAPICalling}
          >
            <i className="bi bi-x-lg"></i>{' '}
            Not Treated
          </button>
        : null}
      </>
    );
  } else if (item.order_button) {
    caseAction = (
      <>
        <button
          type="button"
          className="btn btn-success fs-6 rounded-0 me-1"
          onClick={() => setDataForOrderModal({ isModalVisible: true, patientName: item.patient ,caseId : item.case_id})}
        >
          <i className="bi bi-prescription"></i>{' '}
          Order
        </button>
        {item.treated_button ?
          <button
            type="button"
            className="btn btn-success fs-6 rounded-0 me-1"
            onClick={() => onTreatedOrNotTreatedButtonClicked("Treated", item.treatment_id)}
            disabled={isAPICalling}
          >
            <i className="bi bi-check-lg"></i>{' '}
            Treated
          </button>
        : null}
        {item.not_treated_button ?
          <button
            type="button"
            className="btn btn-danger fs-6 rounded-0"
            onClick={() => onTreatedOrNotTreatedButtonClicked("Not-Treated", item.treatment_id)}
            disabled={isAPICalling}
          >
            <i className="bi bi-x-lg"></i>{' '}
            Not Treated
          </button>
        : null}
      </>
    )
  } else {
    caseAction = (
      <>
        {item.treated_button ?
          <button
            type="button"
            className="btn btn-success fs-6 rounded-0 me-1"
            onClick={() => onTreatedOrNotTreatedButtonClicked("Treated", item.treatment_id)}
            disabled={isAPICalling}
          >
            <i className="bi bi-check-lg"></i>{' '}
            Treated
          </button>
        : null}
        {item.not_treated_button ?
          <button
            type="button"
            className="btn btn-danger fs-6 rounded-0"
            onClick={() => onTreatedOrNotTreatedButtonClicked("Not-Treated", item.treatment_id)}
            disabled={isAPICalling}
          >
            <i className="bi bi-x-lg"></i>{' '}
            Not Treated
          </button>
        : null}
        {!item.schedule_button && !item.treated_button && !item.not_treated_button &&  !item.order_button ?
          <p className="text-black fs-6 text-bold mb-0">No Action Required</p>
        : null}
      </>
    );
  }

  return caseAction;
};

export default RenderNextStep;
