import { FC, useState } from "react";
import Select, { GroupBase, OptionsOrGroups, PropsValue, SingleValue } from "react-select";

import { STATE_TREATMENT } from "src/constants/cases";

import { RequestTreatedOrNotTreatedTreatmentProps } from "./types";

const TreatedOrNotTreatedTreatment: FC<RequestTreatedOrNotTreatedTreatmentProps> = ({ isModalVisible,hideModal,refreshListing,onTreatedOrNotTreatedButtonClick,dataTreatmentStates}): JSX.Element => {

  const [cancelReason, setCancelReason] = useState<string>('Cost');

  const getValue = () => {
    const data = STATE_TREATMENT.find((option) => option.value === cancelReason);
    if (data) return data as unknown as PropsValue<string>;
  };

  const onChangeSelect = (value: SingleValue<string>) => {
    setCancelReason((value as unknown as { value: string; label: string; }).value);
  };

  return (
    <div
      className={`modal fade ${isModalVisible ? 'show show-modal' : ''}`}
      id="exampleModal"
      tabIndex={-1}
      data-bs-backdrop="true"
      aria-hidden="true"
      onClick={hideModal}
    >
      <div className="modal-dialog modal-lg" onClick={e => e.stopPropagation()}>
        <div className="modal-content">
          <div className="modal-header">
            <p className="modal-title fs-2 text-primary" id="exampleModalLabel">
              Non-treatment Reason for Case {dataTreatmentStates.caseId}
            </p>
            <button type="button" className="btn-close" aria-label="Close" onClick={hideModal}></button>
          </div>

          <div className="modal-body">
            <p className="text-center"> Please specify reason for non-treatment... </p>

            <div className="row align-items-center">
              <div className="col-3 text-end">
                <p className="mb-0"> Status Reason </p>
              </div>

              <div className="col-9">
                <Select
                  options={STATE_TREATMENT as unknown as OptionsOrGroups<string, GroupBase<string>>}
                  id="NotTreatedTreatment"
                  isSearchable={false}
                  noOptionsMessage={() => "Select Option..."}
                  value={getValue()}
                  onChange={(value) => onChangeSelect(value)}
                />
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary rounded-0 px-4"
              data-bs-dismiss="modal"
              onClick={()=>onTreatedOrNotTreatedButtonClick("Not Treated",dataTreatmentStates.treatment_id)}
            >
              <div className="row align-items-center px-1">
                <p className="col-auto mb-0"> Save </p>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TreatedOrNotTreatedTreatment;
