import './App.scss';
import { BrowserRouter } from 'react-router-dom';

import HubRoutes from "./routes";

import AuthProvider from './context/authContext';
import ToastProvider from "./context/toastContext";
import HubToast from './components/toast';
import LoaderProvider from './context/loaderContext';
import HubLoader from './components/loader';
import FooterProvider from './context/footerContext';

function App() {
  return (
    <AuthProvider>
      <ToastProvider>
        <LoaderProvider>
          <FooterProvider>
          <>
            <BrowserRouter>
              <HubRoutes />
            </BrowserRouter>

            <HubToast />
            <HubLoader />
          </>
          </FooterProvider>
        </LoaderProvider>
      </ToastProvider>
    </AuthProvider>
  );
};

export default App;
