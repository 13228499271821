import { APIRouteType } from "src/types/apiTypes";

export type FetchHookStateType<TResponseData> = {
  data?: TResponseData;
  loading: boolean;
  apiData: APIRouteType;
  error?: string;
  shouldAPIBeCalled: boolean | null;
};

export enum ActionTypes {
  SET_LOADING = 'setLoading',
  SET_ERROR = 'setError',
  SET_DATA = 'setData',
  SET_API_CALLING_CHECK = 'setAPICallingCheck',
  CLEAR_API_RES = 'clearAPIRes',
};

export type FetchHookReducerType<TResponseData> = {
    type: ActionTypes.SET_DATA;
    payload: TResponseData;
  } | {
    type: ActionTypes.SET_LOADING;
    payload: boolean;
  } | {
    type: ActionTypes.SET_API_CALLING_CHECK;
    payload: boolean;
  } | {
    type: ActionTypes.SET_ERROR;
    payload: string;
  } | {
    type: ActionTypes.CLEAR_API_RES;
  };
