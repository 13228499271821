import  * as yup from "yup";
export const CreateOrderSchema = yup.object({
  case_id: yup
    .string(),
    order_type: yup
    .string()
    .required(`can't be blank`),
    po_number: yup
    .string(),
    qty: yup
    .number()
    .typeError("must be a valid number"),
});
