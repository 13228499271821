import { ActionTypes, FetchHookStateType, FetchHookReducerType } from "./types";

export const fetchReducer = <TResponseData>() => (state: FetchHookStateType<TResponseData>, action: FetchHookReducerType<TResponseData>): FetchHookStateType<TResponseData> => {
  switch(action.type) {
    case ActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ActionTypes.SET_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case ActionTypes.SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.SET_API_CALLING_CHECK:
      return {
        ...state,
        shouldAPIBeCalled: action.payload,
      };
    case ActionTypes.CLEAR_API_RES:
      return {
        ...state,
        error: undefined,
        data: undefined,
      };
    default:
      return state;
  }
};
