import { FC, useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";

import FooterText from "../../components/footerText";
import HubInputField from "../../components/inputType";

import useFetch from "../../hooks/useFetch";
import { ToastContext } from "../../context/toastContext";
import { AuthContext } from "../../context/authContext";

import { CreateUserSchema } from "../../validation/createUser";
import { createUserAPIResponseType } from "./types";
import { APIEndpoints } from "../../types/apiTypes";
import { ToastTypes } from "../../types";



const HubCreateUser: FC = (): JSX.Element => {
  const { userData, updateUserData, destroySession,isManufacturer } = useContext(AuthContext);
  const { showToast } = useContext(ToastContext);
  const navigate = useNavigate();

  const { control, setValue, handleSubmit } = useForm({
    mode: 'all',
    resolver: yupResolver(CreateUserSchema),
  });

  useEffect(() => {
    if (userData) {
      setValue("firstname", userData.firstname);
      setValue("middlename", userData.middlename || "");
      setValue("lastname", userData.lastname);
      setValue("email", userData.email);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);





  const { loading: userLoading, data: updateUserApiResponse, mutate: addNewUser, error, removeErrorAndData } =
    useFetch<createUserAPIResponseType>(APIEndpoints.USER_UPDATE, {}, { userId: userData?.id });

  useEffect(() => {
    if (error) {
      const { code } = error as unknown as { code: number; error: string };
      if (code === 401) {
        showToast([`Please sign in or sign up to continue.`], ToastTypes.ERROR);
        destroySession();
        navigate('/users/sign_in');
      } else {
        showToast([error], ToastTypes.ERROR);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (updateUserApiResponse) {
      const { code, message, data } = updateUserApiResponse.status;

      if (code === 200) {
        if (data) {
          const { user } = data;

          if (user) {
            showToast([message], ToastTypes.SUCCESS);
            updateUserData(user);

            if (isManufacturer) {
              navigate('/statistics');
            } else {
              navigate('/cases');
            }
          }
        }
      } else if (code === 500) {
        showToast([message], ToastTypes.ERROR);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUserApiResponse]);

  const submitForm = async (values: unknown) => {
    if ((error && error.length > 0) || updateUserApiResponse) removeErrorAndData();
    addNewUser({ user: values });
  };





  return (
    <div className="container-fluid d-flex flex-fill auth-page bg-light overflow-auto">
      <div className="container">
        {/* {isManufacturer ? <AuthMaunfacturerSubHeader/> : <AuthSubHeader/>} */}
        <div className="card mt-4">
          <div className="card-header">
            <h4 className="mb-0">
              Initial Login and Password Change
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(submitForm)}>
              {
                <p className="fs-6">

                  It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.

                </p>
              }
              <div className="mb-3">
                <Controller
                  name="firstname"
                  control={control}
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <HubInputField
                      inputId="firstname"
                      isRowBased={true}
                      isRequired={false}
                      field={field}
                      error={error}
                      inputLabel="Firstname"
                      inputType="firstName"
                    />
                  )}
                />
              </div>

              <div className="mb-3">
                <Controller
                  name="middlename"
                  control={control}
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <HubInputField
                      inputId="middlename"
                      isRowBased={true}
                      isRequired={false}
                      field={field}
                      error={error}
                      inputLabel="Middlename"
                      inputType="middleName"
                    />
                  )}
                />
              </div>

              <div className="mb-3">
                <Controller
                  name="lastname"
                  control={control}
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <HubInputField
                      inputId="lastname"
                      isRowBased={true}
                      isRequired={false}
                      field={field}
                      error={error}
                      inputLabel="Lastname"
                      inputType="lastName"
                    />
                  )}
                />
              </div>

              <div className="mb-3">
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <HubInputField
                      inputId="email"
                      isRowBased={true}
                      isRequired={true}
                      field={field}
                      error={error}
                      inputLabel="Email"
                      inputType="email"
                    />
                  )}
                />
              </div>

              <div className="card p-4 mb-2 bg-light-subtle border-light-subtle">
                <h4 className="text-primary mb-0">Password</h4>
                <p>Password must be at least 10 characters long (no spaces) and include at least one uppercase
                  letter, at least three lowercase letters, one special character, and a number.</p>
                <div className="mb-3">
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    render={({ field, fieldState: { error } }) => (
                      <HubInputField
                        inputId="password"
                        isRowBased={true}
                        isRequired={true}
                        field={field}
                        error={error}
                        inputLabel="New Password"
                        inputType="password"
                      />
                    )}
                  />
                </div>
                <div className="">
                  <Controller
                    name="password_confirmation"
                    control={control}
                    defaultValue=""
                    render={({ field, fieldState: { error } }) => (
                      <HubInputField
                        inputId="password_confirmation"
                        isRowBased={true}
                        isRequired={true}
                        field={field}
                        error={error}
                        inputLabel="Confirm Password"
                        inputType="password"
                      />
                    )}
                  />
                </div>
              </div>

              {/* <div className="card mt-3">
                  <div className="card-header">
                    <h4 className="text-primary p-2 mb-0">By Logging in, You Agree to the Terms of the Below Business Associates Agreement.</h4>
                  </div>

                  <div className="card-body overflow-auto" style={{ maxHeight: "400px" }}>
                    <BAAContent />
                  </div>
                </div> */}

              <button disabled={userLoading} className="btn btn-primary d-flex" type="submit">
                {userLoading ? (
                  <i className="spinner-border text-white me-2" style={{ width: `24px`, height: `24px` }}></i>
                ) : null}
                <p className="col-auto mb-0">Save</p>
              </button>
            </form>
          </div>
        </div>
        <FooterText footerType="black" />
      </div>
    </div>
  );
};

export default HubCreateUser;


