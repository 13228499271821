import { FC } from "react";

import ShowPhoneNumber from "../showPhoneNumber";

import { PatientInformationProps } from "./types";

const ShowPatientInformation: FC<PatientInformationProps> = ({ getValues }): JSX.Element => {
  const { patient_attributes } = getValues();

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="m-0">Patient Information</h4>
      </div>

      <div className="card-body">
        <div className="row p-2">
          <div className="col-lg-6 col-md-6 col-12">
              <div className="row">
                <div className="col-4">
                  <label className="form-label">Title:</label>
                </div>
                <div className="col-8">
                  <p>{patient_attributes && patient_attributes.title ? patient_attributes.title : 'N/A'}</p>
                </div>
              </div>
            </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="row">
              <div className="col-4">
                <label className="form-label">Gender:</label>
              </div>
              <div className="col-8">
                <p>{patient_attributes && patient_attributes.gender ? patient_attributes.gender : 'N/A'}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row p-2">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="row">
              <div className="col-4">
                <label className="form-label">First Name:</label>
              </div>
              <div className="col-8">
                <p>{patient_attributes && patient_attributes.firstname ? patient_attributes.firstname : 'N/A'}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="row">
              <div className="col-4">
                <label className="form-label">Middle Name:</label>
              </div>
              <div className="col-8">
                <p>{patient_attributes && patient_attributes.middlename ? patient_attributes.middlename : 'N/A'}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row p-2">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="row">
              <div className="col-4">
                <label className="form-label">Last Name:</label>
              </div>
              <div className="col-8">
                <p>{patient_attributes && patient_attributes.lastname ? patient_attributes.lastname : 'N/A'}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="row">
              <div className="col-4">
                <label className="form-label">Email Address:</label>
              </div>
              <div className="col-8">
                <p>{patient_attributes && patient_attributes.patient_email ? patient_attributes.patient_email : 'N/A'}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row p-2">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="row">
              <div className="col-4">
                <label className="form-label">Date of Birth:</label>
              </div>
              <div className="col-8">
                <p>{patient_attributes && patient_attributes.dob ? patient_attributes.dob : 'N/A'}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="row">
              <div className="col-4">
                <label className="form-label">SSN:</label>
              </div>
              <div className="col-8">
                <p>{patient_attributes && patient_attributes.ssn ? patient_attributes.ssn : 'N/A'}</p>
                {/* <p>802-446-2118</p> */}
              </div>
            </div>
          </div>
        </div>
        <div className="row p-2">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="row">
              <div className="col-4">
                <label className="form-label">Best Time To Contact:</label>
              </div>
              <div className="col-8">
                <p>{patient_attributes && patient_attributes.best_time_to_contact ? patient_attributes.best_time_to_contact : 'N/A'}</p>
              </div>
            </div>
          </div>
        </div>
        {patient_attributes && patient_attributes.phone_numbers ? patient_attributes.phone_numbers.map((phoneNumber, index) => (
          <ShowPhoneNumber key={index} id={index+1} phone={phoneNumber} />
        )) : null}
      </div>
    </div>
  );
};

export default ShowPatientInformation;
