import { FC } from "react"
import { Link } from "react-router-dom";
import { providerProps } from "./types";
import { PROVIDER_HEADER } from "src/constants/manufacturer";

const Provider: FC<providerProps> = ({ providerList }): JSX.Element => {

  return (
    <div className="card p-0">
      <div className="d-flex flex-column flex-fill">
        <div className="card-header order-1 custom-card-header">
          <h4 className="mb-0 p-0 text-black text-center " >Providers</h4>
        </div>
        <div className="order-2 card-body p-0 overflow-y-auto" style={{ maxHeight: 350 }}>
          <table className="table table-responsive table-hover">
            <thead className="table-light thead-sticky">
              <tr>
              {PROVIDER_HEADER.map((tableHeader, index) => (
                    <th
                      key={index}
                      className="text-center top-0"
                      scope="col"
                      
                      // onClick={tableHeader.value !== '' ? () => changeIdOrder(tableHeader.value) : undefined}
                    >
                      <span
                        role={tableHeader.value === '' ? "textbox" : "button"}
                        className={
                          tableHeader.value === '' ?
                            'fw-bold fs-6 lh-base' :
                            'btn btn-link link-primary link-underline link-underline-opacity-0 link-offset-0-hover link-underline-opacity-75-hover p-0 fw-bold'
                        }
                      >
                        {tableHeader.label}
                        {/* {queryParams.sort_by === tableHeader.value ? (
                          <i className={`bi ${queryParams.direction === 'desc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'}`}></i>
                        ) : null} */}
                      </span>
                    </th>
                  ))}

              </tr>
            </thead>
            <tbody>
              {providerList && providerList.length>0 &&
                providerList.map((item,key) =>
                  <tr key={key} className="align-middle">
                    <td className="text-center">
                      <Link to={{ pathname: '/statistics'
                      }} target="_blank"
                       className="link-underline link-underline-opacity-0 link-offset-0-hover link-underline-opacity-75-hover text-center" >{item?.providers} 
                      </Link>
                    </td>
                    <td className="text-center"> {item?.commercial}</td>
                    <td className="text-center">{item?.medicare}</td>
                    <td className="text-center">{item?.medicare_advantage}</td>
                    <td className="text-center">{item?.unspecified}</td>
                    <td className="text-center">{item?.total_cases}</td>
                  </tr>
                )
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default Provider;