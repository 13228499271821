import { FC, useContext } from "react";
import { ToastContext } from "../../context/toastContext";
import { ToastTypes } from "src/types";

const HubToast: FC = (): JSX.Element => {
  const { isToastVisible, message, toastType } = useContext(ToastContext);

  const toastIcon = () => {
    switch(toastType) {
      case ToastTypes.INFO:
        return <i className="bi bi-info-circle-fill fs-5"></i>;

      case ToastTypes.WARNING:
        return <i className="bi bi-exclamation-triangle fs-5"></i>;

      case ToastTypes.ERROR:
        return <i className="bi bi-shield-fill-exclamation fs-5"></i>;

      case ToastTypes.SUCCESS:
        return <i className="bi bi-check-circle-fill fs-5"></i>;

      default:
        return <></>;
    }
  };

  return (
    <div className="toast-container top-0 end-0 position-fixed mt-3 me-3">
      {message.map((msg, index) => (
        <div
          key={index}
          className={`toast bg-${toastType} border-0 ${isToastVisible ? 'show' : ''}`}
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
          style={{ boxShadow: `2px 1px 9px rgba(0,0,0,0.75)` }}
        >
          <div className="toast-body text-white w-100 ms-3">
            <div className="row g-3 align-items-center">
              <div className="col-1 text-end">
                {toastIcon()}
              </div>

              <div className="col-10">
                <div dangerouslySetInnerHTML={{ __html: msg }} className="text-14"></div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default HubToast;
