import * as yup from "yup";

const spaceCheckYupValidation = (val?: string) => val ? (!/^\s*$/.test(val)) : false

export const AddProviderSchema = yup.object({
  npi: yup
    .string()
    .required(`NPI value is required to fetch provider details`)
    .min(10, `Valid NPI number is of 10 numbers`)
    .max(10, `Valid NPI number is of 10 numbers`)
    .test(
      "onlyNumbers", // type of the validator (should be unique)
      `NPI should only contain numbers`, // error message
      (value) => /^\d+$/.test(value)
    )
    .test(
      "noEOrSign", // type of the validator (should be unique)
      `NPI should only contain numbers`, // error message
      (value) => !/[eE+-]/.test(value.toString())
    ),
  ref_taxonomy_id: yup
    .string()
    .required("Speciality for doctor is required")
    .test("whiteSpaceRemoval", "Speciality should not contain only spaces", (val) => spaceCheckYupValidation(val)),
  firstname: yup
    .string()
    .required("First name for doctor is required")
    .max(35, `First name should only be 35 characters long`)
    .test("whiteSpaceRemoval", "First name should not contain only spaces", (val) => spaceCheckYupValidation(val)),
  lastname: yup
    .string()
    .required("Last name for doctor is required")
    .max(35, `Last name should only be 35 characters long`)
    .test("whiteSpaceRemoval", "Last name should not contain only spaces", (val) => spaceCheckYupValidation(val)),
  dea: yup
    .string()
    .when({
      is: (val:string) => val && val.length > 0,
      then: (schema) =>
        schema
          .min(9, "DEA should contain only 9 digits")
          .max(9, "DEA should contain only 9 digits")
          .test('CheckForInvalidChars', 'DEA is invalid', (val) => (val ? /^[a-z0-9]+$/i.test(val) : true)),
    }),
  sln: yup
    .string()
    .when({
      is: (val: string) => val && val.length > 0,
      then: (schema) => schema
        .test("whiteSpaceRemoval", "SLN should not contain only spaces", (val) => spaceCheckYupValidation(val)),
    }),
  tax_id: yup
    .string()
    .max(9, `Tax ID should contain only 9 digits`)
    .when({
      is: (val: string) => val && val.length > 0,
      then: (schema) => schema
        .test('CheckForInvalidChars', 'Tax ID is invalid', (val) => (val ? /^[a-z0-9]+$/i.test(val) : true)),
    }),
  ptan: yup
    .string()
    .when({
      is: (val: string) => val && val.length > 0,
      then: (schema) => schema
      .test("whiteSpaceRemoval", "PTAN should not contain only spaces", (val) => spaceCheckYupValidation(val)),
    })
});
