import { FC} from "react";
import CustomPieChart from "../customPieChart";
import { CaseVolumeProps } from "../caseVolume/types";



const Top10Payors: FC<CaseVolumeProps> = ({ StatisticsObjectType }): JSX.Element => {
  
  return (
    <div className="row">
      <div className="col">
        {StatisticsObjectType && <CustomPieChart title={"Top 10 payors"} pieChartObject={StatisticsObjectType.top_payors} />}
      </div>
      <div className="col">
        {StatisticsObjectType && <CustomPieChart title={"Cases By Submission Mode"} pieChartObject={StatisticsObjectType.mos} />}
      </div>
      <div className="col">
        {StatisticsObjectType && <CustomPieChart title={"Diagnoses"} pieChartObject={StatisticsObjectType.diagnosis} />}
      </div>
    </div>
  );
};

export default Top10Payors;
