export type MixingDetail = {
  case_id: Number;
  patient: string;
  mixer: string;
  site_of_care: string;
  confirmed: string;
  pt_submitted: string;
  instillation: string;
  deliver_by: string;
  pl3_status: string;
  mixing_status: string;
};

export type StatisticsApiResponse = {
  code: number;
  message: string;
  data: StatisticsObject;
};
export type statisticsErrorAndData = {
  code: number;
  error: string;
};
// export type CasedetailObjectType = {
//   global_patient_id: string;
//   bi_ct: string;
//   qty: number;
//   treated_at: string;
//   insurance_name: string;
//   outcome: string;
//   rx_cvg: string;
//   med_cvg: string;
//   rx_pa: string;
//   med_pa: string;
//   status_name: string;
//   retreatment_status: string;
//   practice_name: string;
//   practice_phone: string;
//   practice_fax: string;
//   doctor_name: string;
//   global_doctor_id: string;
//   case_id: number;
//   patient_id: string;
//   suffix: string;
//   dose: number;
//   tmnt: null | number;
//   ordered: string;
//   shipped: string;
//   treatment: string;
//   case_status: string;
//   color_idx: string;
//   provider: {
//     doc_name: string;
//     practice_name: string;
//     phone: number;
//     fax: number;
//   };
//   soc: {
//     practice_name: string;
//     practice_id: number;
//     phone: number;
//     fax: number;
//   };
//   case_detail: {
//     order: {
//       order_status: string;
//     };
//     mixing: {
//       mixing_status: string;
//     };
//     treatment: {
//       treatment_status: string;
//     };
//   };
// };
export type CasedetailObjectType = {
  global_patient_id: string;
  bi_ct: string;
  qty: number;
  received: string;
  completed: string;
  treated_at: string;
  insurance_name: string;
  payer: string;
  update: string[];
  outcome: string;
  rx_cvg: string;
  med_cvg: string;
  rx_pa: string;
  med_pa: string;
  status_name: string;
  retreatment_status: string;
  practice_name: string;
  practice_phone: string;
  practice_fax: string;
  doctor_name: string;
  global_doctor_id: string;
  case_id: number;
  patient_id: string;
  suffix: string;
  dose: number;
  tmnt: null | number;
  ordered: string;
  shipped: string;
  treatment: string;
  case_status: string;
  color_idx: string;
  provider: {
    doc_name: string;
    practice_name: string;
    phone: number;
    fax: number;
  };
  soc: {
    practice_name: string;
    practice_id: number;
    phone: number;
    fax: number;
  };
  case_detail: {
    order: {
      order_status: string;
    };
    mixing: {
      mixing_status: string;
    };
    treatment: {
      treatment_status: string;
    };
  };
};
export type CaseVolumeProps = {
  name: string;
  data?: {
    [key: string]: number;
  };
};
export type topPayorsMOSECRProps = {
  name: string | null;
  value: number;
  percentage: number;
};
export type CaseByMos = {
  [key: string]: number;
};
export type CaseByPayor = {
  [key: string]: number;
};
export type DoctorProps = {
  name: string | null;
  practice: string | null;
  state: string | null;
  phone: number | null;
};
export type NewDoctorsProps = {
  count: number;
  data: DoctorProps[];
};
export type PracticeProps = {
  name: string | null;
  state: string | null;
  phone: number | null;
};
export type NewPracticeProps = {
  count: number;
  data: PracticeProps[];
};
export type KAMSRBDSFAMSProps = {
  link_name: string;
  id: number;
  cases: string;
  ecr: string;
  role: string;
};
export type GeographicalStatisticsProps = {
  state: string;
  case_count: number;
  case_percentage?: string;
  case_id?: number;
};
export type GeographicalStatisticsObject = {
  state: string;
  case_count: number;
  case_percentage?: string;
  case_id?: number;
};
export type MixingDetailProps = {
  id: string;
  patient: string;
  mixer: string;
  site_of_care: string;
  confirmed: string | number;
  pt_submitted: string;
  instillation: string;
  deliver_by: string;
  pl_status: number;
  mixing_status: number;
  case_id: number;
  pl_status_color: number;
  mixing_status_color: number;
  tracking_number: string;
};
export type CaseDetailProps = {
  case_id: number;
  patient_id: string;
  suffix: string;
  dose: number;
  tmnt: null | number;
  ordered: string;
  shipped: string;
  treatment: string;
  case_status: string;
  color_idx: string;
  provider: {
    doc_name: string;
    practice_name: string;
    phone: number;
    fax: number;
  };
  soc: {
    practice_name: string;
    practice_id: number;
    phone: number;
    fax: number;
  };
  case_detail: {
    order: {
      order_status: string;
    };
    mixing: {
      mixing_status: string;
    };
    treatment: {
      treatment_status: string;
    };
  };
};
export type GeographicalStatisticsProps1 = {
  state: string;
  cases: string;
};
export type StatisticsObject = {
  case_volume: CaseVolumeProps[];
  received_cases: number;
  new_practices: NewPracticeProps;
  new_doctors: NewDoctorsProps;
  top_payors: topPayorsMOSECRProps[];
  mos: topPayorsMOSECRProps[];
  diagnosis: topPayorsMOSECRProps[];
  transfers: string;
  kams: KAMSRBDSFAMSProps[];
  rbds: KAMSRBDSFAMSProps[];
  fams: KAMSRBDSFAMSProps[];
  orders?: MixingDetailProps[];
  case_details?: CasedetailObjectType[];
  geographical_stats:
    | GeographicalStatisticsProps[]
    | GeographicalStatisticsProps1[];

  ecr: topPayorsMOSECRProps[];
};

export type UserListStatisticsApiResponse = {};
export type UserListStatisticsErrorResponse = {
  code: number;
  message: string;
};

export const StatisticsTerritories = {
  data: {
    case_volume: [
      {
        name: "Received",
        data: {
          "2024-09-10": 13,
          "2024-09-09": 11,
          "2024-09-06": 47,
          "2024-09-05": 9,
          "2024-09-04": 24,
          "2024-09-03": 49,
          "2024-09-02": 84,
          "2024-08-30": 30,
          "2024-08-29": 44,
          "2024-08-28": 38,
          "2024-08-27": 82,
          "2024-08-26": 60,
          "2024-08-23": 79,
          "2024-08-22": 52,
          "2024-08-21": 31,
          "2024-08-20": 21,
          "2024-08-19": 3,
          "2024-08-16": 27,
          "2024-08-13": 12,
          "2024-08-12": 26,
          "2024-08-11": 1,
        },
      },
      {
        name: "Completed",
        data: {
          "2024-09-10": 3,
          "2024-09-09": 4,
          "2024-09-06": 34,
          "2024-09-05": 4,
          "2024-09-04": 4,
          "2024-09-03": 9,
          "2024-09-02": 24,
          "2024-08-30": 7,
          "2024-08-29": 26,
          "2024-08-28": 3,
          "2024-08-27": 7,
          "2024-08-26": 17,
          "2024-08-23": 12,
          "2024-08-22": 9,
          "2024-08-21": 9,
          "2024-08-20": 16,
          "2024-08-19": 1,
          "2024-08-16": 3,
          "2024-08-15": 1,
          "2024-08-13": 1,
          "2024-08-12": 4,
        },
      },
      {
        name: "Sameday Completed",
        data: {
          "2024-09-10": 2,
          "2024-09-09": 4,
          "2024-09-06": 34,
          "2024-09-05": 4,
          "2024-09-04": 3,
          "2024-09-03": 9,
          "2024-09-02": 23,
          "2024-08-30": 7,
          "2024-08-29": 25,
          "2024-08-28": 2,
          "2024-08-27": 7,
          "2024-08-26": 16,
          "2024-08-23": 11,
          "2024-08-22": 7,
          "2024-08-21": 9,
          "2024-08-20": 16,
          "2024-08-19": 1,
          "2024-08-16": 3,
          "2024-08-13": 1,
          "2024-08-12": 4,
        },
      },
      {
        name: "PA Required",
        data: {
          "2024-09-10": 1,
          "2024-09-05": 4,
          "2024-09-04": 3,
          "2024-09-03": 4,
          "2024-09-02": 4,
          "2024-08-30": 4,
          "2024-08-29": 10,
          "2024-08-28": 2,
          "2024-08-27": 2,
          "2024-08-26": 7,
          "2024-08-23": 1,
          "2024-08-22": 3,
          "2024-08-20": 4,
          "2024-08-19": 1,
          "2024-08-13": 1,
          "2024-08-12": 5,
        },
      },
    ],
    received_cases: 743,
    new_practices: {
      data: [
        {
          name: "17 b practice ",
          state: "TX",
          phone: 2260465553,
        },
        {
          name: "4 COMMUNITY PHARMACY LLC",
          state: "NJ",
          phone: 8622579990,
        },
        {
          name: "A \u0026 R MEDICAL LLC",
          state: "RI",
          phone: 4019425625,
        },
        {
          name: "A.B. RECOVERY",
          state: "DC",
          phone: 2028772835,
        },
        {
          name: "A.B.C. RECOVERY",
          state: "MD",
          phone: 3016182273,
        },
        {
          name: "abc test practice",
          state: "CA",
          phone: 7073285631,
        },
        {
          name: "ADVANCED PAIN MANAGEMENT",
          state: "MO",
          phone: 9043612450,
        },
        {
          name: "AHMAD ABURASHED MD PC",
          state: "MI",
          phone: 2483544633,
        },
        {
          name: "ahmad1 practice",
          state: "CA",
          phone: 2345678901,
        },
        {
          name: "Dartmouth Hitchcock Medical Center-Lebanon",
          state: "DC",
          phone: 2028776333,
        },
        {
          name: "DartmouthH",
          state: "OK",
          phone: 7802735160,
        },
        {
          name: "ddddddddddsdsaddddddddddddddddddddddddddddasdasdddddddddddddddddddddddddddddddddddddasdasddddsdasdsadas sadsadsad",
          state: null,
          phone: 2321321312,
        },
        {
          name: "demo sohail ",
          state: "CA",
          phone: 8056775210,
        },
        {
          name: "Doctor Hospital",
          state: "MN",
          phone: 6128736963,
        },
        {
          name: "Health Bridge Hospital",
          state: "PJ",
          phone: 6677889912,
        },
        {
          name: "Health Bridge Hospital",
          state: "PJ",
          phone: 6677889912,
        },
        {
          name: "Health Bridge Hospital",
          state: "PJ",
          phone: 6677889912,
        },
        {
          name: "JH ORG 07302014",
          state: "VA",
          phone: 3243243434,
        },
        {
          name: "MADDYS MEDICAL LLC",
          state: "RI",
          phone: 4014515636,
        },
        {
          name: "manufacturer New Created",
          state: "WY",
          phone: 21872344,
        },
        {
          name: "New Practice",
          state: null,
          phone: null,
        },
        {
          name: "Rehmat Hospital",
          state: "PJ",
          phone: 218789876,
        },
        {
          name: "Shalamar Hospital",
          state: "TX",
          phone: 42,
        },
        {
          name: "Shan ur REhman",
          state: "CA",
          phone: 6315873367,
        },
        {
          name: "test avanos 3/9",
          state: "IN",
          phone: 6315873366,
        },
        {
          name: "Travis Russo",
          state: "Ad",
          phone: 5671943342,
        },
        {
          name: "Waqas A HAMIDI MD INC.",
          state: "CA",
          phone: 9213385067,
        },
      ],
      count: 27,
    },
    new_doctors: {
      data: [
        {
          name: "Colin Cynthia Terry Oconnor",
          practice: "Travis Russo",
          state: "Ad",
          phone: 5671943342,
        },
        {
          name: "Ahmad Nouman",
          practice: "DartmouthH",
          state: "OK",
          phone: 7802735160,
        },
        {
          name: "Muhammad Aftab",
          practice: "DartmouthH",
          state: "OK",
          phone: 7802735160,
        },
        {
          name: "Ali Aaron",
          practice: "abc test practice",
          state: "CA",
          phone: 7073285631,
        },
        {
          name: "Ahmad A Hamidi",
          practice: "National Hospital",
          state: "NY",
          phone: 65432134,
        },
        {
          name: "Nauman Nauman Nauman",
          practice: "ahmad1 practice",
          state: "CA",
          phone: 2345678901,
        },
        {
          name: "Dr. Abdul Sattar",
          practice: "National Hospital",
          state: "NY",
          phone: 65432134,
        },
        {
          name: "Jon Aagaard",
          practice: "National Hospital",
          state: "NY",
          phone: 65432134,
        },
        {
          name: "Muhammad Aamir",
          practice: "Aa Urology Outpatient Surgery Center",
          state: "MD",
          phone: 4102668049,
        },
        {
          name: "Reem Hamad",
          practice: "Demo Practice 1",
          state: "NY",
          phone: 8552721128,
        },
        {
          name: "U U",
          practice: "500 Market St. STE 101",
          state: "PA",
          phone: null,
        },
        {
          name: "Saud Daud Saud",
          practice: "ahmad1 practice",
          state: "CA",
          phone: 2345678901,
        },
        {
          name: "Qasim Chaudhry",
          practice: "ahmad1 practice",
          state: "CA",
          phone: 2345678901,
        },
        {
          name: "Qasim Anjarwala",
          practice: "ahmad1 practice",
          state: "CA",
          phone: 2345678901,
        },
        {
          name: "Debbie Harris",
          practice: "100 MAIN MEDICAL ASSOCIATES",
          state: "NY",
          phone: 9735230317,
        },
        {
          name: "Shane Abbott",
          practice: "National Hospital",
          state: "NY",
          phone: 65432134,
        },
        {
          name: "New New",
          practice: "National Hospital",
          state: "NY",
          phone: 65432134,
        },
        {
          name: "Ahmad Abd Raffur",
          practice: "dartmouth",
          state: "FL",
          phone: 3052436596,
        },
        {
          name: "New Doctor",
          practice: null,
          state: null,
          phone: null,
        },
        {
          name: "Saudia Aguilar",
          practice: "dartmouth",
          state: "FL",
          phone: 3052436596,
        },
        {
          name: "Christina Test Teimouri",
          practice: "Dartmouth Hitchcock Medical Center-Lebanon",
          state: "AZ",
          phone: 6022628900,
        },
        {
          name: "Dr. Ameer Taimur",
          practice: "National Hospital",
          state: "NY",
          phone: 65432134,
        },
        {
          name: "Ahamd Nauman",
          practice: "ahmad1 practice",
          state: "CA",
          phone: 2345678901,
        },
        {
          name: "Hammad Ali",
          practice: "ahmad1 practice",
          state: "CA",
          phone: 2345678901,
        },
        {
          name: "Asad Iqbal",
          practice: "ahmad1 practice",
          state: "CA",
          phone: 2345678901,
        },
        {
          name: "Jason De Rulo",
          practice: "Morristown Medical Center",
          state: "NJ",
          phone: 6179597725,
        },
        {
          name: "Qasim Nauman",
          practice: "ahmad1 practice",
          state: "CA",
          phone: 2345678901,
        },
        {
          name: "Mahmoud Khaled Abdelghaffar",
          practice: "A.B.C. RECOVERY",
          state: "MD",
          phone: 3016182273,
        },
        {
          name: "Ahmad Ghassan Abazid",
          practice: "A.B.C. RECOVERY",
          state: "MD",
          phone: 3016182273,
        },
        {
          name: "Sohail Test Abdi Moradi",
          practice: "demo sohail ",
          state: "CA",
          phone: 8056775210,
        },
        {
          name: "Umer Arif Esbhani",
          practice: "A.B. RECOVERY",
          state: "DC",
          phone: 2028772835,
        },
        {
          name: "Abdul Rehman Tayyab Tahir",
          practice: "test avanos 3/9",
          state: "IN",
          phone: 6315873366,
        },
        {
          name: "Saud Abaalkhail",
          practice: "Doctor Hospital",
          state: "MN",
          phone: 6128736963,
        },
        {
          name: "Muhammad Aijaz A. Sattar",
          practice: "17 b practice ",
          state: "TX",
          phone: 2260465553,
        },
        {
          name: "Americo Testa",
          practice: "A \u0026 R MEDICAL LLC",
          state: "RI",
          phone: 4019425625,
        },
        {
          name: "Test Org A",
          practice: "JH ORG 07302014",
          state: "VA",
          phone: 3243243434,
        },
        {
          name: "Americo Testa",
          practice: "MADDYS MEDICAL LLC",
          state: "RI",
          phone: 4014515636,
        },
        {
          name: "Julie Saud Underwood",
          practice: "ADVANCED PAIN MANAGEMENT",
          state: "MO",
          phone: 9043612450,
        },
        {
          name: "Manufacturer Doctor New",
          practice: "manufacturer New Created",
          state: "WY",
          phone: 21872344,
        },
        {
          name: "Shan Ur R Ehman",
          practice: "100 MAIN MEDICAL ASSOCIATES",
          state: "NY",
          phone: 9735230317,
        },
        {
          name: "Khalid M Bader",
          practice: "4 COMMUNITY PHARMACY LLC",
          state: "NJ",
          phone: 8622579990,
        },
        {
          name: "Ahmad Aalam",
          practice: "4 COMMUNITY PHARMACY LLC",
          state: "NJ",
          phone: 8622579990,
        },
        {
          name: "Test Test Avanos",
          practice: "100 MAIN MEDICAL ASSOCIATES",
          state: "NY",
          phone: 9735230317,
        },
        {
          name: "New Middlena Middlena Lastn",
          practice: "New Practice",
          state: null,
          phone: null,
        },
        {
          name: "Nicki Minaj",
          practice: "100 MAIN MEDICAL ASSOCIATES",
          state: "NY",
          phone: 9735230317,
        },
      ],
      count: 45,
    },
    transfers: 8.34,
    top_payors: [
      {
        name: null,
        value: 606,
        percentage: 90.18,
      },
      {
        name: "MOBILE",
        value: 10,
        percentage: 1.49,
      },
      {
        name: "JUBILLE",
        value: 10,
        percentage: 1.49,
      },
      {
        name: "PERX1",
        value: 10,
        percentage: 1.49,
      },
      {
        name: "ABC TEST",
        value: 8,
        percentage: 1.19,
      },
      {
        name: "PAPER",
        value: 7,
        percentage: 1.04,
      },
      {
        name: "BLUE CROSS",
        value: 6,
        percentage: 0.89,
      },
      {
        name: "WERX2",
        value: 5,
        percentage: 0.74,
      },
      {
        name: "DRAKE RODRIGUEZ",
        value: 5,
        percentage: 0.74,
      },
      {
        name: "MELVIN SHAW",
        value: 5,
        percentage: 0.74,
      },
    ],
    ecr: [
      {
        name: "No Action",
        value: 669,
        percentage: 90.04,
      },
      {
        name: "Transferred",
        value: 59,
        percentage: 7.94,
      },
      {
        name: "Not treated",
        value: 13,
        percentage: 1.75,
      },
      {
        name: "Treated",
        value: 2,
        percentage: 0.27,
      },
    ],
    mos: [
      {
        name: "online",
        value: 666,
        percentage: 89.64,
      },
      {
        name: "fax",
        value: 75,
        percentage: 10.09,
      },
      {
        name: "erx",
        value: 2,
        percentage: 0.27,
      },
    ],
    diagnosis: [
      {
        name: "A02.0 - Salmonella enteritis",
        value: 53,
        percentage: 17.67,
      },
      {
        name: "A01.05 - Typhoid osteomyelitis",
        value: 38,
        percentage: 12.67,
      },
      {
        name: "1 - Certain infectious and parasitic diseases (A00-B99)",
        value: 32,
        percentage: 10.67,
      },
      {
        name: "A15 - Respiratory tuberculosis",
        value: 31,
        percentage: 10.33,
      },
      {
        name: "A04 - Other bacterial intestinal infections",
        value: 30,
        percentage: 10.0,
      },
      {
        name: null,
        value: 27,
        percentage: 9.0,
      },
      {
        name: "A15.7 - Primary respiratory tuberculosis",
        value: 27,
        percentage: 9.0,
      },
      {
        name: "A54.33 - Gonococcal keratitis",
        value: 25,
        percentage: 8.33,
      },
      {
        name: "A06 - Amebiasis",
        value: 19,
        percentage: 6.33,
      },
      {
        name: "A05.4 - Foodborne Bacillus cereus intoxication",
        value: 18,
        percentage: 6.0,
      },
    ],
    kams: {
      data: [
        {
          providers: " ",
          commercial: " ",
          medicare: " ",
          medicare_advantage: " ",
          unspecified: " ",
          total_cases: " ",
        },
      ],
      total: "13 (1.75)",
    },
    rbds: {
      data: [],
      total: "13 (1.75)",
    },
    fams: {
      data: [],
      total: "13 (1.75)",
    },
    geographical_stats: [
      {
        state: "AL",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "AK",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "AZ",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "AR",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "CA",
        case_count: 229,
        case_percentage: "30.82%",
      },
      {
        state: "CO",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "CT",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "DC",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "DE",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "FL",
        case_count: 182,
        case_percentage: "24.5%",
      },
      {
        state: "FM",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "GA",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "GU",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "HI",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "ID",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "IL",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "IN",
        case_count: 1,
        case_percentage: "0.13%",
      },
      {
        state: "IA",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "KS",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "KY",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "LA",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "ME",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "MD",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "MA",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "MH",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "MI",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "MN",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "MS",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "MO",
        case_count: 5,
        case_percentage: "0.67%",
      },
      {
        state: "MP",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "MT",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "NE",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "NV",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "NH",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "NJ",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "NM",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "NY",
        case_count: 108,
        case_percentage: "14.54%",
      },
      {
        state: "NC",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "ND",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "OH",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "OK",
        case_count: 61,
        case_percentage: "8.21%",
      },
      {
        state: "OR",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "PA",
        case_count: 2,
        case_percentage: "0.27%",
      },
      {
        state: "PW",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "PR",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "RI",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "SC",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "SD",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "TN",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "TX",
        case_count: 27,
        case_percentage: "3.63%",
      },
      {
        state: "UT",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "VT",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "VI",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "VA",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "WA",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "WV",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "WI",
        case_count: 0,
        case_percentage: "0.0%",
      },
      {
        state: "WY",
        case_count: 125,
        case_percentage: "16.82%",
      },
    ],
    case_details: [
      {
        global_patient_id: "string",
        bi_ct: 1,
        qty: 4,
        received: "09/09/2024	",
        completed: "09/09/2024	",
        treated_at: "09/09/2024	",
        insurance_name: "string",
        payer: "united healthcare",
        update: ["Treatment Date"],
        outcome: "Pending",
        rx_cvg: "Y",
        med_cvg: "N",
        rx_pa: "N",
        med_pa: "N",
        status_name: "string",
        retreatment_status: "string",
        practice_name: "string",
        practice_phone: "string",
        practice_fax: "string",
        doctor_name: "string",
        global_doctor_id: "string",
        case_id: 190340,
        patient_id: 45737,
        suffix: "string",
        dose: "number",
        tmnt: "number",
        ordered: "string",
        shipped: "string",
        treatment: "09/09/2024",
        case_status: "Pending Benefits",
        color_idx: 2,
        provider: {
          doc_name: "HERMES, JAYNE",
          practice_name: "hermes health care",
          phone: "3132423234",
          fax: "32453453223",
          address: "3015 n penstemon WICHITA, KS 67226",
        },
        soc: {
          practice_name: "string",
          practice_id: "number",
          phone: "number",
          fax: "number",
        },
        case_detail: {
          order: {
            order_status: "string",
          },
          mixing: {
            mixing_status: "string",
          },
          treatment: {
            treatment_status: "string",
          },
        },
      },
      {
        global_patient_id: "string",
        bi_ct: 1,
        qty: 4,
        received: "09/09/2024	",
        completed: "09/09/2024	",
        treated_at: "09/09/2024	",
        insurance_name: "string",
        payer: "BCBS SC",
        update: ["Treatment Date"],
        outcome: "Covered",
        rx_cvg: "N",
        med_cvg: "Y",
        rx_pa: "N",
        med_pa: "Pending",
        status_name: "string",
        retreatment_status: "string",
        practice_name: "string",
        practice_phone: "string",
        practice_fax: "string",
        doctor_name: "string",
        global_doctor_id: "string",
        case_id: 190273,
        patient_id: 45316,
        suffix: "string",
        dose: "number",
        tmnt: "number",
        ordered: "string",
        shipped: "string",
        treatment: "09/09/2024",
        case_status: "Med Coverage Available, Med PA Pending",
        color_idx: 5,
        provider: {
          doc_name: "THEOHARIDIS, AKILIS",
          practice_name: "trumen medical centre",
          phone: "3132423234",
          fax: "32453453223",
          address: "3015 n penstemon WICHITA, KS 67226",
        },
        soc: {
          practice_name: "string",
          practice_id: "number",
          phone: "number",
          fax: "number",
        },
        case_detail: {
          order: {
            order_status: "string",
          },
          mixing: {
            mixing_status: "string",
          },
          treatment: {
            treatment_status: "string",
          },
        },
      },
      {
        global_patient_id: "string",
        bi_ct: 1,
        qty: 4,
        received: "09/09/2024	",
        completed: "09/09/2024	",
        treated_at: "09/09/2024	",
        insurance_name: "string",
        payer: "Anthem BCBS CA",
        update: ["Treatment Date"],
        outcome: "Covered",
        rx_cvg: "Y",
        med_cvg: "Y",
        rx_pa: "Y",
        med_pa: "N",
        status_name: "string",
        retreatment_status: "string",
        practice_name: "string",
        practice_phone: "string",
        practice_fax: "string",
        doctor_name: "string",
        global_doctor_id: "string",
        case_id: 190104,
        patient_id: 45617,
        suffix: "string",
        dose: "number",
        tmnt: "number",
        ordered: "string",
        shipped: "string",
        treatment: "09/09/2024",
        case_status: "Med and Rx Coverage Available, Rx PA Required",
        color_idx: 2,
        provider: {
          doc_name: "HERMES, JAYNE",
          practice_name: "hermes health care",
          phone: "3132423234",
          fax: "32453453223",
          address: "3015 n penstemon WICHITA, KS 67226",
        },
        soc: {
          practice_name: "string",
          practice_id: "number",
          phone: "number",
          fax: "number",
        },
        case_detail: {
          order: {
            order_status: "string",
          },
          mixing: {
            mixing_status: "string",
          },
          treatment: {
            treatment_status: "string",
          },
        },
      },
      {
        global_patient_id: "string",
        bi_ct: 1,
        qty: 4,
        received: "09/09/2024	",
        completed: "09/09/2024	",
        treated_at: "09/09/2024	",
        insurance_name: "string",
        payer: "ambetter",
        update: ["Treatment Date"],
        outcome: "Covered",
        rx_cvg: "Y",
        med_cvg: "Y",
        rx_pa: "N",
        med_pa: "N",
        status_name: "string",
        retreatment_status: "string",
        practice_name: "string",
        practice_phone: "string",
        practice_fax: "string",
        doctor_name: "string",
        global_doctor_id: "string",
        case_id: 189853,
        patient_id: 45467,
        suffix: "string",
        dose: "number",
        tmnt: "number",
        ordered: "string",
        shipped: "string",
        treatment: "09/09/2024",
        case_status: "Med and Rx Coverage Available",
        color_idx: 2,
        provider: {
          doc_name: "HAERER, BRANDI",
          practice_name: "brandi health care",
          phone: "3132423234",
          fax: "32453453223",
          address: "3015 n penstemon WICHITA, KS 67226",
        },
        soc: {
          practice_name: "string",
          practice_id: "number",
          phone: "number",
          fax: "number",
        },
        case_detail: {
          order: {
            order_status: "string",
          },
          mixing: {
            mixing_status: "string",
          },
          treatment: {
            treatment_status: "string",
          },
        },
      },
      {
        global_patient_id: "string",
        bi_ct: 1,
        qty: 4,
        received: "09/09/2024	",
        completed: "09/09/2024	",
        treated_at: "09/09/2024	",
        insurance_name: "string",
        payer: "united healthcare",
        update: ["Treatment Date"],
        outcome: "Pending",
        rx_cvg: "Y",
        med_cvg: "N",
        rx_pa: "N",
        med_pa: "N",
        status_name: "string",
        retreatment_status: "string",
        practice_name: "string",
        practice_phone: "string",
        practice_fax: "string",
        doctor_name: "string",
        global_doctor_id: "string",
        case_id: 190340,
        patient_id: 45737,
        suffix: "string",
        dose: "number",
        tmnt: "number",
        ordered: "string",
        shipped: "string",
        treatment: "09/09/2024",
        case_status: "Pending Benefits",
        color_idx: 2,
        provider: {
          doc_name: "HERMES, JAYNE",
          practice_name: "hermes health care",
          phone: "3132423234",
          fax: "32453453223",
          address: "3015 n penstemon WICHITA, KS 67226",
        },
        soc: {
          practice_name: "string",
          practice_id: "number",
          phone: "number",
          fax: "number",
        },
        case_detail: {
          order: {
            order_status: "string",
          },
          mixing: {
            mixing_status: "string",
          },
          treatment: {
            treatment_status: "string",
          },
        },
      },
      {
        global_patient_id: "string",
        bi_ct: 1,
        qty: 4,
        received: "09/09/2024	",
        completed: "09/09/2024	",
        treated_at: "09/09/2024	",
        insurance_name: "string",
        payer: "BCBS SC",
        update: ["Treatment Date"],
        outcome: "Covered",
        rx_cvg: "N",
        med_cvg: "Y",
        rx_pa: "N",
        med_pa: "Pending",
        status_name: "string",
        retreatment_status: "string",
        practice_name: "string",
        practice_phone: "string",
        practice_fax: "string",
        doctor_name: "string",
        global_doctor_id: "string",
        case_id: 190273,
        patient_id: 45316,
        suffix: "string",
        dose: "number",
        tmnt: "number",
        ordered: "string",
        shipped: "string",
        treatment: "09/09/2024",
        case_status: "Med Coverage Available, Med PA Pending",
        color_idx: 5,
        provider: {
          doc_name: "THEOHARIDIS, AKILIS",
          practice_name: "trumen medical centre",
          phone: "3132423234",
          fax: "32453453223",
          address: "3015 n penstemon WICHITA, KS 67226",
        },
        soc: {
          practice_name: "string",
          practice_id: "number",
          phone: "number",
          fax: "number",
        },
        case_detail: {
          order: {
            order_status: "string",
          },
          mixing: {
            mixing_status: "string",
          },
          treatment: {
            treatment_status: "string",
          },
        },
      },
      {
        global_patient_id: "string",
        bi_ct: 1,
        qty: 4,
        received: "09/09/2024	",
        completed: "09/09/2024	",
        treated_at: "09/09/2024	",
        insurance_name: "string",
        payer: "Anthem BCBS CA",
        update: ["Treatment Date"],
        outcome: "Covered",
        rx_cvg: "Y",
        med_cvg: "Y",
        rx_pa: "Y",
        med_pa: "N",
        status_name: "string",
        retreatment_status: "string",
        practice_name: "string",
        practice_phone: "string",
        practice_fax: "string",
        doctor_name: "string",
        global_doctor_id: "string",
        case_id: 190104,
        patient_id: 45617,
        suffix: "string",
        dose: "number",
        tmnt: "number",
        ordered: "string",
        shipped: "string",
        treatment: "09/09/2024",
        case_status: "Med and Rx Coverage Available, Rx PA Required",
        color_idx: 2,
        provider: {
          doc_name: "HERMES, JAYNE",
          practice_name: "hermes health care",
          phone: "3132423234",
          fax: "32453453223",
          address: "3015 n penstemon WICHITA, KS 67226",
        },
        soc: {
          practice_name: "string",
          practice_id: "number",
          phone: "number",
          fax: "number",
        },
        case_detail: {
          order: {
            order_status: "string",
          },
          mixing: {
            mixing_status: "string",
          },
          treatment: {
            treatment_status: "string",
          },
        },
      },
      {
        global_patient_id: "string",
        bi_ct: 1,
        qty: 4,
        received: "09/09/2024	",
        completed: "09/09/2024	",
        treated_at: "09/09/2024	",
        insurance_name: "string",
        payer: "ambetter",
        update: ["Treatment Date"],
        outcome: "Covered",
        rx_cvg: "Y",
        med_cvg: "Y",
        rx_pa: "N",
        med_pa: "N",
        status_name: "string",
        retreatment_status: "string",
        practice_name: "string",
        practice_phone: "string",
        practice_fax: "string",
        doctor_name: "string",
        global_doctor_id: "string",
        case_id: 189853,
        patient_id: 45467,
        suffix: "string",
        dose: "number",
        tmnt: "number",
        ordered: "string",
        shipped: "string",
        treatment: "09/09/2024",
        case_status: "Med and Rx Coverage Available",
        color_idx: 2,
        provider: {
          doc_name: "HAERER, BRANDI",
          practice_name: "brandi health care",
          phone: "3132423234",
          fax: "32453453223",
          address: "3015 n penstemon WICHITA, KS 67226",
        },
        soc: {
          practice_name: "string",
          practice_id: "number",
          phone: "number",
          fax: "number",
        },
        case_detail: {
          order: {
            order_status: "string",
          },
          mixing: {
            mixing_status: "string",
          },
          treatment: {
            treatment_status: "string",
          },
        },
      },
      {
        global_patient_id: "string",
        bi_ct: 1,
        qty: 4,
        received: "09/09/2024	",
        completed: "09/09/2024	",
        treated_at: "09/09/2024	",
        insurance_name: "string",
        payer: "united healthcare",
        update: ["Treatment Date"],
        outcome: "Pending",
        rx_cvg: "Y",
        med_cvg: "N",
        rx_pa: "N",
        med_pa: "N",
        status_name: "string",
        retreatment_status: "string",
        practice_name: "string",
        practice_phone: "string",
        practice_fax: "string",
        doctor_name: "string",
        global_doctor_id: "string",
        case_id: 190340,
        patient_id: 45737,
        suffix: "string",
        dose: "number",
        tmnt: "number",
        ordered: "string",
        shipped: "string",
        treatment: "09/09/2024",
        case_status: "Pending Benefits",
        color_idx: 2,
        provider: {
          doc_name: "HERMES, JAYNE",
          practice_name: "hermes health care",
          phone: "3132423234",
          fax: "32453453223",
          address: "3015 n penstemon WICHITA, KS 67226",
        },
        soc: {
          practice_name: "string",
          practice_id: "number",
          phone: "number",
          fax: "number",
        },
        case_detail: {
          order: {
            order_status: "string",
          },
          mixing: {
            mixing_status: "string",
          },
          treatment: {
            treatment_status: "string",
          },
        },
      },
      {
        global_patient_id: "string",
        bi_ct: 1,
        qty: 4,
        received: "09/09/2024	",
        completed: "09/09/2024	",
        treated_at: "09/09/2024	",
        insurance_name: "string",
        payer: "BCBS SC",
        update: ["Treatment Date"],
        outcome: "Covered",
        rx_cvg: "N",
        med_cvg: "Y",
        rx_pa: "N",
        med_pa: "Pending",
        status_name: "string",
        retreatment_status: "string",
        practice_name: "string",
        practice_phone: "string",
        practice_fax: "string",
        doctor_name: "string",
        global_doctor_id: "string",
        case_id: 190273,
        patient_id: 45316,
        suffix: "string",
        dose: "number",
        tmnt: "number",
        ordered: "string",
        shipped: "string",
        treatment: "09/09/2024",
        case_status: "Med Coverage Available, Med PA Pending",
        color_idx: 5,
        provider: {
          doc_name: "THEOHARIDIS, AKILIS",
          practice_name: "trumen medical centre",
          phone: "3132423234",
          fax: "32453453223",
          address: "3015 n penstemon WICHITA, KS 67226",
        },
        soc: {
          practice_name: "string",
          practice_id: "number",
          phone: "number",
          fax: "number",
        },
        case_detail: {
          order: {
            order_status: "string",
          },
          mixing: {
            mixing_status: "string",
          },
          treatment: {
            treatment_status: "string",
          },
        },
      },
      {
        global_patient_id: "string",
        bi_ct: 1,
        qty: 4,
        received: "09/09/2024	",
        completed: "09/09/2024	",
        treated_at: "09/09/2024	",
        insurance_name: "string",
        payer: "Anthem BCBS CA",
        update: ["Treatment Date"],
        outcome: "Covered",
        rx_cvg: "Y",
        med_cvg: "Y",
        rx_pa: "Y",
        med_pa: "N",
        status_name: "string",
        retreatment_status: "string",
        practice_name: "string",
        practice_phone: "string",
        practice_fax: "string",
        doctor_name: "string",
        global_doctor_id: "string",
        case_id: 190104,
        patient_id: 45617,
        suffix: "string",
        dose: "number",
        tmnt: "number",
        ordered: "string",
        shipped: "string",
        treatment: "09/09/2024",
        case_status: "Med and Rx Coverage Available, Rx PA Required",
        color_idx: 2,
        provider: {
          doc_name: "HERMES, JAYNE",
          practice_name: "hermes health care",
          phone: "3132423234",
          fax: "32453453223",
          address: "3015 n penstemon WICHITA, KS 67226",
        },
        soc: {
          practice_name: "string",
          practice_id: "number",
          phone: "number",
          fax: "number",
        },
        case_detail: {
          order: {
            order_status: "string",
          },
          mixing: {
            mixing_status: "string",
          },
          treatment: {
            treatment_status: "string",
          },
        },
      },
      {
        global_patient_id: "string",
        bi_ct: 1,
        qty: 4,
        received: "09/09/2024	",
        completed: "09/09/2024	",
        treated_at: "09/09/2024	",
        insurance_name: "string",
        payer: "ambetter",
        update: ["Treatment Date"],
        outcome: "Covered",
        rx_cvg: "Y",
        med_cvg: "Y",
        rx_pa: "N",
        med_pa: "N",
        status_name: "string",
        retreatment_status: "string",
        practice_name: "string",
        practice_phone: "string",
        practice_fax: "string",
        doctor_name: "string",
        global_doctor_id: "string",
        case_id: 189853,
        patient_id: 45467,
        suffix: "string",
        dose: "number",
        tmnt: "number",
        ordered: "string",
        shipped: "string",
        treatment: "09/09/2024",
        case_status: "Med and Rx Coverage Available",
        color_idx: 2,
        provider: {
          doc_name: "HAERER, BRANDI",
          practice_name: "brandi health care",
          phone: "3132423234",
          fax: "32453453223",
          address: "3015 n penstemon WICHITA, KS 67226",
        },
        soc: {
          practice_name: "string",
          practice_id: "number",
          phone: "number",
          fax: "number",
        },
        case_detail: {
          order: {
            order_status: "string",
          },
          mixing: {
            mixing_status: "string",
          },
          treatment: {
            treatment_status: "string",
          },
        },
      },
      {
        global_patient_id: "string",
        bi_ct: 1,
        qty: 4,
        received: "09/09/2024	",
        completed: "09/09/2024	",
        treated_at: "09/09/2024	",
        insurance_name: "string",
        payer: "united healthcare",
        update: ["Treatment Date"],
        outcome: "Pending",
        rx_cvg: "Y",
        med_cvg: "N",
        rx_pa: "N",
        med_pa: "N",
        status_name: "string",
        retreatment_status: "string",
        practice_name: "string",
        practice_phone: "string",
        practice_fax: "string",
        doctor_name: "string",
        global_doctor_id: "string",
        case_id: 190340,
        patient_id: 45737,
        suffix: "string",
        dose: "number",
        tmnt: "number",
        ordered: "string",
        shipped: "string",
        treatment: "09/09/2024",
        case_status: "Pending Benefits",
        color_idx: 2,
        provider: {
          doc_name: "HERMES, JAYNE",
          practice_name: "hermes health care",
          phone: "3132423234",
          fax: "32453453223",
          address: "3015 n penstemon WICHITA, KS 67226",
        },
        soc: {
          practice_name: "string",
          practice_id: "number",
          phone: "number",
          fax: "number",
        },
        case_detail: {
          order: {
            order_status: "string",
          },
          mixing: {
            mixing_status: "string",
          },
          treatment: {
            treatment_status: "string",
          },
        },
      },
      {
        global_patient_id: "string",
        bi_ct: 1,
        qty: 4,
        received: "09/09/2024	",
        completed: "09/09/2024	",
        treated_at: "09/09/2024	",
        insurance_name: "string",
        payer: "BCBS SC",
        update: ["Treatment Date"],
        outcome: "Covered",
        rx_cvg: "N",
        med_cvg: "Y",
        rx_pa: "N",
        med_pa: "Pending",
        status_name: "string",
        retreatment_status: "string",
        practice_name: "string",
        practice_phone: "string",
        practice_fax: "string",
        doctor_name: "string",
        global_doctor_id: "string",
        case_id: 190273,
        patient_id: 45316,
        suffix: "string",
        dose: "number",
        tmnt: "number",
        ordered: "string",
        shipped: "string",
        treatment: "09/09/2024",
        case_status: "Med Coverage Available, Med PA Pending",
        color_idx: 5,
        provider: {
          doc_name: "THEOHARIDIS, AKILIS",
          practice_name: "trumen medical centre",
          phone: "3132423234",
          fax: "32453453223",
          address: "3015 n penstemon WICHITA, KS 67226",
        },
        soc: {
          practice_name: "string",
          practice_id: "number",
          phone: "number",
          fax: "number",
        },
        case_detail: {
          order: {
            order_status: "string",
          },
          mixing: {
            mixing_status: "string",
          },
          treatment: {
            treatment_status: "string",
          },
        },
      },
      {
        global_patient_id: "string",
        bi_ct: 1,
        qty: 4,
        received: "09/09/2024	",
        completed: "09/09/2024	",
        treated_at: "09/09/2024	",
        insurance_name: "string",
        payer: "Anthem BCBS CA",
        update: ["Treatment Date"],
        outcome: "Covered",
        rx_cvg: "Y",
        med_cvg: "Y",
        rx_pa: "Y",
        med_pa: "N",
        status_name: "string",
        retreatment_status: "string",
        practice_name: "string",
        practice_phone: "string",
        practice_fax: "string",
        doctor_name: "string",
        global_doctor_id: "string",
        case_id: 190104,
        patient_id: 45617,
        suffix: "string",
        dose: "number",
        tmnt: "number",
        ordered: "string",
        shipped: "string",
        treatment: "09/09/2024",
        case_status: "Med and Rx Coverage Available, Rx PA Required",
        color_idx: 2,
        provider: {
          doc_name: "HERMES, JAYNE",
          practice_name: "hermes health care",
          phone: "3132423234",
          fax: "32453453223",
          address: "3015 n penstemon WICHITA, KS 67226",
        },
        soc: {
          practice_name: "string",
          practice_id: "number",
          phone: "number",
          fax: "number",
        },
        case_detail: {
          order: {
            order_status: "string",
          },
          mixing: {
            mixing_status: "string",
          },
          treatment: {
            treatment_status: "string",
          },
        },
      },
      {
        global_patient_id: "string",
        bi_ct: 1,
        qty: 4,
        received: "09/09/2024	",
        completed: "09/09/2024	",
        treated_at: "09/09/2024	",
        insurance_name: "string",
        payer: "ambetter",
        update: ["Treatment Date"],
        outcome: "Covered",
        rx_cvg: "Y",
        med_cvg: "Y",
        rx_pa: "N",
        med_pa: "N",
        status_name: "string",
        retreatment_status: "string",
        practice_name: "string",
        practice_phone: "string",
        practice_fax: "string",
        doctor_name: "string",
        global_doctor_id: "string",
        case_id: 189853,
        patient_id: 45467,
        suffix: "string",
        dose: "number",
        tmnt: "number",
        ordered: "string",
        shipped: "string",
        treatment: "09/09/2024",
        case_status: "Med and Rx Coverage Available",
        color_idx: 2,
        provider: {
          doc_name: "HAERER, BRANDI",
          practice_name: "brandi health care",
          phone: "3132423234",
          fax: "32453453223",
          address: "3015 n penstemon WICHITA, KS 67226",
        },
        soc: {
          practice_name: "string",
          practice_id: "number",
          phone: "number",
          fax: "number",
        },
        case_detail: {
          order: {
            order_status: "string",
          },
          mixing: {
            mixing_status: "string",
          },
          treatment: {
            treatment_status: "string",
          },
        },
      },
    ],
    provider:[
      {
        providers: " ALURI ,BAPU",
        commercial: 0,
        medicare: 0,
        medicare_advantage: 0,
        unspecified: 0,
        total_cases: "2(1.8 %)",
      },
      {
        providers: " BEECROFT ,CARL",
        commercial: 0,
        medicare: 0,
        medicare_advantage: 0,
        unspecified: 0,
        total_cases: "9(8.11 %)",

      },
      {
        providers: "CONNELL , CHARLES",
        commercial: 0,
        medicare: 0,
        medicare_advantage: 0,
        unspecified: 0,
        total_cases: "2(1.8 %)",
      },
      {
        providers: "CREZEE, KELVIN",
        commercial: 0,
        medicare: 0,
        medicare_advantage: 0,
        unspecified: 0,
        total_cases: "6(5.41 %)",

      },
      {
        providers: "DUGAL, NARESH",
        commercial: 0,
        medicare: 0,
        medicare_advantage: 0,
        unspecified: 0,
        total_cases: "2(1.8 %)",
      },
      {
        providers: "FREED, LEWIS",
        commercial: 0,
        medicare: 0,
        medicare_advantage: 0,
        unspecified: 0,
        total_cases: "3(2.7 %)",

      },
      {
        providers: " ALURI ,BAPU",
        commercial: 0,
        medicare: 0,
        medicare_advantage: 0,
        unspecified: 0,
        total_cases: "2(1.8 %)",
      },
      {
        providers: " BEECROFT ,CARL",
        commercial: 0,
        medicare: 0,
        medicare_advantage: 0,
        unspecified: 0,
        total_cases: "9(8.11 %)",

      },
      {
        providers: "CONNELL , CHARLES",
        commercial: 0,
        medicare: 0,
        medicare_advantage: 0,
        unspecified: 0,
        total_cases: "2(1.8 %)",
      },
      {
        providers: "CREZEE, KELVIN",
        commercial: 0,
        medicare: 0,
        medicare_advantage: 0,
        unspecified: 0,
        total_cases: "6(5.41 %)",

      },
      {
        providers: "DUGAL, NARESH",
        commercial: 0,
        medicare: 0,
        medicare_advantage: 0,
        unspecified: 0,
        total_cases: "2(1.8 %)",
      },
      {
        providers: "FREED, LEWIS",
        commercial: 0,
        medicare: 0,
        medicare_advantage: 0,
        unspecified: 0,
        total_cases: "3(2.7 %)",

      }
    ]
  },
};
