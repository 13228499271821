import { FC, useContext } from "react";

import {AvanosLogo, TriGenLogo} from "../../assets";
import { FooterTextProps } from "./types";
import { AuthContext } from "src/context/authContext";


const FooterText: FC<FooterTextProps> = ({ footerType }): JSX.Element => {
  const { isUserAuthorized } = useContext(AuthContext);

  return (
      <div className={`${!isUserAuthorized ? 'bg-white p-3' : 'mt-4 mb-4'}`}>
        <div className="trademark-body container-fluid">
          <div className="row justify-content-start align-items-center">
            <div className="col-8 col-sm-8 col-md-3">
              <img className="img-fluid" width={isUserAuthorized ? 380 : 200}
                   src={isUserAuthorized ? TriGenLogo : AvanosLogo} alt="alternate logo"/>
            </div>
            <small className="col-4 text-end d-block d-lg-none d-md-none">COPY-07554</small>
            <small className="col-12 col-md-7 text-center mt-3 mt-lg-0 mt-md-0 mt-sm-0">
              Distributed by Avanos 5405 Windward Pkwy Alpharetta
              GA 30004 *Registered Trademark or Trademark of Avanos Medical, Inc.
              or its affiliated. ©2023 Avanos Medical, Inc. All Rights Reserved.
            </small>
            {isUserAuthorized ? (
                    <div className="col-2 text-end">
                      <img width={170} src={AvanosLogo} alt="alternate logo"/>
                    </div>
                ) :
                (<small className="col-2 text-end d-none d-md-block">COPY-07554</small>)
            }
          </div>
        </div>
      </div>
  );
};

export default FooterText;
