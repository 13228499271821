import { format } from "date-fns";

export const getDateFormat = (dateAndTime: string) => {
  if (dateAndTime)
    return format(new Date(dateAndTime), "MM/dd/yyyy")
};

export const getDateTimeFormat = (dateAndTime: string) => {
  if (dateAndTime) 
    return format(
      new Date(new Date(dateAndTime).valueOf()+new Date(dateAndTime).getTimezoneOffset() * 60 * 1000),
      "MM/dd/yyyy HH:mm"
    );
};
