import { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import HubInputField from "../../components/inputType";
import FooterText from "../../components/footerText";
import AuthSubHeader from "../../components/authSubHeader";

import useFetch from "../../hooks/useFetch";
import { ToastContext } from "../../context/toastContext";
import { AuthContext } from "../../context/authContext";
import { LoaderContext } from "../../context/loaderContext";

import { AddProviderSchema } from "../../validation/addProvider";
import { APIEndpoints } from "../../types/apiTypes";
import { ToastTypes } from "../../types";
import { AddProviderForm, AddProviderResponse, NPILookupResponse, NPISpeciality, NPISpecialityErrorResponse } from "./types";
import { GroupBase, OptionsOrGroups } from "react-select";

const AddProvider: FC = (): JSX.Element => {
  const [preservedNpi, updatePreservedNpi] = useState<string>("");

  const { control, handleSubmit, formState, getValues, setValue, trigger, reset: resetForm } = useForm<AddProviderForm>({
    mode: 'all',
    resolver: yupResolver(AddProviderSchema),
  });

  const navigate = useNavigate();
  const { showToast } = useContext(ToastContext);
  const { userData, destroySession } = useContext(AuthContext);
  const { setLoader, hideLoader } = useContext(LoaderContext);





  const { error: specialityError, data: specialityData } = useFetch<NPISpeciality[]>(APIEndpoints.GET_PROVIDER_SPECIALITIES);

  /**
   * @TaxonomyListingError Error Handling
   */
  useEffect(() => {
    if (specialityError) {
      const { error, code } = specialityError as unknown as NPISpecialityErrorResponse;
      showToast([error], ToastTypes.ERROR);
      if (code === 401) {
        destroySession();
        navigate(`/users/sign_in`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialityError]);





  const { mutate: lookupNpi, error: npiLookupError, data: npiLookupResponse, removeErrorAndData: clearNpiResponse } =
    useFetch<NPILookupResponse>(APIEndpoints.NPI_LOOKUP);

  /**
   * Checking for NPILookup call errors
   * @NPILookUpErrorResponseHandler
   */
  useEffect(() => {
    if (npiLookupError) {
      hideLoader();
      showToast([npiLookupError], ToastTypes.ERROR);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [npiLookupError]);

  /**
   * Checking for NPILookup call errors
   * @NPILookUpResponseHandler
   */
  useEffect(() => {
    if (npiLookupResponse) {
      if (npiLookupResponse.doctor_data) {
        hideLoader();
        const { basic, taxonomy_data } = npiLookupResponse.doctor_data;
        setValue("ref_taxonomy_id", taxonomy_data.code);
        setValue("sln", taxonomy_data.license || "");
        setValue("firstname", basic.first_name);
        setValue("lastname", basic.last_name);
        setValue("tax_id", basic.tax_id || "");
        trigger();
        showToast(["Doctor data found"], ToastTypes.INFO);
      } else {
        hideLoader();
        setValue("ref_taxonomy_id", "");
        setValue("sln", "");
        setValue("firstname", "");
        setValue("lastname", "");
        setValue("tax_id", "");
        showToast([`No Data Found`], ToastTypes.ERROR);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [npiLookupResponse]);

  /**
   * When we enter after entering NPI in the input field
   * @param event
   */
  const callNPILookup = () => {
    const npi = getValues("npi");

    if (!npi || (npi && npi.length === 0) || formState.errors.npi)
      showToast([`Please enter valid NPI for lookup`], ToastTypes.ERROR);
    else {
      if (preservedNpi !== npi) {
        updatePreservedNpi(npi || "");
        clearNpiResponse();
        setLoader();
        const data = {
          'global_doctor[npi]': npi,
        };
        lookupNpi(null, null, data);
      }
    }
  };





  /**
   * Submitting form and getting response to add new provider/doctor
   * @First Adding hook for api call
   * @Second Error handling for api call
   * @Third Success handling for api call
   * @Fourth Form Submit Handler which will ultimately call the mutate function to call api
   */
  const {
    loading: addProviderLoading,
    error: addProviderError,
    data: addProviderResponse,
    mutate: callAddProvider,
    removeErrorAndData: refreshAddProviderRequest
  } = useFetch<AddProviderResponse>(APIEndpoints.ADD_PROVIDER);

  /**
   * @Second
   */
  useEffect(() => {
    if (addProviderError) {
      console.log({ addProviderError });
      showToast([`Error Happen`], ToastTypes.ERROR);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addProviderError]);

  /**
   * @Third
   */
  useEffect(() => {
    if (addProviderResponse) {
      const { status: { message, code } } = addProviderResponse;
      if (code === 200) {
        showToast([message], ToastTypes.SUCCESS);
        resetForm();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addProviderResponse]);

  /**
   * @Fourth
   */
  const submitHandler = async (values: AddProviderForm) => {
    refreshAddProviderRequest();

    if (userData) {
      const { id } = userData;
      const res = {
        user_id: id,
        global_doctor: {
          ...values,
          ref_taxonomy_id: (specialityData?.find((speciality) => speciality.code === values.ref_taxonomy_id)?.id || 0).toString(),
        },
      };
      callAddProvider(res);
    }
  };

  return (
      <div className="container-fluid d-flex flex-fill auth-page bg-light overflow-auto">
        <div className="container">
          <AuthSubHeader/>
          <div className="card mt-4">
            <div className="card-header">
              <h4 className="mb-0">Add Doctor</h4>
            </div>
            <div className="card-body">
              <p>Please input the information for the doctor you wish to create below. The doctor will be automatically
                added to all practices attached to your account, and available immediately for use in new requests!</p>
              <form onSubmit={handleSubmit(submitHandler)}>

                <div className="row">
                  <div className="col-md-6 col-12 mb-2">
                    <Controller
                      name="npi"
                      control={control}
                      defaultValue=""
                      render={({ field, fieldState: { error } }) => (
                        <HubInputField
                          inputId="npi"
                          isRowBased={true}
                          isRequired={true}
                          field={field}
                          error={error}
                          inputLabel="NPI"
                          inputPlaceholder="NPI"
                          onBlur={callNPILookup}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              event.preventDefault();
                              callNPILookup();
                            }
                          }}
                        />
                      )}
                    />
                  </div>

                  <div className="col-md-6 col-12 mb-2">
                    <Controller
                        name="ref_taxonomy_id"
                        control={control}
                        defaultValue=""
                        render={({field, fieldState: {error}}) => (
                            <HubInputField
                                inputId="ref_taxonomy_id"
                                isRowBased={true}
                                isRequired={true}
                                field={field}
                                error={error}
                                inputLabel="Speciality"
                                inputPlaceholder="Select a Taxonomy..."
                                isDropdown={true}
                                isSearchable={true}
                                dropdownData={
                                  specialityData ?
                                    specialityData.map(
                                        (speciality) => ({label: speciality.name, value: speciality.code})
                                    ) as unknown as OptionsOrGroups<string, GroupBase<string>>
                                    : []
                                }
                            />
                        )}
                    />
                  </div>

                  <div className="col-md-6 col-12 mb-2">
                    <Controller
                        name="firstname"
                        control={control}
                        defaultValue=""
                        render={({field, fieldState: {error}}) => (
                            <HubInputField
                                inputId="firstname"
                                isRowBased={true}
                                isRequired={true}
                                field={field}
                                error={error}
                                inputLabel="First"
                                inputPlaceholder="First Name"
                            />
                        )}
                    />
                  </div>

                  <div className="col-md-6 col-12 mb-2">
                    <Controller
                        name="lastname"
                        control={control}
                        defaultValue=""
                        render={({field, fieldState: {error}}) => (
                            <HubInputField
                                inputId="lastname"
                                isRowBased={true}
                                isRequired={true}
                                field={field}
                                error={error}
                                inputLabel="Last"
                                inputPlaceholder="Last Name"
                            />
                        )}
                    />
                  </div>

                  <div className="col-md-6 col-12 mb-2">
                    <Controller
                        name="dea"
                        control={control}
                        defaultValue=""
                        render={({field, fieldState: {error}}) => (
                            <HubInputField
                                inputId="dea"
                                isRowBased={true}
                                isRequired={false}
                                field={field}
                                error={error}
                                inputLabel="DEA"
                                inputPlaceholder="DEA Number"
                            />
                        )}
                    />
                  </div>

                  <div className="col-md-6 col-12 mb-2">
                    <Controller
                        name="sln"
                        control={control}
                        defaultValue=""
                        render={({field, fieldState: {error}}) => (
                            <HubInputField
                                inputId="sln"
                                isRowBased={true}
                                isRequired={false}
                                field={field}
                                error={error}
                                inputLabel="SLN"
                                inputPlaceholder="State License Number"
                            />
                        )}
                    />
                  </div>

                  <div className="col-md-6 col-12 mb-2">
                    <Controller
                        name="tax_id"
                        control={control}
                        defaultValue=""
                        render={({field, fieldState: {error}}) => (
                            <HubInputField
                                inputId="tax_id"
                                isRowBased={true}
                                isRequired={false}
                                field={field}
                                error={error}
                                inputLabel="Tax ID"
                                inputPlaceholder="Tax ID"
                            />
                        )}
                    />
                  </div>

                  <div className="col-md-6 col-12 mb-2">
                    <Controller
                        name="ptan"
                        control={control}
                        defaultValue=""
                        render={({field, fieldState: {error}}) => (
                            <HubInputField
                                inputId="ptan"
                                isRowBased={true}
                                isRequired={false}
                                field={field}
                                error={error}
                                inputLabel="PTAN"
                                inputPlaceholder="Provider Transaction Access Number"
                            />
                        )}
                    />
                  </div>
                </div>

                <button type="submit" disabled={addProviderLoading} className="btn btn-primary">
                  <div className="row align-items-center px-1">
                    {addProviderLoading ? (
                        <div className="spinner-border text-light col-3" role="status">
                          <span className="sr-only"></span>
                        </div>
                    ) : null}
                    <p className="col-auto mb-0"> Add Doctor </p>
                  </div>
                </button>
              </form>
            </div>
          </div>
          <FooterText footerType="black"/>
        </div>
      </div>
  );
};

export default AddProvider;
