// import React, { useRef, useEffect } from 'react';
// import Highcharts from 'highcharts';
// import HighchartsMap from 'highcharts/modules/map';
// import HighchartsReact  from 'highcharts-react-official';
// import { mapOptions } from './map';

// // Initialize the module
// if (typeof Highcharts === 'object') {
//     HighchartsMap(Highcharts);
// }

// interface HighchartsMapProps {
//     mapData: any;
//     options: Highcharts.Options;
// }

// const HighchartsMapComponent: React.FC<HighchartsMapProps> = ({ mapData, options }) => {
//   const chartRef = useRef<HighchartsReact.RefObject | null | any>(null);
//   useEffect(() => {
//     if (chartRef.current && chartRef.current?.series?.length === 0) {
//       chartRef.current.chart.addSeries({
//                     type: 'map',
//                     mapData: mapData,
//                     name: 'World Map',
//                     data: [
//                         ["AL", 1], // Sample data, specify values as needed
//               ["AK", 5],
//               ["AZ", 19], // Sample data, specify values as needed
//               ["AR", 4]
//                     //     // Add more data as needed
//                      ],
//                     ...options.series, // Additional series options
//                 });
//     }
// }, [mapData, options]);

//   return (
//       <div>
//           <h1>Highcharts Map in React TypeScript</h1>
//           <HighchartsReact highcharts={Highcharts} options={mapOptions}  ref={chartRef}/>
//       </div>
//   );

// };
// export default HighchartsMapComponent;



import { FC } from "react";
import * as Highcharts from 'highcharts/highmaps';
import HighchartsReact from 'highcharts-react-official';
import mapDataWorld from '@highcharts/map-collection/custom/world.geo.json';
import { arrayConversion } from "./const";


const HighchartsMapComponent: FC<HighchartsReact.Props> = (props: HighchartsReact.Props): JSX.Element => {
  // var data1: [string, number][] = [
  //   ['fo', 0],
  //   ['um', 1],
  //   ['us', 2],
  //   ['jp', 3],
  //   ['sc', 4],
  //   ['in', 5],
  //   ['fr', 6],
  //   ['fm', 7],
  //   ['cn', 8],
  //   ['pt', 9],
  //   ['sw', 10],
  //   ['sh', 11],
  //   ['br', 12],
  //   ['ki', 13],
  //   ['ph', 14],
  //   ['mx', 15],
  //   ['es', 16],
  //   ['bu', 17],
  //   ['mv', 18],
  //   ['sp', 19],
  //   ['gb', 20],
  //   ['gr', 21],
  //   ['as', 22],
  //   ['dk', 23],
  //   ['gl', 24],
  //   ['gu', 25],
  //   ['mp', 26],
  //   ['pr', 27],
  //   ['vi', 28],
  //   ['ca', 29],
  //   ['st', 30],
  //   ['cv', 31],
  //   ['dm', 32],
  //   ['nl', 33],
  //   ['jm', 34],
  //   ['ws', 35],
  //   ['om', 36],
  //   ['vc', 37],
  //   ['tr', 38],
  //   ['bd', 39],
  //   ['lc', 40],
  //   ['nr', 41],
  //   ['no', 42],
  //   ['kn', 43],
  //   ['bh', 44],
  //   ['to', 45],
  //   ['fi', 46],
  //   ['id', 47],
  //   ['mu', 48],
  //   ['se', 49],
  //   ['tt', 50],
  //   ['my', 51],
  //   ['pa', 52]
  // ];

  // const convertedData: [string, number][] = mapData.map(entry => {
  //   // Extract the state abbreviation and number of cases from each entry
  //   const stateToCountryCode: { [key: string]: string } = {
  //     "AL": "us",
  //     "AK": "us",
  //     "AZ": "us",
  //     "AR": "us",
  //     "CA": "us",
  //     // Add more state abbreviations as needed
  //   };
  //   const stateAbbreviation = entry.state;
  //   const casesString = entry.cases;

  //   // Extract the number of cases from the string
  //   const cases = parseInt(casesString.match(/\d+/)[0]);

  //   // Map the state abbreviation to its two-letter country code
  //   const countryCode = stateToCountryCode[stateAbbreviation];

  //   // Return an array containing the country code and number of cases
  //   return [countryCode, cases];
  // });


  const options: Highcharts.Options = {
    chart: {
      map: mapDataWorld,
    },
    colorAxis: {
      min: 60
    },
    tooltip: {
      valueDecimals: 1,
      valueSuffix: ' years'
    },
    series: [{
      type: 'map',
      mapData: mapDataWorld,
      data: arrayConversion(),
      dataLabels: {
        enabled: true,
        color: '#00000',
        format: '{point.code}'
      },
      name: 'Case Volume',
      tooltip: { pointFormat: 'Cases: {point.value}' },
      animation: true,
    }]
  }

  return (
    <div id='mapChart'>
      <HighchartsReact
        options={options}
        highcharts={Highcharts}
        constructorType={'mapChart'}
        {...props}
      />
    </div>
  );
}

export default HighchartsMapComponent;
