import { QueryParamsState, QueryStateActionType, QueryStateActions } from "./types";

export const initialState: QueryParamsState = {
  open: "",
  sort_by: "id",
  direction: "desc",
  search_query: "",
  date_from: "",
  date_to: "",
  page: 1,
};

export const queryParamReducer = (state: QueryParamsState, action: QueryStateActionType): QueryParamsState => {
  switch (action.type) {
    case QueryStateActions.CHANGE_CASE_STATUS:
      return {
        ...state,
        open: action.payload,
      };

    case QueryStateActions.CHANGE_SORT_PARAMS:
      return {
        ...state,
        sort_by: action.payload.sort_by,
        direction: action.payload.direction,
      };

    case QueryStateActions.CHANGE_SEARCH_QUERY:
      return {
        ...state,
        search_query: action.payload,
      };

    case QueryStateActions.CHANGE_FROM_DATE:
      return {
        ...state,
        date_from: action.payload,
      };

    case QueryStateActions.CHANGE_TO_DATE:
      return {
        ...state,
        date_to: action.payload,
      };

    case QueryStateActions.CHANGE_PAGE:
      return {
        ...state,
        page: action.payload
      };

    case QueryStateActions.CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
};
