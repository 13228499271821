import { FC } from "react"


const Practices: FC = (): JSX.Element => {

  return (
    <div className="card p-0">
      <div className="d-flex flex-column flex-fill">
        <div className="card-header order-1 custom-card-header">
          <h4 className="mb-0 p-0 text-black text-center " >Practices</h4>
        </div>
        <div className="order-2 card-body p-0 overflow-y-auto" style={{ maxHeight: 400 }}>
          <h5 className="text-center">Coming Soon</h5>
        </div>
      </div>
    </div>
  );
};
export default Practices;