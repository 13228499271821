import { EMAIL_REGEX, PASSWORD_REGEX } from "../constants";
import * as yup from "yup";

export const CreateUserSchema = yup.object({
    firstname: yup
      .string()
      .required(`can't be blank`),
    middlename: yup
      .string(),
    lastname: yup
      .string()
      .required(`can't be blank`),
    email: yup
      .string()
      .email(`Provided email should be a valid string`)
      .required(`can't be blank`)
      .test('EmailVerification', `Provided email should be a valid string`, (val) => (EMAIL_REGEX.test(val)), ),
    password: yup
      .string()
      .required(`Password is required`)
      .test('PasswordValidation',
          `Password should contain <ul><li> Length should be 10,</li>
          <li> must contain at least 1 lowercase letter,</li>
          <li> must contain at least 1 uppercase letter,</li>
          <li> and 1 number</li>
          <li> Can contain special character.</li></ul>`,
          (val) => val ? PASSWORD_REGEX.test(val) : false,
        ) ,
      
    password_confirmation: yup
      .string()
      .required("Password confirmation is required")
      .oneOf([yup.ref('password'), ''], 'Passwords should be same'),
});
