import { FC, useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import HubInputField from "../../components/inputType";
import FooterText from "../../components/footerText";
import AuthSubHeader from "../../components/authSubHeader";

import useFetch from "../../hooks/useFetch";
import { ToastContext } from "../../context/toastContext";

import { AddUserSchema } from "../../validation/addUser";
import { AddNewUserApiResponseType, AddNewUserProps } from "./types";
import { APIEndpoints } from "../../types/apiTypes";
import { ToastTypes } from "src/types";

const AddNewUser: FC = (): JSX.Element => {
  const { control, handleSubmit, reset } = useForm<AddNewUserProps>({
    mode: 'onChange',
    resolver: yupResolver(AddUserSchema)
  });

  const { showToast } = useContext(ToastContext);





  const { loading, data, mutate } = useFetch<AddNewUserApiResponseType>(APIEndpoints.ADD_USER);

  useEffect(() => {
    if (data && data.status) {
      if (data.status.code === 500) {
        const errorMessage = data.status.message.includes("unq_user_email_address") ? "Email already exists" : data.status.message;
        showToast([errorMessage], ToastTypes.ERROR);
      } else {
        reset();
        showToast([data.status.message], ToastTypes.SUCCESS);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const submitNewUser = async (values: AddNewUserProps) => {
    mutate({ user: values });
  };

  return (
      <div className="container-fluid d-flex flex-fill auth-page bg-light overflow-auto">
        <div className="container">
          <AuthSubHeader/>
          <div className="card mt-4">
            <div className="card-header">
              <h4 className="mb-0">Add User</h4>
            </div>
            <div className="card-body">
              <p>Input information in the form below to add a new user to this account. A welcome email will be sent
                to the email address provided below.</p>
              <form onSubmit={handleSubmit(submitNewUser)}>

                <div className="row">
                  <div className="col-12 mb-2">
                    <Controller
                        name="firstname"
                        control={control}
                        defaultValue=""
                        render={({field, fieldState: {error}}) => (
                            <HubInputField
                                inputId="firstname"
                                isRowBased={true}
                                isRequired={true}
                                field={field}
                                inputLabel="First Name"
                                inputPlaceholder="First Name"
                                error={error}
                            />
                        )}
                    />
                  </div>

                  <div className="col-12 mb-2">
                    <Controller
                        name="middlename"
                        control={control}
                        defaultValue=""
                        render={({field, fieldState: {error}}) => (
                            <HubInputField
                                inputId="middlename"
                                isRowBased={true}
                                isRequired={false}
                                field={field}
                                inputLabel="Middle Name"
                                inputPlaceholder="Middle Name"
                                error={error}
                            />
                        )}
                    />
                  </div>

                  <div className="col-12 mb-2">
                    <Controller
                        name="lastname"
                        control={control}
                        defaultValue=""
                        render={({field, fieldState: {error}}) => (
                            <HubInputField
                                inputId="lastname"
                                isRowBased={true}
                                isRequired={true}
                                field={field}
                                inputLabel="Last Name"
                                inputPlaceholder="Last Name"
                                error={error}
                            />
                        )}
                    />
                  </div>

                  <div className="col-12 mb-2">
                    <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        render={({field, fieldState: {error}}) => (
                            <HubInputField
                                inputId="email"
                                inputType="email"
                                isRowBased={true}
                                isRequired={true}
                                field={field}
                                inputLabel="Email"
                                inputPlaceholder="Email Address"
                                error={error}
                            />
                        )}
                    />
                  </div>
                </div>

                <button disabled={loading} type="submit" className="btn btn-primary">
                  <div className="row align-items-center px-1">
                    {loading ? (
                        <div className="spinner-border text-light col-3" role="status">
                          <span className="sr-only"></span>
                        </div>
                    ) : null}
                    <p className="col-auto mb-0">Add User</p>
                  </div>
                </button>
              </form>
            </div>
          </div>
          <FooterText footerType="black"/>
        </div>
      </div>
  );
};

export default AddNewUser;
