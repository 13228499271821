import { FC } from "react";

const BAAContent: FC = (): JSX.Element => {
  return (
    <>
      <p>
        THIS BUSINESS ASSOCIATE AGREEMENT (“Agreement”) is entered into as of [USER CREATED DATE] (“Effective Date”), by and between the currently logged in business (“Covered Entity”) and COPILOT Provider Support Services, LLC (“COPILOT”) with an address of 601 S Lake Destiny Road, Suite 300, Maitland, FL 32751, (each a “Party” and collectively the “Parties”). For purposes of this Agreement, COPILOT is a “Business Associate,” as defined below, and reference to “Business Associate” in this Agreement refer to COPILOT individually.
      </p>
      <p>RECITALS</p>
      <p>WHEREAS, COPILOT performs services on behalf of Covered Entity pursuant one or more underlying agreements (each, an “Underlying Agreement(s)”), which Services involve the Use and/or Disclosure of Protected Health Information (defined below); and may perform certain benefits investigation, patient support, or prescription transfer functions on behalf of Covered Entity related to coverage, treatment, and payment for Avanos products and related services.      </p>
      <p>WHEREAS, the Parties desire to enter into this Agreement in order to comply with the Health Insurance Portability and Accountability Act of 1996 (“HIPAA”) and the Health Information Technology for Economic and Clinical Health Act (“HITECH”), as amended, and their implementing regulations, as well as applicable state health information privacy and security laws and regulations, as amended and in effect. Nothing herein constitutes an admission that the Parties are subject to HIPAA or the HITECH Act except as required by law, and this Agreement has no force or effect with respect to a Party to the extent HIPAA or HITECH are inapplicable to that Party      </p>
      <p>NOW, THEREFORE, the parties do hereby agree as follows:</p>
      <ol>
        <li>
          <p>Definitions. Capitalized terms not otherwise defined in this Agreement shall have the same meaning as those
            terms in the Privacy Rule and the Security Rule (defined below).</p>

          <ol type="a">
            <li>“Breach” when capitalized, “Breach” shall have the meaning set forth in 45 CFR § 164.402 (including all
              of its subsections); with respect to all other uses of the word “breach” in this Agreement, the word shall
              have its ordinary contract meaning.
            </li>
            <li>“Electronic Protected Health Information” or “EPHI” shall have the same meaning as the term “electronic
              protected health information” in 45 CFR § 160.103, limited to information that Business Associate creates,
              accesses or receives on behalf of Covered Entity.
            </li>
            <li>“Protected Health Information” or “PHI” shall have the meaning set forth in the Privacy Rule, limited to
              information that Business Associate creates, accesses or receives on behalf of Covered Entity. PHI
              includes EPHI.
            </li>
            <li>“Privacy Rule” means the Standards for Privacy of Individually Identifiable Health Information, codified
              at 45 CFR part 160 and subparts A, D, and E of part 164, as may be amended during the term of this
              Agreement.
            </li>
            <li>“Security Rule” means the Standards for Security for the Protection of Electronic Protected Health
              Information, codified at 45 CFR part 160 and subpart C of part 164, as may be amended during the term of
              this Agreement.
            </li>
            <li>“Services” the functions performed by COPILOT, respectively, on behalf of Covered Entity that involve
              the creation, receipt, maintenance or transmission of PHI on behalf of Covered Entity. The Parties agree
              that the Services do not include, and this Agreement does not apply to, activities of COPILOT not
              performed for or on behalf of Covered Entity.
            </li>
            <li> “Unsecured Protected Health Information” shall have the same meaning as the term “unsecured protected
              health information” in 45 CFR § 164.402, limited to the information created or received by Business
              Associate from or on behalf of Covered Entity.
            </li>
          </ol>
        </li>

        <li>
          <p>Business Associate Obligations. To the extent that it is a Business Associate of Covered Entity, Business
            Associate shall, in addition to complying with the terms of this Agreement, comply with the following and
            any applicable state provisions that are more restrictive:</p>

          <ol type="a">
            <li>
              <p>Uses and Disclosures. Business Associate shall not Use or further Disclose PHI other than as permitted
                or required by this Agreement or as Required by Law.</p>
              <ol type="i">
                <li>Business Associate may Use and Disclose PHI to perform Services and to perform under any Underlying
                  Agreement, provided such Use or Disclosure would not violate HIPAA if done by Covered Entity or is
                  otherwise in compliance with HIPAA; and
                </li>
                <li>To the extent required by HIPAA, Business Associate shall only Use, request or Disclose the minimum
                  necessary PHI to accomplish the permissible purpose(s) of the Use or Disclosure.
                </li>
              </ol>
            </li>

            <li>Uses and Disclosures Permitted By Law. As permitted by the Privacy Rule, Business Associate may Use or
              Disclose PHI: (i) as is necessary for the proper management and administration of Business Associate’s
              organization, (ii) to provide data aggregation services relating to the health care services of the
              Covered Entity; (iii) to create de-identified information that may be Used and Disclosed by Business
              Associate on a royalty-free basis as Business Associate deems appropriate; and (iv) to carry out the legal
              responsibilities of Business Associate; provided, however, that any permitted Disclosure of PHI to a third
              party for the management or administration of Business Associate or to carry out its legal
              responsibilities must be either Required by Law or subject to reasonable assurances obtained by Business
              Associate from the third party that PHI will be held confidentially, and securely, and Used or Disclosed
              only as Required by Law or for the purposes for which it was disclosed to such third party, and that any
              breaches of confidentiality of PHI which become known to such third party will be immediately reported to
              Business Associate.
            </li>

            <li>Privacy Rule. To the extent Business Associate carries out one or more of Covered Entity’s obligations
              under the Privacy Rule, Business Associate shall comply with the requirements of HIPAA that apply to
              Covered Entity in the performance of such obligation(s).
            </li>

            <li>Safeguards. Business Associate shall use safeguards that are appropriate and sufficient to prevent Use
              or Disclosure of PHI other than the Uses and Disclosures permitted or required by this Agreement. Business
              Associate shall comply with the Security Rule with respect to EPHI, including implementing Administrative
              Safeguards, Physical Safeguards, and Technical Safeguards that reasonably and appropriately protect the
              Confidentiality, Integrity and Availability of EPHI.
            </li>

            <li>Reporting. Business Associate shall report to Covered Entity any Use or Disclosure of PHI not permitted
              or required by this Agreement and any Security Incident of which it becomes aware. Business Associate
              hereby informs Covered Entity, without any further obligation, that incidents including, but not limited
              to, ping sweeps or other common network reconnaissance techniques, attempts to log on to a system with an
              invalid password or username, denial of service attacks that do not result in a server being taken off
              line, and similar minor unsuccessful Security Incidents and other incidents may occur from time to time.
            </li>

            <li>Agents and Subcontractors. Business Associate shall ensure that any and all Subcontractors that create,
              receive, maintain or transmit PHI on behalf of Business Associate agree prior to the Subcontractors’
              receipt of such PHI, to the same restrictions and conditions that apply to Business Associate with respect
              to PHI.
            </li>

            <li>
              <p>Patient Rights.</p>

              <ol type="i">
                <li>Patient Right to Access. Business Associate shall make PHI in a Designated Records Set that it
                  maintains available to Covered Entity or, as directed by Covered Entity, to the subject of the PHI, in
                  compliance with the requirements of 45 C.F.R. §164.524. If any Individual requests access to his or
                  her own PHI from Business Associate, Business Associate shall notify Covered Entity of the details of
                  such request. Business Associate agrees to provide PHI it maintains electronically in a Designated
                  Record Set in an electronic form if it is readily producible in such form or format at the request of
                  Covered Entity or an Individual.
                </li>
                <li>Patient Right to Amend. Business Associate shall incorporate amendment(s) to PHI in a Designated
                  Records Set that it maintains upon receipt of Covered Entity’s request and in compliance with 45
                  C.F.R. §164.526. If any Individual submits to Business Associate a request to amend his or her own
                  PHI, Business Associate shall notify Covered Entity of the details of such request.
                </li>

                <li>
                  <p>Patient Right to Request Accounting. With respect to disclosures of PHI by Business Associate,
                    Business Associate shall document and make available to Covered Entity the information required to
                    provide an accounting of disclosures or, as directed by Covered Entity, to the subject of the PHI,
                    in compliance with the requirements of 45 C.F.R. §164.528. If any Individual requests an accounting
                    from Business Associate, Business Associate shall notify Covered Entity of the details of such
                    request.</p>
                  <p>(1) Business Associate agrees to implement an appropriate record keeping process to enable it to
                    comply with the requirements of this Section.</p>

                </li>
              </ol>
            </li>

            <li>Audit. Business Associate shall make its internal practices, books, and records relating to the Use and
              Disclosure of PHI received from, or created or received by Business Associate on behalf of Covered Entity
              available to the Secretary of Health and Human Services, upon request, for purposes of determining and
              facilitating compliance with HIPAA.
            </li>

            <li>Mitigation. Business Associate shall mitigate promptly, to the extent practicable, any harmful effect
              that is known to Business Associate of a Use or Disclosure of PHI by Business Associate in violation of
              this Agreement, the Privacy Rule, the Security Rule, or other applicable federal or state law.
            </li>

            <li>Breach. If Business Associate discovers a Breach of Unsecured Protected Health Information, Business
              Associate shall promptly (no later than the timeframe Required by Law) notify the Covered Entity in
              accordance with the requirements of 45 CFR § 164.410. Such notification shall include, to the extent
              possible, the identification of each Individual whose PHI has been or is reasonably believed to have been
              accessed, acquired, Used or Disclosed during the Breach, along with any other information that the Covered
              Entity will be required to include in its notification to the Individual, the media and/or the Secretary,
              as applicable, including, without limitation, a description of the Breach, the date of the Breach and its
              discovery, the types of Unsecured Protected Health Information involved and a description of the Business
              Associate’s investigation, mitigation, and prevention efforts.
            </li>

          </ol>
        </li>

        <li>
          <span>Covered Entity Obligations.</span>
          <ol type="a">
            <li>Notice of Privacy Practices. Covered Entity shall notify each Business Associate of limitation(s) in its
              Notice of Privacy Practices, to the extent such limitation affects Business Associate’s permitted Uses or
              Disclosures.
            </li>

            <li>Individual Permission. Covered Entity shall notify each Business Associate of changes in, or revocation
              of, permission by an Individual to Use or Disclose PHI, to the extent such changes affects Business
              Associate’s permitted Uses or Disclosures.
            </li>

            <li>Restrictions. Covered Entity shall notify each Business Associate of restriction(s) in the Use or
              Disclosure of PHI that Covered Entity has agreed to, to the extent such restriction affects Business
              Associate’s permitted Uses or Disclosures.
            </li>

            <li>Compliance. Covered Entity shall comply with all applicable state and federal privacy and security laws
              and regulations, including HIPAA. Covered Entity agrees to obtain any patient authorizations or consents
              that may be required by state or federal law or regulation in order to transmit PHI to Business Associate
              and to enable Business Associate and its Subcontractors, agents and assigns to Use and Disclose PHI as
              contemplated by this Agreement.
            </li>

          </ol>
        </li>

        <li>
          <p>Term & Termination.</p>

          <ol type="a">
            <li>Term. The Term of this Agreement shall begin on the Effective Date, and shall terminate when all PHI
              provided by Covered Entity to Business Associate is destroyed or returned to Covered Entity, or if it is
              infeasible to return or destroy all PHI, protections are extended to such information in accordance with
              the termination provisions in this Section.
            </li>

            <li>
              <p>Termination.</p>

              <ol type="i">
                <li>
                  <p>By Covered Entity. Upon Covered Entity’s knowledge of a material breach by a Business Associate of
                    this Agreement, Covered Entity may, with respect to the Business Associate committing the
                    breach:</p>
                  <p>(1) Terminate this Agreement and any Underlying Agreement upon thirty (30) days’ notice if Covered
                    Entity reasonably determines that Business Associate has violated a material term of this Agreement
                    if, following Covered Entity’s notification to Business Associate of the material breach, Business
                    Associate is unable or unwilling to take steps to cure the breach within such thirty (30) day
                    period. In the event of such a cure, this Agreement shall remain in full force and effect; or</p>
                  <p>(2) Immediately terminate this Agreement and any Underlying Agreement if Business Associate has
                    breached a material term of this Agreement and cure is not possible.</p>
                </li>
                <li>
                  <p>By Business Associate. Upon Business Associate’s knowledge of a material breach by Covered Entity
                    of this Agreement, Business Associate affected by the breach may:</p>
                  <p>(1) Terminate this Agreement and any Underlying Agreement upon thirty (30) days’ notice if Business
                    Associate determines that Covered Entity has violated a material term of this Agreement if,
                    following Business Associate’s notification to Covered Entity of the material breach, Covered Entity
                    is unable or unwilling to take steps to cure the breach within such thirty (30) day period. In the
                    event of such a cure, this Agreement shall remain in full force and effect; or</p>
                  <p>(2) Immediately terminate this Agreement and any Underlying Agreement if Covered Entity has
                    breached a material term of this Agreement and cure is not possible.</p>
                  <p>(3) For clarity, the termination of this Agreement by a Business Associate shall not constitute
                    termination of this Agreement as to the other remaining Business Associate. This Agreement shall
                    continue in full force and effect between Covered Entity and the remaining Business Associate unless
                    otherwise terminated by either remaining Party in accordance with this section.</p>
                </li>
              </ol>
            </li>
          </ol>
          <p>Return on Termination. At termination of the Agreement, to the extent feasible, Business Associate shall
            return or destroy all PHI that Business Associate still maintains in any form and retain no copies of PHI.
            If the return or destruction of such PHI is not feasible, Business Associate shall extend the protections of
            this Agreement to the remaining information and limit further Uses and Disclosures of PHI to those purposes
            that make the return or destruction of PHI infeasible.</p>
          <p>Survival. The terms of this Section shall survive the termination or expiration of this Agreement.</p>
        </li>

        <li>Compliance with Laws. Business Associate shall comply with all applicable federal, state and local laws, rules and regulations, including, without limitation, the requirements of HIPAA.</li>

        <li>Underlying Agreement. Except as specifically required to implement the purposes of this Agreement, and except to the extent inconsistent with this Agreement, all terms of any Underlying Agreement shall remain in full force and effect. In the event of a conflict between the terms of any Underlying Agreement and this Agreement, this Agreement shall control.</li>

        <li> No Third-Party Beneficiaries. Nothing express or implied in this Agreement is intended to confer, nor shall anything herein confer, upon any person other than the Covered Entity, Business Associate and their respective successors or assigns, any rights, remedies, obligations or liabilities whatsoever.</li>

        <li>Amendment. The Parties agree to negotiate in good faith to amend this Agreement from time to time by mutual written agreement in order to keep this Agreement consistent with any changes made to the HIPAA laws or regulations in effect as of the Effective Date and with any new regulations promulgated under HIPAA. A Party may terminate the Agreement with respect to that Party if the Parties are unable to agree to such changes by the compliance date for such new or revised HIPAA laws or regulations.</li>

        <li>Counterparts. This Agreement may be executed in two or more counterparts, each of which shall be an original, but all of which taken together shall constitute one and the same agreement.</li>

      </ol>
    </>
  );
};

export default BAAContent;
