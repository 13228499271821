import { FC, createContext, useState } from "react";
import { FooterProps, FooterProviderType } from "./types";

export const FooterContext = createContext<FooterProps>({
  isAccordianShown: false,
  changeAccordianVisibility: () =>{}
 });

const FooterProvider: FC<FooterProviderType> = ({ children }): JSX.Element => {

  const [isAccordianShown,setAccordianVisibility] = useState<boolean>(false);
  
  const changeAccordianVisibility = () => {
    setAccordianVisibility((isAccordianShown) => !isAccordianShown);
  };

  return (
    <FooterContext.Provider
      value={{
        isAccordianShown,
        changeAccordianVisibility
      }}
    >
      {children}
    </FooterContext.Provider>
  );
};

export default FooterProvider;
